export const PERMISSION_RESOURCE = {
	GAME: "game",
	GAME_PROBABILITIES: "game_Probabilities",
	GAME_RTP: "game_RTPs",
	GAME_TRACK_BRANDING: "game_TrackBranding",
	GAME_ADD_TO_PROJECTS: "game_AddToProjects",

	PARTNER: "partner",
	PARTNER_WALLET: "partner_Wallet",
	PARTNER_ONLINE_CONFIGURATION: "partner_OnlineConfiguration",
	PARTNER_ONLINE_RTP: "partner_OnlineRTPs",
	PARTNER_RETAIL_CONFIGURATION: "partner_RetailConfiguration",
	PARTNER_RETAIL_RTP: 'partner_RetailRTPs',
	PARTNER_TERMINAL_CONFIGURATION: "partner_TerminalConfiguration",
	PARTNER_TERMINAL_RTPS: "partner_TerminalRTPs",
	PARTNER_SECURITY_SETTINGS: "partner_SecuritySettings",
	PARTNER_CURRENCIES: "partner_Currencies",
	PARTNER_LANGUAGES: "partner_Languages",
	PARTNER_TRANSACTIONS_HISTORY: "partner_TransactionsHistory",

	APIKEY: "apiKey",
	APIKEY_RTP: "apiKey_RTPs",
	APIKEY_GAMES: "apiKey_Games",
	APIKEY_ADD_ONS: "apiKey_Add_Ons",
	APIKEY_CURRENCIES: "apiKey_Currencies",
	APIKEY_ANALYTICAL_TOOLS: "apiKey_AnalyticalTools",
	APIKEY_PLATFORMS: "apiKey_Platforms",
	APIKEY_IPRESTRICTIONS: "apiKey_IPRestrictions",
	APIKEY_CUSTOMIZATION: "apiKey_Customization",
	APIKEY_OTHER_CONFIGS: "apiKey_OtherConfigs",

	DEVELOPMENT: "development",
	DEVELOPMENT_SPACE: "development_Space",
	DEVELOPMENT_STREAMS: "development_Streams",
	//#region unused functionality
	// DEVELOPMENT_STREAM_SERVERS: "development_StreamServers",
	//#endregion

	BOX: "box",
	BOX_GAMES: "box_Games",
	BOX_RTP: 'box_RTPs',

	BETSHOP: "betShop",
	BETSHOP_BALANCE: "betShop_Balance",
	BETSHOP_BOX: "betShop_Box",
	BETSHOP_CURRENCIES: "betShop_Currencies",
	BETSHOP_OPERATING_SETTINGS: "betShop_OperatingSettings",
	BETSHOP_TICKETS: "betShop_Tickets",
	BETSHOP_HOTKEYS: "betShop_HotKeys",
	BETSHOP_CUSTOMIZATION: "betShop_Customization",
	BETSHOP_DBCACHECOMPARE: "betShop_DBCacheCompare",
	BETSHOP_ACTIVITY: "betShop_Activity",
	BETSHOP_LIMIT_SETTINGS: "betShop_LimitSettings",
	BETSHOP_BONUS_CONFIGS: "betShop_BonusConfigs",
	BETSHOP_BET: "betShop_Bet" /* VS-10739 */,

	BETSLIPS: "betSlips",
	BETSLIPS_ONLINE: "betSlips_Online",
	BETSLIPS_RETAIL: "betSlips_Retail",
	BETSLIPS_SSBT: "betSlips_Terminal",

	EVENTS: "events",

	FAILED_TRANSACTION: "failedTransaction",

	TRANSLATIONS: "translations",
	TRANSLATIONS_SYSTEM: "translations_System",
	TRANSLATIONS_PARTNER: "translations_Partner",
	TRANSLATIONS_RULES: "translations_Rules",
	TRANSLATIONS_LIVE_MONITOR: "translations_LiveMonitor",

	PERMISSION: "permission",
	PERMISSION_GROUP: "permission_Group",
	PERMISSION_REQUESTS: "permission_Requests",

	RISK_MANAGEMENT_BET_ANOMALIES: "riskManagementBetAnomalies",
	RISK_MANAGEMENT_BET_ANOMALIES_HISTORY: "riskManagementBetAnomalies_History",
	RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS: "riskManagementBetAnomalies_Configurations",
	RISK_MANAGEMENT_BIG_MULTIPLIERS: "riskManagementBigMultipliers",
	RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY: "riskManagementBigMultipliers_History",
	RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS: "riskManagementBigMultipliers_Configurations",
	RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES: "riskManagementPlayerSuspiciousActivates",
	RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY: "riskManagementPlayerSuspiciousActivates_History",
	RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS: "riskManagementPlayerSuspiciousActivates_Configurations",
	RISK_MANAGEMENT_TOP_PLAYERS: "riskManagementTopPlayers",
	RISK_MANAGEMENT_TOP_PLAYERS_MAIN_TABLE: "riskManagementTopPlayers_MainTable",

	USER: "user",
	USER_FORCE_LOGOUT: "user_ForceLogout",
	USER_PARTNERS: "user_Partners",
	USER_PROJECTS: "user_Projects",
	USER_BETSHOPS: "user_BetShops",
	USER_PERMISSION_GROUP: "user_PermissionGroups",
	USER_PERMISSION: "user_Permissions",
	USER_LOGS: "user_Logs",
	ACCESS_MANAGER: "accessManager",

	CASHIER: "cashier",
	CASHIER_FORCE_LOGOUT: "cashier_ForceLogout",

	PLAYER: "player",
	PLAYER_SESSIONS_HISTORY: "player_SessionsHistory",
	PLAYER_DBCACHECOMPARE: "player_DBCacheCompare",
	PLAYER_BET_HISTORY: "player_BetHistory",

	REPORTS: "reports",
	REPORTS_PROJECT_PERFORMANCE: "reports_ProjectPerformance",
	REPORTS_BETSHOP_PERFORMANCE: "reports_BetShopPerformance",
	REPORTS_PARTNER_PERFORMANCE: "reports_PartnerPerformance",

	DASHBOARD: "dashboard",
	DASHBOARD_ONLINE: "dashboard_Online",
	DASHBOARD_RETAIL: "dashboard_Retail",

	SETTINGS: "settings",
	SETTINGS_MAINTENANCE_MODE: "settings_MaintenanceMode",
	SETTINGS_LANGUAGES: "settings_Languages",
	SETTINGS_CURRENCIES: "settings_Currencies",

	BONUS: "bonus",
	BONUS_JACKPOT: "bonus_Jackpot",
	BONUS_STANDARD: "bonus_FreeBet_DoubleDooble",

	STREAMS: "streams",
	SURVEYS: "surveys",

	// DEVELOPER: "Developer",
	// SUPER_ADMIN: "SuperAdmin"

	PENDING_BETSLIP: "betSlips_Online",
	SETTLED_BETSLIP: "betSlips_Retail",

	TERMINAL: "terminal",
	TERMINAL_GAMES: "terminal_Games",
	TERMINAL_RTP: "terminal_RTP",
	TERMINAL_OTHER_CONFIGS: "terminal_OtherConfigs",
	TERMINAL_ADD_ONS: "terminal_Add_Ons"
};

export const PERMISSION_ACTION = {
	VIEW: "View",
	MODIFY: "Modify",
	DELETE: "Delete",
	CREATE: "Create",
	EXPORT: "Export"
};

export const ACCESS_MANAGER_PERMISSION = {
	[PERMISSION_RESOURCE.USER]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.EXPORT],
	[PERMISSION_RESOURCE.USER_PARTNERS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
	[PERMISSION_RESOURCE.USER_PROJECTS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
	[PERMISSION_RESOURCE.USER_BETSHOPS]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
	[PERMISSION_RESOURCE.USER_PERMISSION_GROUP]: [
		PERMISSION_ACTION.VIEW,
		PERMISSION_ACTION.MODIFY,
		PERMISSION_ACTION.CREATE,
		PERMISSION_ACTION.DELETE
	],
	[PERMISSION_RESOURCE.PERMISSION_GROUP]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.EXPORT],
	[PERMISSION_RESOURCE.PERMISSION]: [PERMISSION_ACTION.VIEW],
	[PERMISSION_RESOURCE.PERMISSION_REQUESTS]: [PERMISSION_ACTION.VIEW],
	[PERMISSION_RESOURCE.ACCESS_MANAGER]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE]
};
