import { connect } from "react-redux";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import SettledBetsTableComponent from "../../common/settled/tableByBetSlip.component";
import NewSettledBetsTableComponent from "../../common/settled/tableByBet.component";

import { getSettledBets, setSettledBetsSorting, setSettledBetsFilters } from "store/actions/dashboard/betslips/settled.action";

import { PERMISSION_RESOURCE } from "constants/permissions.constants";

const SsbtSettledBetsComponent = ({
	bets,
	sorting,
	filters,
	total,
	isLoading,
	getSettledBets,
	setSettledBetsSorting,
	setSettledBetsFilters,
	gameCategory,
	showBetsBySeparate = false
}) => {
	const Component = showBetsBySeparate ? NewSettledBetsTableComponent : SettledBetsTableComponent;
	const exportURL = ApiUrls.EXPORT_SSBT_BETSLIPS;

	return (
		<Component
			bets={bets}
			sorting={sorting}
			filters={filters}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.SSBT}
			getSettledBets={getSettledBets}
			setSettledBetsSorting={setSettledBetsSorting}
			setSettledBetsFilters={setSettledBetsFilters}
			exportURL={exportURL + "?isPending=" + false}
			permission={PERMISSION_RESOURCE.BETSLIPS_SSBT}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslips.settled.ssbt.bets,
		total: state.betslips.settled.ssbt.total,
		sorting: state.betslips.settled.ssbt.sorting,
		filters: state.betslips.settled.ssbt.filters,
		isLoading: state.betslips.isLoading
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = false }) => ({
	getSettledBets: (fromFirstPage, gameCategory) => {
		dispatch(getSettledBets(PROJECT_TYPE.SSBT, fromFirstPage, gameCategory, showBetsBySeparate));
	},
	setSettledBetsSorting: (sorting) => {
		dispatch(setSettledBetsSorting(sorting, PROJECT_TYPE.SSBT));
	},
	setSettledBetsFilters: (filters) => {
		dispatch(setSettledBetsFilters(filters, PROJECT_TYPE.SSBT));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SsbtSettledBetsComponent);
