import { useState, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Radio } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import BoxAddComponent from "./box-add.component";
import { getBoxes, setBoxesSorting } from "store/actions/dashboard/retail/boxes/boxes.action";

import { DATE_TIME_FORMAT } from "constants/date.constants";

import boxType from "types/box/box.type";
import sortingType from "types/common/sorting.type";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";
import { setDefaultBox } from "store/actions/dashboard/retail/boxes/generalInfo.action.js";
import Question from "components/common/question/index.jsx";
import useForceUpdate from "hooks/useForceUpdate.js";

/** Boxes Page Component */
const BoxesComponent = ({ getBoxes, boxes, isLoading, total, setBoxesSorting, sorting, globalPartnerId, setDefaultBoxFromProps }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [selectedDefaultBox, setSelectedDefaultBox] = useState(null);
	const defaultBoxId = useMemo(() => boxes?.find(box => box.isDefault)?.id, [boxes]);
	const [forceUpdate, forceUpdateValue] = useForceUpdate()

	/** State to show/hide box creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	const defaultBoxChange = () => {
		setDefaultBoxFromProps(selectedDefaultBox, forceUpdate);
		setSelectedDefaultBox(null)
	}

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.currency.default"),
			dataIndex: "isDefault",
			render: (value, record) => <Radio value={record.id} disabled={!record.enabled} style={{ margin: 0 }} defaultChecked={value} />,
			sorter: false,
			align: "center"
		},
		{
			title: t("pages.dashboard.boxes.box_id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.boxes.name"),
			dataIndex: "name"
		},
		{
			title: t("pages.dashboard.boxes.bet_shops"),
			dataIndex: "betShopNames",
			multi: true,
			multiMapper: (value) => value,
			sorter: false,
			center: true,
			align: "center"
		},
		{
			title: t("pages.dashboard.boxes.games"),
			dataIndex: "games",
			multi: true,
			multiMapper: (value) => value,
			sorter: false,
			center: true,
			align: "center"
		},
		{
			title: t("pages.dashboard.boxes.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.boxes.last_update_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
	];

	const generateAction = () => {
		let key = null;

		if (hasPermission({ resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.VIEW })) {
			key = "view";
		}

		if (hasPermission({ resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_BOXES_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${record.partnerId}`));
				}
			}
		};
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.boxes.boxes") }]} />
				<div className="dashboard-section-content">
					{hasPermission({ resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.CREATE }) && (
						<div className="table-buttons-dropdowns">
							<div className="table-buttons-dropdowns-button">
								<Button onClick={() => setShowCreatePopup(true)} type="primary">
									{t("pages.dashboard.boxes.create_box")}
								</Button>
							</div>
						</div>
					)}
					<Radio.Group
						style={{ width: "100%" }}
						value={defaultBoxId}
						onChange={(e) => setSelectedDefaultBox(e.target.value)}
					>

						<Table updateProps={[forceUpdateValue, globalPartnerId]} loading={isLoading} columns={columns} data={boxes} loadFn={getBoxes} sorting={sorting} setSortingFn={setBoxesSorting} total={total} actions={generateAction()} isDisabled={() => false} />
					</Radio.Group>
				</div>
			</div>

			<Question
				type="confirm"
				onOk={defaultBoxChange}
				onCancel={() => setSelectedDefaultBox(null)}
				isVisible={selectedDefaultBox !== null}
				message={t("pages.dashboard.boxes.default_box_text")}
			/>

			{showCreatePopup ? <BoxAddComponent onClose={() => setShowCreatePopup(false)} /> : null}
		</Fragment>
	);
};

/** BoxesComponent propTypes
 * PropTypes
 */
BoxesComponent.propTypes = {
	/** Redux action to get virual boxes */
	getBoxes: PropTypes.func,
	/** Redux state property, represents the array of virual boxes  */
	boxes: PropTypes.arrayOf(boxType),
	/** Redux state property, is true when loading boxes */
	isLoading: PropTypes.bool,
	/** Redux state property, games total count */
	total: PropTypes.number,
	/** Redux action to set boxes sorting details */
	setBoxesSorting: PropTypes.func,
	/** Redux state property, boxes sorting details */
	sorting: sortingType,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to set default box */
	setDefaultBoxFromProps: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
	getBoxes: (fromFirstPage) => {
		dispatch(getBoxes(fromFirstPage));
	},
	setBoxesSorting: (sorting) => {
		dispatch(setBoxesSorting(sorting));
	},
	setDefaultBoxFromProps: (data, callback) => {
		dispatch(setDefaultBox(data, callback));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.boxes.isLoading,
		boxes: state.boxes.boxes,
		total: state.boxes.total,
		sorting: state.boxes.sorting,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxesComponent);
