import { Fragment } from "react";

import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import TopPlayers from "components/dashboard/riskManagement/topPlayers";

const TopPlayersComponent = () => {
	return (
		<Fragment>
			<TopPlayers />
		</Fragment>
	);
};


export default withPermission(
	TopPlayersComponent,
	[
		{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_TOP_PLAYERS_MAIN_TABLE, action: PERMISSION_ACTION.VIEW }
	],
	Paths.DASHBOARD
)
