import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Space, Switch } from "antd";
import Table from "components/common/table";
import FooterButtons from "./footerButtons";
import NumericInput from "components/common/numericInput";
import { countDecimals, numberTransform } from "utils/common";
import { rtpPeriodChecking } from "utils/markets";
import { hasPermission } from "utils/permissions";
import { showError } from "utils/message";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import rtpType from "types/rtp/rtp.type";
import useRTPs from "hooks/useRTPs";
import { PERIOD } from "constants/market.constants";
import { GAME_CATEGORY, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import useForceUpdate from "hooks/useForceUpdate";
import LuckySixRTP from "components/dashboard/common/RTPComponent/luckySixRTP.jsx";
import { getBoxGameRTPs, resetBoxGameRTPs, saveBoxGameRTPs } from "store/actions/dashboard/retail/boxes/rtp.action.js";

const saveButtonPropsStateDefault = { loading: false, disabled: true, style: { display: "none" } };
const { Item: FormItem } = Form;

const RTPComponent = ({
		isSaving,
		isLoading,
		getBoxGameRTPsFromProps,
		saveBoxGameRTPsFromProps,
		resetBoxGameRTPsFromProps,
		onClose,
		rtp,
		gameId,
		gameType,
		gameCategory
	}) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [orders, setOrders] = useState({});
	const [initialized, setInitialized] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = useMemo(() => !rtp.allowOverride || !hasPermission({ resource: PERMISSION_RESOURCE.BOX_RTP, action: PERMISSION_ACTION.MODIFY }), [rtp.allowOverride]);
	const [saveButtonPropsState, setSaveButtonPropsState] = useState(saveButtonPropsStateDefault);
	const [forceUpdate] = useForceUpdate();
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		setSaveButtonPropsState({
			loading: isSaving,
			disabled: !isDirty || isDisabled || !isFormTouched,
			style: isDisabled || !rtp.allowOverride ? { display: "none" } : {}
		});
	}, [isDirty, isSaving, isDisabled, isFormTouched, rtp.allowOverride]);

	/** Columns of table */
	const columns = useMemo(
		() => [
			{
				title: "#",
				dataIndex: "index",
				sorter: false,
				render: (index, record) => (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT) ? index + 1 : " "),
				width: "6%"
			},
			{
				title: t("pages.dashboard.boxes.rtp.market"),
				dataIndex: "name",
				render: (value, record) => {
					if (gameType === SCHEDULED_GAME_TYPE.KENO.value) {
						return record.name;
					}

					if (gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
						return record.name?.split("-")[1] ?? "";
					}

					if (record.period === PERIOD.FIRSTHALF) {
						return `${t(`markets.${gameType}.FirstHalf`)} ${t(`markets.${gameType}.${value}`)}`;
					}

					if (record.period === PERIOD.SECONDHALF) {
						return `${t(`markets.${gameType}.SecondHalf`)} ${t(`markets.${gameType}.${value}`)}`;
					}

					if (rtpPeriodChecking(record, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT)) {
						if (record.period === PERIOD.MATCH && record.name === "Winner") {
							return t(`markets.${gameType}.LeagueChamp`);
						}
						return t(`markets.${gameType}.${value}`);
					}

					return "";
				},
				sorter: false,
				className: "drag-visible",
				width: "27%",
				ellipsis: true
			},
			{
				title: t("pages.dashboard.boxes.rtp.margin"),
				dataIndex: "margin",
				sorter: false,
				className: "ant-table-input-cell",
				render: (value, record) => (
					<FormItem
						className="inline-form-item-control table-form-control"
						name={["rtPs", record.name + "_" + record.period, "margin"]}
						rules={[
							{ required: true, message: t("validation.field_required") },
							{
								type: "number",
								min: rtp.minPossibleValue || 1,
								message: t("validation.must_be_more").replace("%X%", rtp.minPossibleValue || 1),
								transform: numberTransform
							},
							{
								type: "number",
								max: rtp.maxPossibleValue || 50,
								message: t("validation.must_be_less").replace("%X%", rtp.maxPossibleValue || 50),
								transform: numberTransform
							}
						]}
						style={{ marginLeft: 0 }}
					>
						<NumericInput
							style={{ maxWidth: "200px" }}
							decimalsCount={2}
							disabled={isDisabled}
							onChange={(val) => {
								const numVal = Number(val);
								const value = (100 - (!isNaN(numVal) ? numVal : 0)).toFixed(countDecimals(numVal));
								setFieldsValue({
									rtPs: {
										[record.name + "_" + record.period]: {
											rtp: value
										}
									}
								});
							}}
						/>
					</FormItem>
				),
				width: "28%"
			},
			{
				title: t("pages.dashboard.boxes.rtp.rtp"),
				dataIndex: "rtp",
				className: "ant-table-input-cell",
				render: (value, record) => (
					<FormItem
						className="inline-form-item-control table-form-control"
						style={{ marginLeft: 0 }}
						name={["rtPs", record.name + "_" + record.period, "rtp"]}
					>
						<Input style={{ maxWidth: "200px" }} disabled={true} />
					</FormItem>
				),
				sorter: false,
				width: "28%"
			},
			{
				title: "",
				dataIndex: "enabled",
				className: "ant-table-input-cell",
				render: (value, record) => {
					let isDisabledByParent = false;

					if (
						![SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value].includes(gameType) &&
						[PERIOD.FIRSTHALF, PERIOD.SECONDHALF].includes(record.period)
					) {
						const parentRtp = formInstance.getFieldValue(["rtPs", `${record.name}_${PERIOD.MATCH}`]);
						isDisabledByParent = !parentRtp.enabled;
					}
					return (
						<FormItem
							className="inline-form-item-control table-form-control"
							style={{ marginLeft: 0 }}
							name={["rtPs", record.name + "_" + record.period, "enabled"]}
							valuePropName="checked"
						>
							<Switch disabled={isDisabled || isDisabledByParent} />
						</FormItem>
					);
				},
				sorter: false,
				width: "10%"
			}
		],
		[gameType, t, setFieldsValue, isDisabled]
	);

	const { mutatedData, getDetailsData } = useRTPs(rtp, orders, columns, gameType);

	/** Function to sort rtps data by order Number
	 * @function
	 * @param {array} data - array of rtps
	 * @returns {string} data - sorted array
	 * @memberOf RTPComponent
	 */
	const sortByOrderNumber = (data) => {
		data.sort((item1, item2) => (orders[item1.name] < orders[item2.name] ? -1 : 1));
		return data;
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf RTPComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((values) => {
				try {
					const { rtPs } = rtp.rtPs.reduce(
						(acc, d) => {
							if (acc.hasSameOrder) {
								throw new Error(t("errors.message.rtpsHaveSameOrder"));
							} else if (acc.groupedRtps[d.orderNumber] && acc.groupedRtps[d.orderNumber] !== d.name) {
								acc.hasSameOrder = true;
							} else {
								acc.groupedRtps[d.orderNumber] = d.name;

								if (!values.rtPs[d.name + "_" + d.period]) {
									return acc;
								}

								acc.rtPs.push({
									margin: values.rtPs[d.name + "_" + d.period].margin,
									enabled: gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value || values.rtPs[d.name + "_" + d.period].enabled,
									name: d.name,
									period: d.period,
									orderNumber: orders[d.name]
								});
							}

							return acc;
						},
						{ rtPs: [], groupedRtps: {}, hasSameOrder: false }
					);

					const d = {
						id: routeParams.id,
						gameId,
						rtPs
					};

					saveBoxGameRTPsFromProps({ data: d, onSuccess: onClose, gameCategory });
				} catch (err) {
					if (err.message === t("errors.message.rtpsHaveSameOrder")) {
						showError(err.message);
					} else {
						throw new Error(err.message);
					}
				}
			})
			.catch(Function.prototype);
	};

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const orders = {};
		rtp.rtPs.forEach((r) => {
			orders[r.name] = r.orderNumber;
		});
		setOrders(orders);

		if (initialized) {
			setFieldsValue({
				rtPs: Object.fromEntries(
					rtp.rtPs.map((r, i) => [
						r.name + "_" + r.period,
						{
							margin: r.margin,
							rtp: r.rtp,
							enabled: r.enabled
						}
					])
				)
			});

			forceUpdate();
		}
	}, [rtp.rtPs]);

	/** reset Rtp data when opening popup */
	useEffect(() => {
		resetBoxGameRTPsFromProps();
		setInitialized(true);
	}, []);

	/** Activate save button only when there is a change */
	useEffect(() => {
		setIsFormTouched(true);
	}, [rtp.rtPs]);

	return (
		<Space direction="vertical">
			<Form
				className="dashboard-form"
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				onValuesChange={(_, values) => {
					setIsDirty(formInstance.isFieldsTouched([], true));
					if (![SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value].includes(gameType)) {
						const rtPsEntries = Object.entries(values.rtPs);
						const filtered = rtPsEntries.filter(([key, value]) => {
							const periodStr = key.at(-1);
							return PERIOD.MATCH.toString() === periodStr && !value.enabled;
						});
						if (filtered.length > 0) {
							const newValues = { ...values, rtPs: { ...values.rtPs } };
							filtered.forEach((entry) => {
								const [groupName] = entry.at(0).split("_");
								[`${groupName}_${PERIOD.FIRSTHALF}`, `${groupName}_${PERIOD.SECONDHALF}`].forEach((subGroup) => {
									if (newValues.rtPs[subGroup]) {
										newValues.rtPs[subGroup] = {
											...newValues.rtPs[subGroup],
											enabled: false
										};
										formInstance.setFieldValue(["rtPs", subGroup, "enabled"], false);
									}
								});
							});
						}
						// Need to re-render table for re-work column render function
						forceUpdate();
					}
				}}
			>
				<div className="ant-modal-table">
					<FormItem shouldUpdate>
						{() => {
							if (gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
								return (
									<LuckySixRTP
										isLoading={isLoading}
										rtp={rtp}
										getRTPs={() => getBoxGameRTPsFromProps({ id: routeParams.id, gameId, gameCategory })}
									/>
								);
							}

							return (
								<Table
									loading={isLoading}
									columns={columns}
									data={mutatedData}
									loadFn={() => getBoxGameRTPsFromProps({ id: routeParams.id, gameId, gameCategory })}
									total={rtp.rtPs.length}
									isDisabled={(record) => !record.period}
									noPagination={true}
									uniqueKey="name"
									nonFixed={true}
									detailsType="table"
									details={getDetailsData}
									isAlwaysExpended={true}
									tableLayout="fixed"
									draggable={
										isDisabled || gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value
											? null
											: {
												onDragEnd: (oldIndex, newIndex) => {
													const oldOrderNumber = oldIndex + 1;
													const newOrderNumber = newIndex + 1;

													const newOrders = {};

													Object.keys(orders).forEach((k) => {
														if (oldOrderNumber < newOrderNumber) {
															if (orders[k] === oldOrderNumber) {
																newOrders[k] = newOrderNumber;
															} else if (orders[k] > oldOrderNumber && orders[k] <= newOrderNumber) {
																newOrders[k] = orders[k] - 1;
															} else {
																newOrders[k] = orders[k];
															}
														} else {
															if (orders[k] === oldOrderNumber) {
																newOrders[k] = newOrderNumber;
															} else if (orders[k] < oldOrderNumber && orders[k] >= newOrderNumber) {
																newOrders[k] = orders[k] + 1;
															} else {
																newOrders[k] = orders[k];
															}
														}
													});

													setOrders(newOrders);
													setTimeout(() => {
														setIsFormTouched(true);
													}, 100);
												}
											}
									}
									isDraggable={() => gameType !== SCHEDULED_GAME_TYPE.LUCKY_SIX.value}
									id="box_games_rtps_modal"
								/>
							);
						}}
					</FormItem>
				</div>
			</Form>
			<FooterButtons onClose={onClose} onSave={!(isDisabled || !rtp.allowOverride) ? handleForm : null} saveButtonProps={saveButtonPropsState} />
		</Space>
	);
};

RTPComponent.propTypes = {
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	getBoxGameRTPsFromProps: PropTypes.func,
	saveBoxGameRTPsFromProps: PropTypes.func,
	resetBoxGameRTPsFromProps: PropTypes.func,
	onClose: PropTypes.func,
	rtp: rtpType,
	gameId: PropTypes.string,
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getBoxGameRTPsFromProps: (obj) => {
		dispatch(getBoxGameRTPs(obj));
	},
	saveBoxGameRTPsFromProps: (obj) => {
		dispatch(saveBoxGameRTPs(obj));
	},
	resetBoxGameRTPsFromProps: () => {
		dispatch(resetBoxGameRTPs());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.boxes.editingBox.isSaving,
		isLoading: state.boxes.editingBox.rtp.isLoading,
		rtp: state.boxes.editingBox.rtp.data
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RTPComponent);
