import Breadcrumbs from "components/common/breadcrumbs/index.jsx";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paths from "constants/path.constants.js";
import { useEffect, useState } from "react";
import { getEditingSSBTGames } from "store/actions/dashboard/ssbt/ssbt.action.js";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import { hasPermission } from "utils/permissions.js";
import GamesComponent from "components/dashboard/ssbt/edit/sections/games/index.jsx";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import AddOns from "./sections/addOns";
import { getHashValue, updateLocationHash } from "utils/common";

const SSBTEditComponent = () => {
	const dispatch = useDispatch();
	const routeParams = useParams();
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf ApiKeyEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf ApiKeyEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	useEffect(() => {
		if (routeParams.id) {
			dispatch(getEditingSSBTGames(routeParams.id));
		}
	}, [routeParams.id]);

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.ssbt"), path: Paths.DASHBOARD_SSBT }, { title: t("common.edit") }]} />
			<Tabs
				animated={false}
				defaultActiveKey={getHashValue("tab")}
				onChange={(key) => updateLocationHash("tab=" + key)}
				items={[
					hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.VIEW })
						? {
								key: "1",
								label: <span className={tabClassName("1")}>{t("pages.dashboard.ssbt.tabs.games")}</span>,
								children: <GamesComponent />
						  }
						: null,
					hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_ADD_ONS, action: PERMISSION_ACTION.VIEW })
						? {
								key: "2",
								label: <span className={tabClassName("2")}>{t("pages.dashboard.apikeys.tabs.addOns")}</span>,
								children: <AddOns id={routeParams.id} onTabChange={(status) => changeTabSavedStatus(status, "2")} />
						  }
						: null
				]}
			/>
		</div>
	);
};

export default SSBTEditComponent;
