import i18n from "translations/config";

import { GAME_CATEGORY, SCHEDULED_GAME_TYPE, SPIN_TO_WIN_MARKETS } from "constants/game.constants";
import { isLeagueGame, isRacingGame } from "./common";
import { PERIOD } from "constants/market.constants";

/** All possible market tabs with their markets */
const allMarkets = [
	{ group: "Winner3Way", period: PERIOD.MATCH, title: "Winner3Way" },
	{ group: "DoubleChance", period: PERIOD.MATCH, title: "DoubleChance" },
	{ group: "ResultWithoutDraw", period: PERIOD.MATCH, title: "ResultWithoutDraw" },
	{ group: "CorrectScore6Goals", period: PERIOD.MATCH, title: "CorrectScore6Goals" },
	{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
	{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
	{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
	{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
	{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
	{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
	{ group: "Total3Way", period: PERIOD.MATCH, title: "Total3Way" },
	{ group: "Points", period: PERIOD.MATCH, title: "Points" },
	{ group: "HalfMatch", period: PERIOD.MATCH, title: "HalfMatch" },
	{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
	{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
	{ group: "WillHomeTeamScore", period: PERIOD.MATCH, title: "WillHomeTeamScore" },
	{ group: "WillAwayTeamScore", period: PERIOD.MATCH, title: "WillAwayTeamScore" },
	{ group: "WillBothTeamsScore", period: PERIOD.MATCH, title: "WillBothTeamsScore" },
	{ group: "WinnerAndTotal", period: PERIOD.MATCH, title: "WinnerAndTotal" },
	{ group: "NextPoint", period: PERIOD.MATCH, argument: 1, title: "NextPoint" },
	{ group: "Correct6HomeGoalsCount", period: PERIOD.MATCH, title: "Correct6HomeGoalsCount" },
	{ group: "Correct6AwayGoalsCount", period: PERIOD.MATCH, title: "Correct6AwayGoalsCount" },
	{ group: "Correct6GoalsCount", period: PERIOD.MATCH, title: "Correct6GoalsCount" },
	{ group: "DoubleChanceAndTotal", period: PERIOD.MATCH, title: "DoubleChanceAndTotal" },
	{ group: "Penalty", period: PERIOD.MATCH, title: "Penalty" },
	{ group: "YellowCard", period: PERIOD.MATCH, title: "YellowCard" },
	{ group: "RedCard", period: PERIOD.MATCH, title: "RedCard" },
	{ group: "MethodOfVictoryOvertime", period: PERIOD.MATCH, title: "MethodOfVictoryOvertime" },
	{ group: "MatchEndsInOvertime", period: PERIOD.MATCH, title: "MatchEndsInOvertime" },
	{ group: "MatchEndsInPenaltyShootouts", period: PERIOD.MATCH, title: "MatchEndsInPenaltyShootouts" },
	{ group: "Winner2Way", period: PERIOD.MATCH, title: "Winner2Way" },
	{ group: "Winner", period: PERIOD.NONE, title: "Winner" },
	{ group: "Loser", period: PERIOD.NONE, title: "Loser" },
	{ group: "Finalist", period: PERIOD.NONE, title: "Finalist" },
	{ group: "Champion", period: PERIOD.NONE, title: "Champion" },
	{ group: "RoundTotal", period: PERIOD.NONE, argument: (arg) => Number(arg) % 0.5 === 0, title: "RoundTotal" },
	{ group: "Place", period: PERIOD.NONE, title: "Place" },
	{ group: "Show", period: PERIOD.NONE, title: "Show" },
	{ group: "Pair", period: PERIOD.NONE, title: "Pair" },
	{ group: "PairAnyOrder", period: PERIOD.NONE, title: "PairAnyOrder" },
	{ group: "Triple", period: PERIOD.NONE, title: "Triple" },
	{ group: "TripleAnyOrder", period: PERIOD.NONE, title: "TripleAnyOrder" },
	{ group: "WinnerNumber", period: PERIOD.NONE, title: "WinnerNumber" },
	{ group: "Total123Placed", period: PERIOD.NONE, title: "Total123Placed" },
	{ group: "RaceEvenOdd", period: PERIOD.NONE, title: "RaceEvenOdd" },
	{ group: "WillNonFinishers", period: PERIOD.NONE, title: "WillNonFinishers" },
	{ group: "TotalNonFinishers", period: PERIOD.NONE, title: "TotalNonFinishers" },
	{ group: "Winner2Way", period: PERIOD.PENALTY_SHOOTOUT, title: "Winner2Way" },
	{ group: "HomeGoalMiss", period: PERIOD.PENALTY_SHOOTOUT, title: "HomeGoalMiss" },
	{ group: "AwayGoalMiss", period: PERIOD.PENALTY_SHOOTOUT, title: "AwayGoalMiss" },
	{ group: "BothTeamsToScore", period: PERIOD.PENALTY_SHOOTOUT, title: "BothTeamsToScore" },
	{ group: "HomePenaltyShot", period: PERIOD.PENALTY_SHOOTOUT, title: "HomePenaltyShot" },
	{ group: "AwayPenaltyShot", period: PERIOD.PENALTY_SHOOTOUT, title: "AwayPenaltyShot" },
	{ group: "MatchEndsInOvertime", period: PERIOD.PENALTY_SHOOTOUT, title: "MatchEndsInOvertime" },
	{ group: "MethodOfVictoryOvertime", period: PERIOD.PENALTY_SHOOTOUT, title: "MethodOfVictoryOvertime" },
	{ group: "CorrectScore", period: PERIOD.PENALTY_SHOOTOUT, title: "CorrectScore" },
	{ group: "CorrectScorePerShot", period: PERIOD.PENALTY_SHOOTOUT, title: "CorrectScorePerShot" },
	{ group: "TotalGoals", period: PERIOD.PENALTY_SHOOTOUT, title: "TotalGoals" },
	{ group: "TotalRealizePenalty", period: PERIOD.PENALTY_SHOOTOUT, title: "TotalRealizePenalty" },
	{ group: "MatchGoalsCount", period: PERIOD.PENALTY_SHOOTOUT, title: "MatchGoalsCount" },
	{ group: "NoGoal", period: PERIOD.PENALTY_SHOOTOUT, title: "NoGoal" },
	{ group: "Handicap", period: PERIOD.PENALTY_SHOOTOUT, title: "Handicap" },
	{ group: "AsianTotal", period: PERIOD.PENALTY_SHOOTOUT, title: "AsianTotal" },
	{ group: "AsianHandicap", period: PERIOD.PENALTY_SHOOTOUT, title: "AsianHandicap" },
	{ group: "FirstNumberOverUnder", period: PERIOD.NONE, title: "FirstNumberOverUnder" },
	{ group: "LastNumberOverUnder", period: PERIOD.NONE, title: "LastNumberOverUnder" },
	{ group: "FirstNumberOddEven", period: PERIOD.NONE, title: "FirstNumberOddEven" },
	{ group: "LastNumberOddEven", period: PERIOD.NONE, title: "LastNumberOddEven" },
	{ group: "Sum5TotalOverUnder", period: PERIOD.NONE, title: "Sum5TotalOverUnder" },
	{ group: "Sum5TotalOddEven", period: PERIOD.NONE, title: "Sum5TotalOddEven" },
	{ group: "EvenMoreOdd", period: PERIOD.NONE, title: "EvenMoreOdd" },
	{ group: "FirstBallColour", period: PERIOD.NONE, title: "FirstBallColour" },
	{ group: "LastBallColour", period: PERIOD.NONE, title: "LastBallColour" },
	{ group: "FirstLastNumberSum", period: PERIOD.NONE, title: "FirstLastNumberSum" },
	{ group: "Straight", period: PERIOD.NONE, title: "Straight" },
	{ group: "Split", period: PERIOD.NONE, title: "Split" },
	{ group: "Trio", period: PERIOD.NONE, title: "Trio" },
	{ group: "Street", period: PERIOD.NONE, title: "Street" },
	{ group: "Basket", period: PERIOD.NONE, title: "Basket" },
	{ group: "Corner", period: PERIOD.NONE, title: "Corner" },
	{ group: "DoubleStreet", period: PERIOD.NONE, title: "DoubleStreet" },
	{ group: "Column", period: PERIOD.NONE, title: "Column" },
	{ group: "Dozen", period: PERIOD.NONE, title: "Dozen" },
	{ group: "EvenOdd", period: PERIOD.NONE, title: "EvenOdd" },
	{ group: "RedBlack", period: PERIOD.NONE, title: "RedBlack" },
	{ group: "HighLow", period: PERIOD.NONE, title: "HighLow" }
];

/** Get All possible markets
 * @function
 * @param {array} markets - markets to filter
 * @returns {array}
 */
export const filterMarkets = (markets) => {
	const filtered = [];
	allMarkets.forEach((market) => {
		markets.forEach((current) => {
			if (
				current.period === market.period &&
				current.group === market.group &&
				(!market.argument || market.argument === current.argument || (typeof market.argument === "function" && market.argument(current.argument)))
			) {
				filtered.push({ ...current, groupTitle: market.title });
			}
		});
	});
	return filtered;
};

const getTeamByNumber = (gameType, gameData, number) =>
	gameData?.[`team${number}`]?.[
		[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value, SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value].includes(gameType) ? "countryName" : "countryCode"
	] ?? `Team ${number}`;

const getNameofTotalarket = (group, argument) => {
	const condition = [
		"Total",
		"TotalGoals",
		"AsianTotal",
		"HomeTotal",
		"AwayTotal",
		"Handicap",
		"AsianHandicap",
		"WinnerAndTotal",
		"DoubleChanceAndTotal",
		"Total3Way",
		"WinnerNumber",
		"Total123Placed",
		"RoundTotal",
		"TotalNonFinishers",
		"TotalGoals",
		"TotalRealizePenalty",
		"TotalMissed"
	].includes(group);
	if (!condition) return "";
	return " (" + argument + ")";
};

const convertArgumentForHandicapAway = (group, outcome, argument) => {
	return ["Handicap", "AsianHandicap"].includes(group) && outcome === "Away" && argument ? 0 - argument : argument;
};

/** Make market beuty name, from common market text
 * @function
 * @param {string} str - market name
 * @param {object} gameData - game Data
 * @param {string} group - market name in system
 * @returns {string}
 */
const makeMarketName = (str, gameData, group, argument, gameType, odd) => {
	if (gameType === SCHEDULED_GAME_TYPE.KENO.value) {
		if (odd) {
			return odd.split(":").join(", ");
		} else {
			return "-";
		}
	}

	const team1 = getTeamByNumber(gameType, gameData, 1);
	const team2 = getTeamByNumber(gameType, gameData, 2);

	const participants = gameData?.participants ?? [];

	let result = str;

	switch (str) {
		case "Home":
			result = team1;
			break;
		case "Away":
			result = team2;
			break;
		case "Draw":
			result = i18n.t(`betText.${gameType}.Draw`);
			break;
		case "Neither":
			result = i18n.t(`betText.${gameType}.Neither`);
			break;
		case "HomeOrDraw":
			result = team1 + " " + i18n.t(`betText.${gameType}.or`) + " " + i18n.t(`betText.${gameType}.Draw`);
			break;
		case "HomeOrAway":
			result = team1 + " " + i18n.t(`betText.${gameType}.or`) + " " + team2;
			break;
		case "AwayOrDraw":
			result = team2 + " " + i18n.t(`betText.${gameType}.or`) + " " + i18n.t(`betText.${gameType}.Draw`);
			break;
		case "HomeAndUnder":
			result = team1 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeAndOver":
			result = team1 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "DrawAndUnder":
			result = i18n.t(`betText.${gameType}.Draw`) + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "DrawAndOver":
			result = i18n.t(`betText.${gameType}.Draw`) + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "AwayAndUnder":
			result = team2 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "AwayAndOver":
			result = team2 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrDrawAndOver":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrAwayAndOver":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				team2 +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "AwayOrDrawAndOver":
			result =
				team2 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrDrawAndUnder":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeOrAwayAndUnder":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				team2 +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "AwayOrDrawAndUnder":
			result =
				team2 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeWinAfterOvertime":
			result = team1 + " " + i18n.t(`betText.${gameType}.winOverTime`);
			break;
		case "AwayWinAfterOvertime":
			result = team2 + " " + i18n.t(`betText.${gameType}.winOverTime`);
			break;
		case "HomeWinAfterPenalties":
			result = team1 + " " + i18n.t(`betText.${gameType}.winPenalty`);
			break;
		case "AwayWinAfterPenalties":
			result = team2 + " " + i18n.t(`betText.${gameType}.winPenalty`);
			break;
		default:
			if (group === "CorrectScore6Goals") result = str.replace("Home", "").replace("Away", " : ");

			if (["Correct6HomeGoalsCount", "Correct6AwayGoalsCount", "Correct6GoalsCount"].includes(group))
				result = str.replace("Goal", "").replace("AndMore", " " + i18n.t(`betText.${gameType}.andMore`));

			if (group === "HalfMatch")
				result = str
					.replace("P1P1", team1 + "/" + team1)
					.replace("P1H", team1 + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("P1P2", team1 + "/" + team2)
					.replace("HP1", i18n.t(`betText.${gameType}.Draw`) + "/" + team1)
					.replace("HH", i18n.t(`betText.${gameType}.Draw`) + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("HP2", i18n.t(`betText.${gameType}.Draw`) + "/" + team2)
					.replace("P2P2", team2 + "/" + team2)
					.replace("P2H", team2 + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("P2P1", team2 + "/" + team1);

			// Racing
			if (str.includes("%Even%")) result = str.replace("%Even%", i18n.t(`betText.${gameType}.Even`));
			else if (str.includes("%Odd%")) result = str.replace("%Odd%", i18n.t(`betText.${gameType}.Odd`));
			else if (!isNaN(str))
				result = participants.length > 0 ? `#${participants[Number(str) - 1].number}.${participants[Number(str) - 1].name}` : "";
			else if (str.startsWith("Race "))
				result =
					participants.length > 0
						? `#${participants[Number(str.split(" ")[1]) - 1].number}.${participants[Number(str.split(" ")[1]) - 1].name}`
						: "";
			else if (/^[0-9]+-[0-9]+$/.test(str))
				result =
					participants.length > 0
						? `${i18n.t(`betText.${gameType}.1st`)} #${participants[Number(str.split("-")[0]) - 1].number}.${participants[Number(str.split("-")[0]) - 1].name}/${i18n.t(`betText.${gameType}.2nd`)} #${participants[Number(str.split("-")[1]) - 1].number}.${participants[Number(str.split("-")[1]) - 1].name}`
						: "";
			else if (/^[0-9]+-[0-9]+-[0-9]+$/.test(str))
				result =
					participants.length > 0
						? `${i18n.t(`betText.${gameType}.1st`)} #${participants[Number(str.split("-")[0]) - 1].number}.${participants[Number(str.split("-")[0]) - 1].name}/${i18n.t(`betText.${gameType}.2nd`)} #${participants[Number(str.split("-")[1]) - 1].number}.${participants[Number(str.split("-")[1]) - 1].name}/${i18n.t(`betText.${gameType}.3rd`)} #${participants[Number(str.split("-")[2]) - 1].number}.${participants[Number(str.split("-")[2]) - 1].name}`
						: "";
			break;
	}

	argument = convertArgumentForHandicapAway(group, str, argument);
	result += getNameofTotalarket(group, argument);

	return result;
};

const isAsianMarket = (group, argument) => {
	if (!group) return false;
	if (!argument) return false;
	return ["Handicap", "AsianHandicap", "Total", "AsianTotal", "AwayTotal", "HomeTotal"].includes(group) && Number(argument) % 0.5 !== 0;
};

const homeAwayConverterToTeam = (outcome, prefix = "", postfix = "") => {
	let retVal = "";
	switch (outcome) {
		case "Home":
			retVal = "%team1% ";
			break;
		case "Away":
			retVal = "%team2% ";
			break;
		default:
			break;
	}
	return prefix + retVal + postfix;
};

/** Make market odd beuty name
 * @function
 * @param {number} period - period
 * @param {string} group - market name in system
 * @param {string} team1 - Team 1 name
 * @param {string} team2 - Team 2 name
 * @param {number} argument - argument
 * @returns {string}
 */
export const makeGroupTitle = (period, group, gameData, argument, outcome, gameType, gameCategory = GAME_CATEGORY.SCHEDULED) => {
	if (group === "Winner" && isLeagueGame(gameType, gameCategory)) {
		group = "LeagueChamp";
	} else if (gameType === SCHEDULED_GAME_TYPE.KENO.value) {
		group = "selectedNumbers";
	}

	const team1 = getTeamByNumber(gameType, gameData, 1);
	const team2 = getTeamByNumber(gameType, gameData, 2);

	let res = "";
	const _period = Number(period) || 0;
	if ([1, 2].includes(_period)) {
		res += i18n.t(`betText.${gameType}.${_period}-half`);
	}

	let gr = group;
	if (isAsianMarket(group, argument)) {
		if (gameType !== SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value) {
			gr = "Asian" + group;
		}
	}

	argument = convertArgumentForHandicapAway(group, outcome, argument);

	if (["Handicap", "AsianHandicap"].includes(group)) {
		res = homeAwayConverterToTeam(outcome, "", res);
	}

	res += " " + i18n.t(`betText.${gameType}.${gr}`);
	res = res.replace("%team1%", team1).replace("%team2%", team2);

	res += getNameofTotalarket(group, argument);

	return res.trim();
};

/** Make beuty text of bet
 * @function
 * @param {object} bet - bet
 * @returns {string}
 */
export const makeBetText = (bet) =>
	makeGroupTitle(bet.period, bet.group, bet.gameData, bet.argument, bet.outcome, bet.gameType) +
	" : " +
	makeMarketName(bet.outcome, bet.gameData, bet.group, bet.argument, bet.gameType, bet.odd);

/** Make market beuty name, to show in winner market section
 * @function
 * @param {string} str - market name
 * @param {string} team1 - Team 1 name
 * @param {string} team2 - Team 2 name
 * @param {string} group - market name in system
 * @returns {string}
 */
export const makeWinnerMarketName = (str, gameData, group, argument, gameType, gameCategory = GAME_CATEGORY.SCHEDULED) => {
	const team1 = getTeamByNumber(gameType, gameData, 1);
	const team2 = getTeamByNumber(gameType, gameData, 2);

	const participants = gameData?.participants ?? [];

	let result = str;

	switch (str) {
		case "Home":
			result = team1;
			break;
		case "Away":
			result = team2;
			break;
		case "Draw":
			result = i18n.t(`betText.${gameType}.Draw`);
			break;
		case "Neither":
			result = i18n.t(`betText.${gameType}.Neither`);
			break;
		case "HomeOrDraw":
			result = team1 + " " + i18n.t(`betText.${gameType}.or`) + " " + i18n.t(`betText.${gameType}.Draw`);
			break;
		case "HomeOrAway":
			result = team1 + " " + i18n.t(`betText.${gameType}.or`) + " " + team2;
			break;
		case "AwayOrDraw":
			result = team2 + " " + i18n.t(`betText.${gameType}.or`) + " " + i18n.t(`betText.${gameType}.Draw`);
			break;
		case "HomeAndUnder":
			result = team1 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeAndOver":
			result = team1 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "DrawAndUnder":
			result = i18n.t(`betText.${gameType}.Draw`) + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "DrawAndOver":
			result = i18n.t(`betText.${gameType}.Draw`) + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "AwayAndUnder":
			result = team2 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.under`);
			break;
		case "AwayAndOver":
			result = team2 + " " + i18n.t(`betText.${gameType}.and`) + " " + i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrDrawAndOver":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrAwayAndOver":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				team2 +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "AwayOrDrawAndOver":
			result =
				team2 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.over`);
			break;
		case "HomeOrDrawAndUnder":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeOrAwayAndUnder":
			result =
				team1 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				team2 +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "AwayOrDrawAndUnder":
			result =
				team2 +
				" " +
				i18n.t(`betText.${gameType}.or`) +
				" " +
				i18n.t(`betText.${gameType}.Draw`) +
				" " +
				i18n.t(`betText.${gameType}.and`) +
				" " +
				i18n.t(`betText.${gameType}.under`);
			break;
		case "HomeWinAfterOvertime":
			result = team1 + " " + i18n.t(`betText.${gameType}.winOverTime`);
			break;
		case "AwayWinAfterOvertime":
			result = team2 + " " + i18n.t(`betText.${gameType}.winOverTime`);
			break;
		case "HomeWinAfterPenalties":
			result = team1 + " " + i18n.t(`betText.${gameType}.winPenalty`);
			break;
		case "AwayWinAfterPenalties":
			result = team2 + " " + i18n.t(`betText.${gameType}.winPenalty`);
			break;
		default:
			if (gameType === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value) {
				if (["CorrectScore", "CorrectScorePerShot"].includes(group)) {
					result = str.replace("Home", "").replace("Away", " : ");
					break;
				} else if (["HomePenaltyShot", "AwayPenaltyShot"].includes(group)) {
					result = `${i18n.t(`markets.${gameType}.${str}`)} R${argument}`;
					break;
				} else if (str.startsWith("Goal")) {
					result = str.replace("Goal", "");
					break;
				} else if (str.startsWith("Miss")) {
					result = `${str} R(${argument})`;
					break;
				} else {
					result = str;
					break;
				}
			}
			if (["Correct6HomeGoalsCount", "Correct6AwayGoalsCount", "Correct6GoalsCount"].includes(group))
				result = str.replace("Goal", "").replace("AndMore", " " + i18n.t(`betText.${gameType}.andMore`));
			if (group === "HalfMatch")
				result = str
					.replace("P1P1", team1 + "/" + team1)
					.replace("P1H", team1 + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("P1P2", team1 + "/" + team2)
					.replace("HP1", i18n.t(`betText.${gameType}.Draw`) + "/" + team1)
					.replace("HH", i18n.t(`betText.${gameType}.Draw`) + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("HP2", i18n.t(`betText.${gameType}.Draw`) + "/" + team2)
					.replace("P2P2", team2 + "/" + team2)
					.replace("P2H", team2 + "/" + i18n.t(`betText.${gameType}.Draw`))
					.replace("P2P1", team2 + "/" + team1);

			/** Racing */
			switch (isRacingGame(gameType, gameCategory)) {
				case str.includes("%Even%"):
					result = str.replace("%Even%", i18n.t(`betText.${gameType}.Even`));
					break;
				case str.includes("%Odd%"):
					result = str.replace("%Odd%", i18n.t(`betText.${gameType}.Odd`));
					break;
				case !isNaN(str):
					result = `#${participants.find((p) => p.number === Number(str)).number}.${participants.find((p) => p.number === Number(str)).name}`;
					break;
				case str.startsWith("Race "):
					result = `#${participants.find((p) => p.number === Number(str.split(" ")[1])).number}.${participants.find((p) => p.number === Number(str.split(" ")[1])).name}`;
					break;
				case /^[0-9]+-[0-9]+$/.test(str):
					result = `${i18n.t(`betText.${gameType}.1st`)} #${participants.find((p) => p.number === Number(str.split("-")[0])).number}.${participants.find((p) => p.number === Number(str.split("-")[0])).name}/${i18n.t(`betText.${gameType}.2nd`)} #${participants.find((p) => p.number === Number(str.split("-")[1])).number}.${participants.find((p) => p.number === Number(str.split("-")[1])).name}`;
					break;
				case /^[0-9]+-[0-9]+-[0-9]+$/.test(str):
					result = `${i18n.t(`betText.${gameType}.1st`)} #${participants.find((p) => p.number === Number(str.split("-")[0])).number}${participants.find((p) => p.number === Number(str.split("-")[0])).name}./${i18n.t(`betText.${gameType}.2nd`)} #${participants.find((p) => p.number === Number(str.split("-")[1])).number}.${participants.find((p) => p.number === Number(str.split("-")[1])).name}/${i18n.t(`betText.${gameType}.3rd`)} #${participants.find((p) => p.number === Number(str.split("-")[2])).number}.${participants.find((p) => p.number === Number(str.split("-")[2])).name}`;
					break;
			}

			if (gameCategory === GAME_CATEGORY.SCHEDULED && gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
				switch (str) {
					case "Yes":
						result = i18n.t(`betText.${gameType}.Even`);
						break;
					case "No":
						result = i18n.t(`betText.${gameType}.Odd`);
						break;
					default:
						break;
				}

				result += argument ? ` ${argument}` : "";
			}

			if (gameCategory === GAME_CATEGORY.SCHEDULED && gameType === SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value) {
				switch (group) {
					case SPIN_TO_WIN_MARKETS.DOZEN:
						result = result + " " + i18n.t(`betText.${gameType}.picks`);
						break;
					case SPIN_TO_WIN_MARKETS.HIGH_LOW:
						result += ` ${str === "Low" ? "1-18" : "19-36"}`;
						break;
					default:
						result = result.replace(/-/g, "/");
				}
			}

			break;
	}

	argument = convertArgumentForHandicapAway(group, str, argument);
	result += getNameofTotalarket(group, argument);

	return result;
};

export const rtpPeriodChecking = (rtpObj, ...periodsArray) => periodsArray.includes(rtpObj.period);
