import {
	BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE,
	BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH,
	BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE,
	BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH,
	BIG_MULTIPLIERS_HISTORY_BEFORE,
	BIG_MULTIPLIERS_HISTORY_FINISH,
	SET_BIG_MULTIPLIERS_CONFIGURATIONS,
	SET_BIG_MULTIPLIERS_HISTORY,
	SET_BIG_MULTIPLIERS_HISTORY_FILTERS,
	SET_BIG_MULTIPLIERS_HISTORY_SORTING,

	BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE,
	BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH,
	BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE,
	BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH,
	BET_ANOMALIES_HISTORY_BEFORE,
	BET_ANOMALIES_HISTORY_FINISH,
	SET_BET_ANOMALIES_CONFIGURATIONS,
	SET_BET_ANOMALIES_HISTORY,
	SET_BET_ANOMALIES_HISTORY_FILTERS,
	SET_BET_ANOMALIES_HISTORY_SORTING,
	BET_ANOMALY_SAVE_BEFORE,
	BET_ANOMALY_SAVE_FINISH,
	BET_ANOMALY_UPDATE,
	
	PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH,
	SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE,
	PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH,
	ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY,
	REMOVE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,
	UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS,

	RISK_MANAGEMENT_TOP_PLAYERS_ACTION_BEFORE,
	RISK_MANAGEMENT_TOP_PLAYERS_ACTION_FINISH,
	RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_BEFORE,
	RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_FINISH,
	RISK_MANAGEMENT_SET_TOP_PLAYERS_FILTERS,
	RISK_MANAGEMENT_SET_TOP_PLAYERS_SORTING,
	RISK_MANAGEMENT_SET_TOP_PLAYERS,
	RISK_MANAGEMENT_SET_TOP_PLAYER_DETAILS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_BEFORE:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					configurations: {
						...state.bigMultipliers.configurations,
						isLoading: true
					}
				}
			};
		case BIG_MULTIPLIERS_CONFIGURATIONS_ACTION_FINISH:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					configurations: {
						...state.bigMultipliers.configurations,
						isLoading: false
					}
				}
			};
		case BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_BEFORE:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					configurations: {
						...state.bigMultipliers.configurations,
						isSaving: true
					}
				}
			};
		case BIG_MULTIPLIERS_CONFIGURATIONS_SAVE_ACTION_FINISH:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					configurations: {
						...state.bigMultipliers.configurations,
						isSaving: false
					}
				}
			};
		case SET_BIG_MULTIPLIERS_CONFIGURATIONS:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					configurations: {
						...state.bigMultipliers.configurations,
						data: payload.data
					}
				}
			};
		case SET_BIG_MULTIPLIERS_HISTORY_FILTERS:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					history: {
						...state.bigMultipliers.history,
						filters: payload.filters
					}
				}
			};
		case SET_BIG_MULTIPLIERS_HISTORY_SORTING:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					history: {
						...state.bigMultipliers.history,
						sorting: payload.sorting
					}
				}
			};
		case BIG_MULTIPLIERS_HISTORY_BEFORE:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					history: {
						...state.bigMultipliers.history,
						isLoading: true
					}
				}
			};
		case BIG_MULTIPLIERS_HISTORY_FINISH:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					history: {
						...state.bigMultipliers.history,
						isLoading: false
					}
				}
			};
		case SET_BIG_MULTIPLIERS_HISTORY:
			return {
				...state,
				bigMultipliers: {
					...state.bigMultipliers,
					history: {
						...state.bigMultipliers.history,
						total: payload.total,
						data: payload.data
					}
				}
			};
		case BET_ANOMALIES_CONFIGURATIONS_ACTION_BEFORE:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isLoading: true
					}
				}
			};
		case BET_ANOMALY_SAVE_BEFORE:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isSaving: true
					}
				}
			};
		case BET_ANOMALY_SAVE_FINISH:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isSaving: false
					}
				}
			};
		case BET_ANOMALIES_CONFIGURATIONS_ACTION_FINISH:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isLoading: false
					}
				}
			};
		case BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_BEFORE:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isSaving: true
					}
				}
			};
		case BET_ANOMALIES_CONFIGURATIONS_SAVE_ACTION_FINISH:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						isSaving: false
					}
				}
			};
		case SET_BET_ANOMALIES_CONFIGURATIONS:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					configurations: {
						...state.betAnomalies.configurations,
						data: payload.data
					}
				}
			};
		case SET_BET_ANOMALIES_HISTORY_FILTERS:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						filters: payload.filters
					}
				}
			};
		case SET_BET_ANOMALIES_HISTORY_SORTING:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						sorting: payload.sorting
					}
				}
			};
		case BET_ANOMALIES_HISTORY_BEFORE:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						isLoading: true
					}
				}
			};
		case BET_ANOMALIES_HISTORY_FINISH:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						isLoading: false
					}
				}
			};
		case SET_BET_ANOMALIES_HISTORY:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						total: payload.total,
						data: payload.data
					}
				}
			};
		case BET_ANOMALY_UPDATE:
			return {
				...state,
				betAnomalies: {
					...state.betAnomalies,
					history: {
						...state.betAnomalies.history,
						data: state.betAnomalies.history.data.map(historyData => {
							if (historyData.id === payload.data.id) {
								return { ...historyData, ...payload.data }
							}
							return historyData
						})
					}
				}
			}
		case PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_BEFORE:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						isLoading: true
					}
				}
			};
		case PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_ACTION_FINISH:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						isLoading: false
					}
				}
			};
		case SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_FILTERS:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						filters: payload.filters
					}
				}
			};
		case SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY_SORTING:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						sorting: payload.sorting
					}
				}
			};
		case SET_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						total: payload.total,
						data: payload.data
					}
				}
			};
		case UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					history: {
						...state.playerSuspiciousActivities.history,
						data: state.playerSuspiciousActivities.history.data.map(record => {
							if (record.id === payload.data.id) {
								return {
									...record,
									...payload.data
								}
							}
							return record
						})
					}
				}
			}
		case PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_BEFORE:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						isLoading: true
					}
				}
			};
		case PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS_ACTION_FINISH:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						isLoading: false
					}
				}
			};
		case SET_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						total: payload.total,
						data: payload.data
					}
				}
			};
		case ADD_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						total: state.playerSuspiciousActivities.configurations.total + 1,
						data: [
							...state.playerSuspiciousActivities.configurations.data,
							{ ...payload.data }
						]
					}
				}
			};
		case UPDATE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						data: state.playerSuspiciousActivities.configurations.data.map(record => {
							if (record.id === payload.data.id) {
								return {
									...record,
									...payload.data
								}
							}
							return record;
						})
					}
				}
			};
		case REMOVE_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						total: state.playerSuspiciousActivities.configurations.total - 1,
						data: state.playerSuspiciousActivities.configurations.data.filter(record => {
							return record.id !== payload.data.id;
						})
					}
				}
			};
		case PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_BEFORE:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						isSaving: true
					}
				}
			};
		case PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATION_SAVE_ACTION_FINISH:
			return {
				...state,
				playerSuspiciousActivities: {
					...state.playerSuspiciousActivities,
					configurations: {
						...state.playerSuspiciousActivities.configurations,
						isSaving: false
					}
				}
			};
		case RISK_MANAGEMENT_TOP_PLAYERS_ACTION_BEFORE:
			return {
				...state,
				topPlayers: {
				...state.topPlayers,
					list: {
						...state.topPlayers.list,
						isLoading: true
					}
				}
			};
		case RISK_MANAGEMENT_TOP_PLAYERS_ACTION_FINISH:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					list: {
						...state.topPlayers.list,
						isLoading: false
					}
				}
			};
		case RISK_MANAGEMENT_SET_TOP_PLAYERS_FILTERS:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					list: {
						...state.topPlayers.list,
						filters: payload.filters
					}
				}
			};
		case RISK_MANAGEMENT_SET_TOP_PLAYERS_SORTING:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					list: {
						...state.topPlayers.list,
						sorting: payload.sorting
					}
				}
			};
		case RISK_MANAGEMENT_SET_TOP_PLAYERS:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					list: {
						...state.topPlayers.list,
						total: payload.total,
						data: payload.data
					}
				}
			};
		case RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_BEFORE:
			return {
				...state,
				topPlayers: {
				...state.topPlayers,
					playerDetails: {
						...state.topPlayers.playerDetails,
						isLoading: true
					}
				}
			};
		case RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_FINISH:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					playerDetails: {
						...state.topPlayers.playerDetails,
						isLoading: false
					}
				}
			};
		case RISK_MANAGEMENT_SET_TOP_PLAYER_DETAILS:
			return {
				...state,
				topPlayers: {
					...state.topPlayers,
					playerDetails: {
						...state.topPlayers.playerDetails,
						...payload,
					}
				}
			}
		default:
			return state;
	}
};
