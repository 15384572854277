import { Fragment } from "react";
import PropTypes from "prop-types";
import OnlineComponent from "./sections/online";
import RetailComponent from "./sections/retail";
import { PROJECT_TYPE } from "constants/common.constants";
import SsbtBetslipsComponent from "./sections/ssbt";

/** Betslips Page Component */
const BetslipsComponent = ({ projectType, showBetsBySeparate }) => {
	return (
		<Fragment>
			{projectType === PROJECT_TYPE.ONLINE ? (
				<OnlineComponent showBetsBySeparate={showBetsBySeparate} />
			) : projectType === PROJECT_TYPE.RETAIL ? (
				<RetailComponent showBetsBySeparate={showBetsBySeparate} />
			) : projectType === PROJECT_TYPE.SSBT ? (
				<SsbtBetslipsComponent showBetsBySeparate={showBetsBySeparate} />
			) : null}
		</Fragment>
	);
};

/** BetslipsComponent propTypes
 * PropTypes
 */
BetslipsComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	showBetsBySeparate: PropTypes.bool
};

export default BetslipsComponent;
