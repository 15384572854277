import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Col, Modal, Select, Divider, Alert } from "antd";
const { Item: FormItem } = Form;

import {
	addScheduledGameToProjects,
} from "store/actions/dashboard/virtuals/games/games.action";


import { POPUP_SIZE, PROJECT_TYPE } from "constants/common.constants";
import useAutosuggestion from "hooks/useAutosuggestion.js";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants.js";
import { GAME_CATEGORY, GAME_CHANNEL } from "constants/game.constants.js";
import { FILTER_GAME_SPECIFICATION_TYPE } from "constants/filter.constants.js";


const ScheduledAddGameToComponent = ({
gameCategory,
globalPartnerId,
onClose,
isSaving,
addScheduledGameToProjectsFromProps,
}) => {
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const { t } = useTranslation();
	const gameNames = useAutosuggestion(AUTOSUGGESTION_TYPE.SCHEDULED_GAME_NAMES, [globalPartnerId, gameCategory], {
		disableAction: () => !globalPartnerId,
		actionArguments: [{ params: { specificationType: FILTER_GAME_SPECIFICATION_TYPE.ALL, gameChannel: GAME_CHANNEL.ONLINE } }]
	});

	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [globalPartnerId, gameCategory], {
		disableAction: () => !globalPartnerId,
		actionArguments: [{ params: { gameCategory, allPartners: true } }]
	});

	const handleForm = async () => {
		try {
			const { gameId, excludedProjectIds } = await validateFields();
			addScheduledGameToProjectsFromProps({
				excludedProjectIds: excludedProjectIds ? excludedProjectIds.map(item => item.key) : [],
				gameId,
				projectType: PROJECT_TYPE.ONLINE,
			}, onClose);
		} catch (error) {
			console.log(error);
		}
	};

	const setGame = (e) => {
		setFieldsValue({ gameId: e });
	};

	const setApiKeyNames = (_, data) => {
		setFieldsValue({ excludedProjectIds: data });
	}

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.games.add_game_to")}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: isSaving }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{}}
			>
				<Alert
					style={{
						padding: '8px 15px'
					}}
					description={t("pages.dashboard.games.add_game_to_note")}
					type="info"
					showIcon
				/>

				<Col span={24}>
					<FormItem
						name="gameId"
						rules={[{ required: true, message: t("validation.field_required") }]}
					>
						<Select
							placeholder={`${t("pages.dashboard.games.select_game")}`}
							getPopupContainer={() => document.body} onChange={setGame} suffixIcon={<i className="icon-down" />}>
							{gameNames.map((game) => (
								<Select.Option value={game.key} key={game.key}>
									{game.value}
								</Select.Option>
							))}
						</Select>
					</FormItem>
					<Divider orientation="left" orientationMargin="0">{t("pages.dashboard.games.excluded_project")}</Divider>
					<FormItem
						name="excludedProjectIds"
					>
						<Select
							className={'multiple-select-overflow'}
							mode="multiple"
							placeholder={`${t("pages.dashboard.games.search_project")}`}
							onChange={setApiKeyNames}
							options={apiKeyNames}
							getPopupContainer={() => document.body}
						/>
					</FormItem>
				</Col>
			</Form>
		</Modal>
	);
};


ScheduledAddGameToComponent.propTypes = {
	isSaving: PropTypes.bool,
	addScheduledGameToProjectsFromProps: PropTypes.func,
	globalPartnerId: PropTypes.string,
	onClose: PropTypes.func,
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
};

const mapDispatchToProps = (dispatch) => ({
	addScheduledGameToProjectsFromProps: (arg, onSuccess) => {
		dispatch(addScheduledGameToProjects(arg, onSuccess))
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.games.addScheduledGameToProjectsLoading,
		globalPartnerId: state.partner.globalPartnerId,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledAddGameToComponent);
