import { connect } from "react-redux";

import ApiUrls from "constants/api.constants";
import { PROJECT_TYPE } from "constants/common.constants";

import PendingBetsTableComponent from "../../common/pending/tableByBetSlip.component";
import NewPendingBetsTableComponent from "../../common/pending/tableByBet.component";

import {
	setPendingBetsSorting,
	setPendingBetsFilters,
	getPendingBets,
	cancelPendingBet,
	cancelPendingBetslip
} from "store/actions/dashboard/betslips/pendings.action";

import { PERMISSION_RESOURCE } from "constants/permissions.constants";

const SsbtPendingBetsComponent = ({
	bets,
	sorting,
	filters,
	total,
	isLoading,
	getPendingBets,
	cancelPendingBet,
	cancelPendingBetslip,
	setPendingBetsSorting,
	setPendingBetsFilters,
	gameCategory,
	showBetsBySeparate
}) => {
	const Component = showBetsBySeparate ? NewPendingBetsTableComponent : PendingBetsTableComponent;
	const exportURL = ApiUrls.EXPORT_SSBT_BETSLIPS;

	return (
		<Component
			bets={bets}
			sorting={sorting}
			filters={filters}
			total={total}
			isLoading={isLoading}
			projectType={PROJECT_TYPE.SSBT}
			getPendingBets={getPendingBets}
			cancelPendingBet={cancelPendingBet}
			cancelPendingBetslip={cancelPendingBetslip}
			setPendingBetsSorting={setPendingBetsSorting}
			setPendingBetsFilters={setPendingBetsFilters}
			exportURL={exportURL + "?isPending=" + true}
			permission={PERMISSION_RESOURCE.BETSLIPS_SSBT}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslips.pendings.ssbt.bets,
		total: state.betslips.pendings.ssbt.total,
		sorting: state.betslips.pendings.ssbt.sorting,
		filters: state.betslips.pendings.ssbt.filters,
		isLoading: state.betslips.isLoading
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = false }) => ({
	getPendingBets: (fromFirstPage, gameCategory) => {
		dispatch(getPendingBets(PROJECT_TYPE.SSBT, fromFirstPage, gameCategory, showBetsBySeparate));
	},
	cancelPendingBet: ({ id, betId, reason, gameCategory }) => {
		dispatch(cancelPendingBet({ id, betId, reason, projectType: PROJECT_TYPE.SSBT, gameCategory }));
	},
	cancelPendingBetslip: ({ id, reason, gameCategory, showBetsBySeparate }) => {
		dispatch(cancelPendingBetslip({ id, reason, projectType: PROJECT_TYPE.SSBT, gameCategory, showBetsBySeparate }));
	},
	setPendingBetsSorting: (sorting) => {
		dispatch(setPendingBetsSorting(sorting, PROJECT_TYPE.SSBT));
	},
	setPendingBetsFilters: (filters) => {
		dispatch(setPendingBetsFilters(filters, PROJECT_TYPE.SSBT));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SsbtPendingBetsComponent);
