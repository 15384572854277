import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from "components/common/table";
import OnlineFilters from "../../online/pending/filters.component";
import RetailFilters from "../../retail/pending/filters.component";
import SsbtFilters from "../../ssbt/pending/filters.component";

import ColumnsButton from "components/common/columnsButton";
import ExportButton from "components/common/exportButton";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { BETSLIP_MODES, KEYS_OF_LOCAL_STORAGE } from "constants/bet.constants";
import { SCHEDULED_GAME_TYPE, GAME_EVENT_TYPE, SCHEDULED_GAME_TYPE_MAPPER, GAME_CATEGORY, GAME_TYPES } from "constants/game.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { PERMISSION_ACTION } from "constants/permissions.constants";
import { FILTER_SHOW_IN_CURRENCY } from "constants/filter.constants";
import { BONUS_TYPE_TRANSLATION_TEXTS } from "constants/bonus.constants";
import { PAYMENT_TYPE_TEXT } from "constants/partner.constants";

import { makeBetText } from "utils/markets";
import { hasPermission } from "utils/permissions";
import { getPartnerCurrency } from "utils/currency";
import {
	isBetslipCancelable,
	isEventIsNotFinishedYet,
	isRacingGame,
	isLeagueGame,
	isCupGame,
	getRoundNameOfCupGame,
	getGamesFromConfig,
	isNullish
} from "utils/common";
import {
	columnsTitlesByProjectType,
	getIncludedColumnsFromLocalStorage,
	setIncludedColumnsFromLocalStorage,
	getFilteredColumns,
	convertAmt
} from "utils/betslips";

import betType from "types/bet/bet.type";
import sortingType from "types/common/sorting.type";
import currencyType from "types/common/currency.type";
import { settleBetslips } from "store/actions/dashboard/betslips/pendings.action";

/** Pending Betslips Table Component */
const PendingBetsTableComponent = ({
	getPendingBets,
	cancelPendingBet,
	cancelPendingBetslip,
	settleBetslips,
	setPendingBetsSorting,
	setPendingBetsFilters,
	bets,
	isLoading,
	sorting,
	filters,
	total,
	globalPartnerId,
	projectType,
	exportURL,
	currency,
	currencies,
	filtersComponent,
	permission,
	gameCategory
}) => {
	const { t } = useTranslation();
	const dateRef = useRef(moment().local().format());

	/** Columns list, which can be included/excluded */
	const columnsToInclude = [
		...columnsTitlesByProjectType(projectType, "key"),
		{ title: t("pages.dashboard.bets.ip"), key: "ipAddress" },
		{ title: t("pages.dashboard.bets.game"), key: "gameName" },
		{ title: t("pages.dashboard.bets.event_id"), key: "eventId" },
		{ title: t("pages.dashboard.bets.starts_at"), key: "eventStartDate" },
		{ title: t("pages.dashboard.bets.ends_at"), key: "eventFinishTime" },
		{ title: t("pages.dashboard.bets.bonus_id"), key: "bonusId" },
		{ title: t("pages.dashboard.bets.bonus_type"), key: "bonusType" },
		{ title: t("pages.dashboard.bets.market_id"), key: "marketId" },
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
					{ title: t("pages.dashboard.bets.betting_period_start"), key: "bettingPeriodStartTime" },
					{ title: t("pages.dashboard.bets.betting_period_end"), key: "bettingPeriodEndTime" }
			  ]),
		...(projectType === PROJECT_TYPE.SSBT || (projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED)
			? [
					{ title: t("pages.dashboard.bets.promotionPercent"), key: "promotionPercent" },
					{ title: t("pages.dashboard.bets.promotionAmount"), key: "possiblePromotionAmount" }
			  ]
			: [])
	];

	const [includedColumns, setIncludedColumns] = useState(() => getIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.PENDING_BETSLIPS_SAVED_KEY));

	/** Function which will fire on columns popup apply button click
	 * @function
	 * @param {array} selectedColumns - selected columns
	 * @memberOf PendingBetsTableComponent
	 */
	const handleColumnsApply = (selectedColumns) => {
		setIncludedColumns(selectedColumns);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.bets.id"),
			dataIndex: "id"
		},
		{
			title: t("pages.dashboard.bets.date"),
			dataIndex: "betTime",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
					{
						title: t("pages.dashboard.bets.bet_type"),
						dataIndex: "type",
						render: (value) => {
							switch (value) {
								case BETSLIP_MODES.SINGLE:
									return t("pages.dashboard.bets.single");
								case BETSLIP_MODES.MULTI:
									return t("pages.dashboard.bets.multi");
								default:
									break;
							}
							return "";
						}
					}
			  ]),
		{
			title:
				projectType === PROJECT_TYPE.ONLINE
					? t("pages.dashboard.bets.project")
					: projectType === PROJECT_TYPE.RETAIL
					? t("pages.dashboard.bets.betshop")
					: projectType === PROJECT_TYPE.SSBT
					? t("pages.dashboard.menu.terminal")
					: " - ",
			dataIndex: "projectName"
		},
		...(projectType === PROJECT_TYPE.ONLINE
			? [
					{
						title: t("pages.dashboard.games.gameCategory"),
						dataIndex: "gameCategory",
						render: (value) => t(`pages.dashboard.games.gameCategory_${value}`),
						sorter: false
					}
			  ]
			: []),
		...(projectType === PROJECT_TYPE.ONLINE ? columnsTitlesByProjectType(projectType, "dataIndex") : []),
		...(projectType !== PROJECT_TYPE.SSBT
			? [
					{
						title:
							projectType === PROJECT_TYPE.ONLINE
								? t("pages.dashboard.bets.username")
								: projectType === PROJECT_TYPE.RETAIL
								? t("pages.dashboard.bets.cashier")
								: " - ",
						dataIndex: "userName",
						render: (value, record) => {
							if (projectType === PROJECT_TYPE.ONLINE && record.isTesting) {
								return value + "(Test)";
							}
							return value;
						}
					}
			  ]
			: []),
		{
			title: t("pages.dashboard.bets.currency"),
			dataIndex: "currencyId",
			render: (value, record) => {
				let currencyCode = currency.code;

				if (filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL || value === "FUN") {
					currencyCode = value;
				}

				return getPartnerCurrency(currencyCode, currencies, globalPartnerId, record.betTime);
			}
		},
		{
			title: t("pages.dashboard.bets.odds"),
			dataIndex: "factor",
			render: (value, record) => (record.type === BETSLIP_MODES.MULTI ? Number(value).toFixed(2) : "-"),
			sorter: false
		},
		...(projectType === PROJECT_TYPE.SSBT || (projectType === PROJECT_TYPE.ONLINE && gameCategory === GAME_CATEGORY.SCHEDULED)
			? [
					{
						title: t("pages.dashboard.bets.promotionPercent"),
						dataIndex: "promotionPercent",
						render: (value, record) => {
							return <div>{record.type === BETSLIP_MODES.MULTI && !isNullish(value) ? value : "-"}</div>;
						}
					},
					{
						title: t("pages.dashboard.bets.promotionAmount"),
						dataIndex: "possiblePromotionAmount",
						render: (value, record) => {
							return (
								<div>
									{record.type === BETSLIP_MODES.MULTI && !isNullish(value)
										? convertAmt(value, record, currencies, currency, globalPartnerId, filters)
										: "-"}
								</div>
							);
						}
					}
			  ]
			: []),
		{
			title: t("pages.dashboard.bets.stake"),
			dataIndex: "amount",
			render: (value, record) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
		},
		{
			title: t("pages.dashboard.bets.possible_win"),
			dataIndex: "possibleWin",
			render: (value, record) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
		},
		{
			title: t("pages.dashboard.bets.bonus_id"),
			dataIndex: "bonusId",
			sorter: false,
			render: (value, record) => {
				if (record.bonusId === null) {
					return " - ";
				}
				return <span title={record.bonusId}>{record.bonusId}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.bonus_type"),
			dataIndex: "bonusType",
			render: (value, record) => {
				let bonusTypeText = " - ";
				if (record.bonusId === null) {
					return bonusTypeText;
				}

				bonusTypeText = BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType] ? t(BONUS_TYPE_TRANSLATION_TEXTS[record.bonusType]) : value;

				return <span title={bonusTypeText}>{bonusTypeText}</span>;
			}
		},
		{
			title: t("pages.dashboard.bets.ip"),
			dataIndex: "ipAddress"
		},
		...(projectType === PROJECT_TYPE.RETAIL
			? [
					{
						title: t("pages.dashboard.partners.retail_settings.retail_configs.payment_type"),
						dataIndex: "paymentType",
						render: (value) => t(PAYMENT_TYPE_TEXT[value])
					}
			]
			: [])
	];

	/** Function to get table details data
	 * @function
	 * @param {object} record - row item
	 * @returns {object} - table details data
	 * @memberOf PendingBetsTableComponent
	 */
	const getDetailsData = (record) => {
		if (!record.bets) {
			return null;
		}

		const columns = [
			{
				title: t("pages.dashboard.bets.bet_id"),
				dataIndex: "id"
			},
			{
				title: t("pages.dashboard.bets.game_type"),
				dataIndex: "gameType",
				render: (value) =>
					t(
						`common.${getGamesFromConfig(gameCategory)
							.filter((v) => v.value === value)
							.map((v) => v.type)}`
					)
			},
			{
				title: t("pages.dashboard.bets.game"),
				dataIndex: "gameName"
			},
			{
				title: t("pages.dashboard.bets.event_id"),
				dataIndex: "eventId"
			},
			{
				title: t("pages.dashboard.bets.event"),
				dataIndex: "gameData",
				render: (value, pending) => {
					if (!pending.eventId) {
						return <span className="info-text error-text">{t("common.event_not_found")}</span>;
					}
					if ([GAME_TYPES[gameCategory]?.FOOTBALL_SINGLE_MATCH?.value, SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value].includes(pending.gameType)) {
						return `${pending?.gameData?.team1?.countryName ?? ""} - ${pending?.gameData?.team2?.countryName ?? ""}`;
					}

					if (isLeagueGame(pending.gameType)) {
						const getLeagueGamesEventTitle = () => {
							if (pending.gameType === SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value) {
								return t("common.english_league");
							}
							if (pending.gameType === SCHEDULED_GAME_TYPE.TURKISH_LEAGUE.value) {
								return t("common.turkish_league");
							}
						};

						if (pending.eventType === GAME_EVENT_TYPE.LEAGUE) {
							return getLeagueGamesEventTitle();
						}

						if (pending.gameData) {
							return `${pending?.gameData?.team1?.countryCode ?? ""} - ${pending?.gameData?.team2?.countryCode ?? ""}`;
						}

						return getLeagueGamesEventTitle();
					}

					if (isCupGame(pending.gameType)) {
						if (pending.eventType === GAME_EVENT_TYPE.LEAGUE) {
							return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[pending.gameType]}`);
						}

						if (pending.eventType === GAME_EVENT_TYPE.WEEK) {
							return getRoundNameOfCupGame(pending.eventOrderNumber, pending.gameType);
						}

						if (pending.gameData) {
							return `${pending?.gameData?.team1?.countryCode ?? ""} - ${pending?.gameData?.team2?.countryCode ?? ""}`;
						}

						return t(`common.${SCHEDULED_GAME_TYPE_MAPPER[pending.gameType]}`);
					}

					if (isRacingGame(pending.gameType)) {
						return pending?.gameData?.venue ?? "";
					}

					if (pending.gameType === SCHEDULED_GAME_TYPE.KENO.value) {
						return t("common.keno");
					}

					if (pending.gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
						return t("common.lucky_six");
					}

					return "";
				}
			},
			{
				title: t("pages.dashboard.bets.starts_at"),
				dataIndex: "eventStartDate",
				render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
			},
			{
				title: t("pages.dashboard.bets.ends_at"),
				dataIndex: "eventFinishTime",
				render: (value) => {
					if (value) {
						return moment.utc(value).local().format(DATE_TIME_FORMAT);
					}

					return "";
				}
			},
			...(gameCategory !== GAME_CATEGORY.INSTANT
				? [
						{
							title: t("pages.dashboard.bets.betting_period_start"),
							dataIndex: "bettingPeriodStartTime",
							render: (value) => {
								if (value) {
									return moment.utc(value).local().format(DATE_TIME_FORMAT);
								}

								return "";
							}
						},
						{
							title: t("pages.dashboard.bets.betting_period_end"),
							dataIndex: "bettingPeriodEndTime",
							render: (value) => {
								if (value) {
									return moment.utc(value).local().format(DATE_TIME_FORMAT);
								}

								return "-";
							}
						}
				]
				: []),
			{
				title: t("pages.dashboard.bets.market_id"),
				dataIndex: "marketId",
				render: (value) => value || "-"
			},
			{
				title: t("pages.dashboard.bets.market_selection"),
				dataIndex: "odd",
				render: (value, pending) =>
					makeBetText({
						period: pending.period,
						argument: pending.argument,
						outcome: value,
						group: pending.market,
						gameData: pending.gameData,
						gameType: pending.gameType,
						odd: pending.odd
					})
			},
			{
				title: t("pages.dashboard.bets.odds"),
				dataIndex: "factor"
			},
			{
				title: t("pages.dashboard.bets.stake"),
				dataIndex: "amount",
				render: (value) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
			},
			{
				title: t("pages.dashboard.bets.possible_win"),
				dataIndex: "possibleWin",
				render: (value) => convertAmt(value, record, currencies, currency, globalPartnerId, filters)
			}
		];

		const filteredColumns = getFilteredColumns(columns, columnsToInclude, includedColumns);

		let actions = null;

		if (hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY })) {
			actions = {
				cancelBet: {
					messageKey: "bet_cancel",
					handler: (r, reason) => cancelPendingBet({ id: record.id, betId: r.id, reason, gameCategory }),
					title: t("common.cancel"),
					type: "delete",
					icon: <i className="icon-cancel" />,
					hidden: (r) => !r.allowCancel || record.type === BETSLIP_MODES.MULTI,
					isPrompt: true,
					promptLabel: t("pages.dashboard.bets.cancel_reason"),
					isPromptRequired: true
				}
			};
		}

		return {
			columns: filteredColumns,
			data: record.bets,
			uniqueKey: "id",
			actions
		};
	};

	/** Keep included columns in local storage */
	useEffect(() => {
		setIncludedColumnsFromLocalStorage(KEYS_OF_LOCAL_STORAGE.PENDING_BETSLIPS_SAVED_KEY, includedColumns);
	}, [includedColumns]);

	return (
		<div className="dashboard-section-content">
			<div className="table-header">
				<div className="table-buttons-dropdowns">
					{hasPermission({ resource: permission, action: PERMISSION_ACTION.EXPORT }) ? (
						<ExportButton
							columns={columns.map((c) => ({ title: c.title, key: c.dataIndex }))}
							tableName={t("pages.dashboard.bets.tabs.pending_betslips")}
							url={exportURL}
							filters={filters}
							sorting={sorting}
						/>
					) : null}
					<ColumnsButton columns={columnsToInclude} onApply={handleColumnsApply} defaultSelectedColumns={includedColumns} />
				</div>
				{filtersComponent ? (
					<Fragment>{filtersComponent}</Fragment>
				) : projectType === PROJECT_TYPE.ONLINE ? (
					<OnlineFilters projectType={projectType} gameCategory={gameCategory} />
				) : projectType === PROJECT_TYPE.RETAIL ? (
					<RetailFilters projectType={projectType} gameCategory={gameCategory} />
				) : projectType === PROJECT_TYPE.SSBT ? (
					<SsbtFilters projectType={projectType} gameCategory={gameCategory} />
				) : (
					<Fragment />
				)}
			</div>
			<Table
				loading={isLoading}
				columns={getFilteredColumns(columns, columnsToInclude, includedColumns)}
				data={bets}
				loadFn={(fromFirstPage) => getPendingBets(fromFirstPage, gameCategory)}
				sorting={sorting}
				filters={filters}
				setSortingFn={setPendingBetsSorting}
				setFiltersFn={setPendingBetsFilters}
				total={total}
				updateProps={[globalPartnerId]}
				actions={
					hasPermission({ resource: permission, action: PERMISSION_ACTION.MODIFY })
						? {
								cancelBet: {
									messageKey: "bet_cancel",
									handler: (record, reason) => cancelPendingBetslip({ id: record.id, reason, gameCategory }),
									title: t("common.cancel"),
									hidden: (record) => !isBetslipCancelable(record),
									icon: <i className="icon-cancel" />,
									type: "delete",
									promptLabel: t("pages.dashboard.bets.cancel_reason"),
									isPromptRequired: true,
									isPrompt: true
								},
								settleBetslips: {
									title: t("pages.dashboard.bets.settle_betslisp"),
									messageKey: "settle_betslisp",
									handler: (record) => {
										settleBetslips({ id: record.id, projectType, gameCategory });
									},
									hidden: (record) => isEventIsNotFinishedYet(record, dateRef.current, (_date) => moment.utc(_date).local().format()),
									icon: <i className="icon-settle-betslip" />,
									type: "delete"
								}
						  }
						: {}
				}
				isDisabled={() => false}
				detailsType="table"
				details={getDetailsData}
				enableReload={true}
			/>
		</div>
	);
};

/** PendingBetsTableComponent propTypes
 * PropTypes
 */
PendingBetsTableComponent.propTypes = {
	/** Function to get pending bets */
	getPendingBets: PropTypes.func,
	/** Function to cancel pending bet */
	cancelPendingBet: PropTypes.func,
	/** Function to cancel pending bet slip */
	cancelPendingBetslip: PropTypes.func,
	/** Function to settle pending bet slip */
	settleBetslip: PropTypes.func,
	/** The array of pending bets */
	bets: PropTypes.arrayOf(betType),
	/** Is true when loading pending bets */
	isLoading: PropTypes.bool,
	/** Function to set pending bets sorting details */
	setPendingBetsSorting: PropTypes.func,
	/** Function to set pending bets filters */
	setPendingBetsFilters: PropTypes.func,
	/** Pending bets sorting details */
	sorting: sortingType,
	/** Ppending bets filters */
	filters: PropTypes.object,
	/** Pending bets total count */
	total: PropTypes.number,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** The url to export table data */
	exportURL: PropTypes.string,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType),
	/** The JSX content of filters */
	filtersComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	/** The permission for actions */
	permission: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

const mapDispatchToProps = (dispatch) => ({
	settleBetslips: ({ id, projectType, gameCategory }) => {
		dispatch(settleBetslips({ ids: [id], projectType, gameCategory }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingBetsTableComponent);
