import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Radio, Select } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";

import { addBetshopBox } from "store/actions/dashboard/retail/betshops/box.action";

import { BOX_TYPE } from "constants/box.constants";
import { POPUP_SIZE } from "constants/common.constants";
import useAutosuggestion from "hooks/useAutosuggestion.js";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants.js";

/** Betshop Box Adding Popup Component */
const BoxAddComponent = ({ isSaving, addBetshopBox, onClose, boxes }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [selectedBoxType, setSelectedBoxType] = useState(BOX_TYPE.VIRTUAL);

	/** Fires when form submitted
	 * @function
	 * @memberOf BoxAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ id }) => {
				addBetshopBox(routeParams.id, id);
			})
			.catch(Function.prototype);
	};

	return (
		<Modal open={true} title={t("pages.dashboard.betshops.add_new_box")} cancelText={t("common.cancel")} okText={t("common.save")} onOk={handleForm} onCancel={onClose} width={POPUP_SIZE.SMALL} maskClosable={false} closable={false} okButtonProps={{ loading: isSaving }} centered>
			<Form className="dashboard-form" form={formInstance} colon={false} layout="vertical" requiredMark={false}>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24}>
						<FormItem label={t("pages.dashboard.betshops.select_box")} name="id" rules={[{ required: true, message: t("validation.field_required") }]}>
							<SearchableSelect
								placeholder={t("pages.dashboard.betshops.select_box")}
								items={boxes}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.value}
								getPopupContainer={() => document.body}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** BoxAddComponent propTypes
 * PropTypes
 */
BoxAddComponent.propTypes = {
	/** Redux state property, is true when adding box for betshop */
	isSaving: PropTypes.bool,
	/** Redux action to add box to betshop*/
	addBetshopBox: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of available boxes  */
	boxes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.string
		})
	),
};

const mapDispatchToProps = (dispatch) => ({
	addBetshopBox: (id, boxId) => {
		dispatch(addBetshopBox(id, boxId));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.betshops.isSaving,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxAddComponent);
