import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Input } from "antd";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import { setPendingBetsFilters, getPendingBets } from "store/actions/dashboard/betslips/pendings.action";
import useAutosuggestion from "hooks/useAutosuggestion";
import {
	FILTER_BETSLIP_MODES,
	FILTER_TEST_STATE,
	FILTER_SHOW_IN_CURRENCY,
	FILTER_BETSLIP_MODE_TYPE,
	FILTER_REPORT_BONUS_TYPE
} from "constants/filter.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { PAYMENT_TYPE, PAYMENT_TYPE_TEXT } from "constants/partner.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { getGamesFromConfig } from "utils/common";
const { Item: FormItem } = Form;

/** Pending Bets Page Filters Component */
const Filters = ({ setPendingBetsFilters, getPendingBets, projectNamesAutosuggestionType, filters, projectType, globalPartnerId, gameCategory }) => {
	const { t } = useTranslation();
	const [gameTypeFilter, setGameTypeFilter] = useState(filters.gameType);

	const projectNames = useAutosuggestion(projectNamesAutosuggestionType, [globalPartnerId, gameCategory], {
		disableAction: () => !globalPartnerId,
		actionArguments: [{ params: { gameCategory } }]
	});
	const systemMarkets = useAutosuggestion(AUTOSUGGESTION_TYPE.SYSTEM_MARKETS, [globalPartnerId, projectType, gameCategory], {
		disableAction: () => !globalPartnerId,
		actionArguments: [{ params: { gameCategory } }]
	});

	/** Function , to filter marktes by game type
	 * @function
	 * @returns {array}
	 * @memberOf Filters
	 */
	const getMarketsByGameType = () => {
		let markets = [];
		if (gameTypeFilter && systemMarkets[Number(gameTypeFilter)]) {
			markets = systemMarkets[Number(gameTypeFilter)];
		}
		return markets;
	};

	useEffect(() => {
		setGameTypeFilter(filters.gameType);
	}, [filters.gameType]);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{
			name: "betSlipId",
			title: projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.betslip_id") : t("pages.dashboard.bets.ticket_id")
		},
		{ name: "betId", title: t("pages.dashboard.bets.bet_id") },
		{
			name: "projectIds",
			title:
				projectType === PROJECT_TYPE.ONLINE
					? t("pages.dashboard.bets.project")
					: projectType === PROJECT_TYPE.RETAIL
					? t("pages.dashboard.bets.betshop")
					: t("pages.dashboard.menu.terminal"),
			values: projectNames.map((p) => ({ title: p.value, value: p.key }))
		},
		{ name: "eventId", title: t("pages.dashboard.bets.event_id") },
		{
			name: "gameType",
			title: t("pages.dashboard.bets.game_type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				getGamesFromConfig(gameCategory).map((g) => ({
					title: t(`common.${g.type}`),
					value: g.value
				}))
			)
		},
		{
			name: "marketName",
			title: t("pages.dashboard.bets.market_type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				!!gameTypeFilter
					? getMarketsByGameType().map((m) => ({
							title: t(`markets.${gameTypeFilter}.${m}`),
							value: m
					  }))
					: []
			)
		},
		...(projectType !== PROJECT_TYPE.SSBT
			? [
					{
						name: "userNameOrId",
						title:
							projectType === PROJECT_TYPE.ONLINE
								? t("pages.dashboard.bets.player_id_or_username")
								: t("pages.dashboard.bets.cashier_id_or_username")
					}
			  ]
			: []),
		{
			name: "userExternalId",
			title: t("pages.dashboard.bets.player_external_id")
		},
		...(gameCategory === GAME_CATEGORY.INSTANT
			? []
			: [
					{
						name: "type",
						title: t("pages.dashboard.bets.bet_type"),
						values: [
							{
								title: t("common.all"),
								value: FILTER_BETSLIP_MODES.ALL
							},
							{
								title: t("pages.dashboard.bets.single"),
								value: FILTER_BETSLIP_MODES.SINGLE
							},
							{
								title: t("pages.dashboard.bets.multi"),
								value: FILTER_BETSLIP_MODES.MULTI
							}
						]
					}
			  ]),
		...(projectType !== PROJECT_TYPE.SSBT
			? [
					{
						name: "testState",
						title: t("common.data_type"),
						values: [
							{ title: t("common.all"), value: FILTER_TEST_STATE.ALL },
							{ title: t("common.testing"), value: FILTER_TEST_STATE.TESTING },
							{ title: t("common.not_testing"), value: FILTER_TEST_STATE.NOTTESTING }
						]
					}
			  ]
			: []),
		...(projectType !== PROJECT_TYPE.SSBT
			? [
					{
						name: "demoState",
						title: t("pages.dashboard.bets.mode_type"),
						values: [
							{
								title: t("pages.dashboard.bets.real"),
								value: FILTER_BETSLIP_MODE_TYPE.REAL
							},
							{
								title: t("pages.dashboard.bets.demo"),
								value: FILTER_BETSLIP_MODE_TYPE.DEMO
							}
						]
					}
			  ]
			: []),
		{ name: "bonusId", title: t("pages.dashboard.bets.bonus_id") },
		{
			name: "betType",
			title: t("pages.dashboard.bets.betslips"),
			values: [
				{ title: t("common.all"), value: FILTER_REPORT_BONUS_TYPE.ALL },
				{
					title: t("pages.dashboard.bets.only_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.ONLY_BONUS
				},
				{
					title: t("pages.dashboard.bets.without_bonus"),
					value: FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS
				}
			]
		},
		...(projectType === PROJECT_TYPE.RETAIL
			? [
					{
						name: "paymentType",
						title: t("pages.dashboard.partners.retail_settings.retail_configs.payment_type"),
						values: [
							{
								title: t(PAYMENT_TYPE_TEXT[PAYMENT_TYPE.BOTH]),
								value: PAYMENT_TYPE.BOTH
							},
							{
								title: t(PAYMENT_TYPE_TEXT[PAYMENT_TYPE.CASH]),
								value: PAYMENT_TYPE.CASH
							},
							{
								title: t(PAYMENT_TYPE_TEXT[PAYMENT_TYPE.CREDIT_CARD]),
								value: PAYMENT_TYPE.CREDIT_CARD
							}
						]
					}
			  ]
			: []),
		{
			name: "showIn",
			title: t("pages.dashboard.bets.show_in"),
			values: [
				{
					title: t("pages.dashboard.bets.original_currency"),
					value: FILTER_SHOW_IN_CURRENCY.ORIGINAL
				},
				{
					title: t("pages.dashboard.bets.converted_currency"),
					value: FILTER_SHOW_IN_CURRENCY.CONVERTED
				}
			]
		}
	];

	return (
		<FiltersWrapper
			filtersName={`pendings_${projectType}_${gameCategory}`}
			loadFn={() => getPendingBets(projectType, gameCategory)}
			setFiltersFn={(filters) => setPendingBetsFilters(filters, projectType)}
			filters={filters}
			dependencies={[{ field: "gameType", resetField: "marketName", resetValue: "" }]}
			filtersList={filtersList()}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.betslip_id") : t("pages.dashboard.bets.ticket_id")}
							name="betSlipId"
						>
							<NumericInput
								placeholder={`${t("common.enter")} ${
									projectType === PROJECT_TYPE.ONLINE ? t("pages.dashboard.bets.betslip_id") : t("pages.dashboard.bets.ticket_id")
								}`}
								isInteger={true}
								maxLength={12}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bet_id")} name="betId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bet_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={
								projectType === PROJECT_TYPE.ONLINE
									? t("pages.dashboard.bets.project")
									: projectType === PROJECT_TYPE.RETAIL
									? t("pages.dashboard.bets.betshop")
									: t("pages.dashboard.menu.terminal")
							}
							name="projectIds"
						>
							<SearchableSelect
								items={projectNames}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.value}
								mode="multiple"
								placeholder={`${t("common.select")} ${t("pages.dashboard.bets.project")}`}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.event_id")} name="eventId">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.event_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.game_type")} name="gameType">
							<Select suffixIcon={<i className="icon-down" />} onChange={(e) => setGameTypeFilter(e)}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{getGamesFromConfig(gameCategory).map((g) => (
									<Select.Option value={g.value} key={g.value}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.market_type")} name="marketName" dependencies={["gameType"]}>
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{getMarketsByGameType().map((market) => (
									<Select.Option key={market} value={market}>
										{t(`markets.${gameTypeFilter}.${market}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					{projectType !== PROJECT_TYPE.SSBT && (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem
								label={
									projectType === PROJECT_TYPE.ONLINE
										? t("pages.dashboard.bets.player_id_or_username")
										: t("pages.dashboard.bets.cashier_id_or_username")
								}
								name="userNameOrId"
							>
								<Input
									placeholder={`${t("common.enter")} ${
										projectType === PROJECT_TYPE.ONLINE
											? t("pages.dashboard.bets.player_id_or_username")
											: t("pages.dashboard.bets.cashier_id_or_username")
									}`}
								/>
							</FormItem>
						</Col>
					)}
					{projectType === PROJECT_TYPE.ONLINE && (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem label={t("pages.dashboard.bets.player_external_id")} name="userExternalId">
								<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.player_external_id")}`} />
							</FormItem>
						</Col>
					)}
					{gameCategory === GAME_CATEGORY.INSTANT ? null : (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem label={t("pages.dashboard.bets.bet_type")} name="type">
								<Select suffixIcon={<i className="icon-down" />}>
									<Select.Option value={FILTER_BETSLIP_MODES.ALL}>{t("common.all")}</Select.Option>
									<Select.Option value={FILTER_BETSLIP_MODES.SINGLE}>{t("pages.dashboard.bets.single")}</Select.Option>
									<Select.Option value={FILTER_BETSLIP_MODES.MULTI}>{t("pages.dashboard.bets.multi")}</Select.Option>
								</Select>
							</FormItem>
						</Col>
					)}
					{projectType !== PROJECT_TYPE.SSBT && (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem label={t("common.data_type")} name="testState">
								<Select suffixIcon={<i className="icon-down" />}>
									<Select.Option value={FILTER_TEST_STATE.ALL}>{t("common.all")}</Select.Option>
									<Select.Option value={FILTER_TEST_STATE.TESTING}>{t("common.testing")}</Select.Option>
									<Select.Option value={FILTER_TEST_STATE.NOTTESTING}>{t("common.not_testing")}</Select.Option>
								</Select>
							</FormItem>
						</Col>
					)}
					{projectType !== PROJECT_TYPE.SSBT && (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem label={t("pages.dashboard.bets.mode_type")} name="demoState">
								<Select suffixIcon={<i className="icon-down" />}>
									<Select.Option value={FILTER_BETSLIP_MODE_TYPE.REAL}>{t("pages.dashboard.bets.real")}</Select.Option>
									<Select.Option value={FILTER_BETSLIP_MODE_TYPE.DEMO}>{t("pages.dashboard.bets.demo")}</Select.Option>
								</Select>
							</FormItem>
						</Col>
					)}
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.bonus_id")} name="bonusId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.bonus_id")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.betslips")} name="betType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.ONLY_BONUS}>{t("pages.dashboard.bets.only_bonus")}</Select.Option>
								<Select.Option value={FILTER_REPORT_BONUS_TYPE.WITHOUT_BONUS}>{t("pages.dashboard.bets.without_bonus")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					{projectType === PROJECT_TYPE.RETAIL ? (
						<Col xs={24} sm={12} lg={6} xl={4}>
							<FormItem label={t("pages.dashboard.partners.retail_settings.retail_configs.payment_type")} name="paymentType">
								<Select suffixIcon={<i className="icon-down" />}>
									<Select.Option value={PAYMENT_TYPE.BOTH}>{t("common.all")}</Select.Option>
									<Select.Option value={PAYMENT_TYPE.CASH}>
										{t("pages.dashboard.partners.retail_settings.retail_configs.cash")}
									</Select.Option>
									<Select.Option value={PAYMENT_TYPE.CREDIT_CARD}>
										{t("pages.dashboard.partners.retail_settings.retail_configs.credit_card")}
									</Select.Option>
								</Select>
							</FormItem>
						</Col>
					) : null}
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.bets.show_in")} name="showIn">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_SHOW_IN_CURRENCY.ORIGINAL}>{t("pages.dashboard.bets.original_currency")}</Select.Option>
								<Select.Option value={FILTER_SHOW_IN_CURRENCY.CONVERTED}>{t("pages.dashboard.bets.converted_currency")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get pending bets */
	getPendingBets: PropTypes.func,
	/** Redux action to set pending bets filters */
	setPendingBetsFilters: PropTypes.func,
	/** Pending bets filters */
	filters: PropTypes.object,
	/** Project name autosuggestion type */
	projectNamesAutosuggestionType: PropTypes.oneOf(Object.values(AUTOSUGGESTION_TYPE)),
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId
	};
};

const mapDispatchToProps = (dispatch, { showBetsBySeparate = false }) => ({
	getPendingBets: (projectType, gameCategory) => {
		dispatch(getPendingBets(projectType, false, gameCategory, showBetsBySeparate));
	},
	setPendingBetsFilters: (filters, projectType) => {
		dispatch(setPendingBetsFilters(filters, projectType));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
