import moment from "moment";

import { yesterday, getNow } from "utils/dateTime";
import {
	flagsToBinary,
	getGameDefaultFilters,
	getOnlineEventsDefaultState,
	getRetailEventsDefaultState,
	getSSBTEventsDefaultState
} from "utils/common";
import { WEEKDAYS, ORDER_DIRECTION, PROJECT_TYPE, REDEMPTION_DATE_VALUES } from "constants/common.constants";
import {
	WORKING_HOURS_TYPE,
	TICKET_GENERAL_DETAILS,
	TICKET_MARKET_DETAILS,
	PARTNER_SECURITY_PASSWORD_PATTERN,
	PAYMENT_TYPE
} from "constants/partner.constants";
import { USER_GENDER, USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";
import {
	SCHEDULED_GAME_TYPE,
	GAME_CYCLE_MINUTES,
	GAME_CATEGORY,
	REDUX_EDITING_API_KEY_GAME_FIELD,
	REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD,
	REDUX_EDITING_API_KEY_PLATFORMS_FIELD,
	INSTANT_GAME_TYPE,
	GAME_TEAM_FORMAT
} from "constants/game.constants";
import { DASHBOARD_PERIOD } from "constants/dashboard.constants";
import { BETSHOP_BONUS_CONFIG_TYPE } from "constants/betshop.constants";
import { LOGIN_CONFIGURATION_TYPE } from "constants/apiKey.constants";
import { SURVEY_STATE } from "constants/survey.constants";

import localStorageUtils from "utils/localStorage";
import sessionStorageUtils from "utils/sessionStorage";

import {
	FILTER_STATUS,
	FILTER_TEST_STATE,
	FILTER_GAME_SPECIFICATION_TYPE,
	FILTER_BETSLIP_MODES,
	FILTER_BETSLIP_MODE_TYPE,
	FILTER_BET_STATE,
	FILTER_EVENT_STATE,
	FILTER_ACTIVITY_TYPE,
	FILTER_TRANSACTION_STATUS,
	FILTER_USER_STATE,
	FILTER_USER_ONLINE_STATE,
	FILTER_REQUEST_API_TYPE,
	FILTER_SHOW_IN_CURRENCY,
	FILTER_PRINT_TYPE,
	FILTER_SETTLEMENT_PRINTED,
	FILTER_BONUS_TYPE,
	FILTER_BONUS_STATE,
	FILTER_JACKPOT_BONUS_LEVEL,
	FILTER_JACKPOT_BONUS_STATE,
	FILTER_JACKPOT_BONUS_TYPE,
	FILTER_REPORT_BONUS_TYPE
} from "constants/filter.constants";

import { LOGIN_VIEW_COMPONENTS } from "../constants/auth.constants";
import { getDefaultStateOfHistoryRules, getDefaultStateOfMainRules } from "utils/rules";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { RISK_MANAGEMENT_ACTION_TYPES, RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS } from "constants/riskManagement.constants";

export default {
	auth: {
		activeComponentName: LOGIN_VIEW_COMPONENTS.MAIN_LOGIN_COMPONENT,
		isLoading: false,
		expires: -1,
		QRBase64: null,
		activation: {
			isActivating: false
		},
		mainLogin: {
			temporaryLockMessageVisible: false,
			unlockDate: null
		},
		twoFactorData: {
			tokenType: null,
			token: null,
			userName: null
		},
		passwordSettings: {
			isLoading: false,
			passwordSettings: {}
		},
		resetPassword: {
			isReseting: false
		},
		forgotPassword: {
			isSaving: false
		}
	},
	permissions: {
		permissions: [],
		isLoading: false,
		isResourcesLoading: false,
		resources: []
	},
	partners: {
		partners: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		filters: {
			nameOrId: ""
		},
		wallet: {
			isLoading: false,
			isSaving: false,
			wallet: {
				balance: 0
			}
		},
		viewingPartner: {
			activity: {
				activities: [],
				isLoading: false,
				total: 0,
				filters: {
					transactionId: "",
					type: FILTER_ACTIVITY_TYPE.ALL,
					userNameOrId: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "ChangeTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		}
	},
	partner: {
		globalPartnerId: sessionStorageUtils.get("selected_partner_id") || null,
		globalPartners: [],
		isLoading: false,
		generalInfo: {
			partnerId: "",
			name: "",
			contactName: "",
			email: "",
			emergencyEmail: "",
			tel: "",
			emergencyTel: "",
			fax: "",
			countryCode: "",
			city: "",
			address: "",
			postalCode: "",
			type: 2
		},
		terminalSettings: {
			terminalConfigs: {
				endpointUrl: null,
				secret: null,
				sendAdditionalData: false,
				ticketRedemptionPeriod: REDEMPTION_DATE_VALUES.THIRTHY_DAYS,
				comboBoostSettings: {
					isComboBoostEnabled: false,
					minOddFactor: null,
					boostFactors: {
						fourBets: null,
						fiveBets: null,
						sixBets: null,
						sevenBets: null,
						eightBets: null,
						nineBets: null,
						tenBets: null
					}
				}
			},
			terminalRTP: {
				allowOverride: false,
				minPossibleValue: 0,
				maxPossibleValue: 1
			}
		},
		onlineSettings: {
			onlineConfigs: {
				type: 1,
				projectCountLimit: 0,
				projectCount: 0,
				isSplitStakeEnabled: true,
				allowBetCancellation: true,
				isStreamOpenedByDefault: true,
				comboBoostSettings: {
					isComboBoostEnabled: false,
					minOddFactor: null,
					boostFactors: {
						fourBets: null,
						fiveBets: null,
						sixBets: null,
						sevenBets: null,
						eightBets: null,
						nineBets: null,
						tenBets: null
					}
				},
				quickBet: undefined
			},
			onlineRTP: {
				allowOverride: false,
				minPossibleValue: 0,
				maxPossibleValue: 1
			}
		},
		retailSettings: {
			retailConfigs: {
				betShopCountLimit: 0,
				betShopCount: 0,
				ticketRedemptionPeriod: REDEMPTION_DATE_VALUES.THIRTHY_DAYS,
				workingHours: {
					weekDays: {
						[WEEKDAYS.MONDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.TUESDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.WEDNESDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.THURSDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.FRIDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.SATURDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.SUNDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN }
					},
					allowOverride: true
				},
				publicHolidays: {
					days: [],
					disableCashierSoftware: true
				}
			},
			retailRTP: {
				allowOverride: false,
				minPossibleValue: 0,
				maxPossibleValue: 1
			}
		},
		securitySettings: {
			passwordSettings: {
				passwordMinLimit: 12,
				passwordMaxLimit: 24,
				forceChange: 3,
				pattern: {
					type: PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS,
					contain: [],
					alsoIncludeCharacters: "",
					regularExpression: ""
				}
			},
			tokenMultidevice: {
				lifetimeMinutes: 15,
				multideviceLogin: true
			},
			ipRestriction: {
				restrictedCountries: []
			},
			authenticationSettings: {
				twoFactorEnabled: true,
				loginSettings: {
					failedLoginAttempts: 3,
					autoUnlockTime: 1,
					autoUnlockCount: 3
				},
				twoFASettings: {
					failedLoginAttempts: 3,
					autoUnlockTime: 1,
					autoUnlockCount: 3
				}
			}
		},
		currency: {
			currencies: [],
			isLoading: false,
			availableCurrencies: {},
			isAvailableLoading: false
		},
		language: {
			languages: [],
			isLoading: false,
			availableLanguages: {},
			isAvailableLoading: false
		}
	},
	apikeys: {
		apiKeys: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			nameOrId: "",
			gameId: "",
			from: "",
			to: "",
			lastUpdateFrom: "",
			lastUpdateTo: "",
			itemState: FILTER_STATUS.ALL,
			testState: FILTER_TEST_STATE.ALL
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingApikey: {
			generalInfo: {
				description: "",
				defaultLanguageCode: "",
				defaultCurrencyCode: "",
				endPointURL: "",
				secret: "",
				name: "",
				isTesting: true,
				loginConfiguration: {
					type: LOGIN_CONFIGURATION_TYPE.POPUP
				},
				gameCategoryList: []
			},
			games: {
				[REDUX_EDITING_API_KEY_GAME_FIELD[GAME_CATEGORY.SCHEDULED]]: [],
				[REDUX_EDITING_API_KEY_GAME_FIELD[GAME_CATEGORY.INSTANT]]: [],
				isLoading: false
			},
			rtp: {
				isLoading: false,
				data: {
					allowOverride: true,
					minPossibleValue: 0,
					maxPossibleValue: 0,
					rtPs: []
				}
			},
			otherConfigs: {
				isLoading: false,
				data: {
					gameLable: ""
				}
			},
			currencies: {
				currencies: [],
				isLoading: false
			},
			[REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD[GAME_CATEGORY.SCHEDULED]]: [],
			[REDUX_EDITING_API_KEY_ANALITICAL_TOOLS_FIELD[GAME_CATEGORY.INSTANT]]: [],
			[REDUX_EDITING_API_KEY_PLATFORMS_FIELD[GAME_CATEGORY.SCHEDULED]]: {
				desktop: true,
				android: true,
				ios: true
			},
			[REDUX_EDITING_API_KEY_PLATFORMS_FIELD[GAME_CATEGORY.INSTANT]]: {
				desktop: true,
				android: true,
				ios: true
			},
			ipRestriction: {
				blockedCountries: {
					countries: [],
					isLoading: false
				},
				blockedIPs: {
					ips: [],
					isLoading: false
				}
			},
			customization: {
				ui: {},
				previewUI: {}
			},
			addOns: {
				data: {
					isPartnerLevelComboBoostEnabled: false,
					isComboBoostEnabled: true,
					minOddFactor: null,
					boostFactors: {
						fourBets: null,
						fiveBets: null,
						sixBets: null,
						sevenBets: null,
						eightBets: null,
						nineBets: null,
						tenBets: null
					}
				},
				isLoading: false,
				isSaving: false
			}
		}
	},
	ssbts: {
		ssbts: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			nameOrId: "",
			gameId: "",
			from: "",
			to: "",
			lastUpdateFrom: "",
			lastUpdateTo: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingSSBT: {
			name: "",
			gamesLoading: false,
			games: [],
			otherConfigs: {
				isLoading: false,
				data: {
					gameLabel: ""
				}
			},
			addOns: {
				data: {
					isPartnerLevelComboBoostEnabled: false,
					isComboBoostEnabled: true,
					minOddFactor: null,
					boostFactors: {
						fourBets: null,
						fiveBets: null,
						sixBets: null,
						sevenBets: null,
						eightBets: null,
						nineBets: null,
						tenBets: null
					}
				},
				isLoading: false,
				isSaving: false
			}
		}
	},
	betslips: {
		isLoading: false,
		isSaving: false,
		pendings: {
			online: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					userNameOrId: "",
					userExternalId: "",
					type: FILTER_BETSLIP_MODES.ALL,
					testState: FILTER_TEST_STATE.ALL,
					demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL,
					marketName: ""
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			retail: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					userNameOrId: "",
					type: FILTER_BETSLIP_MODES.ALL,
					testState: FILTER_TEST_STATE.ALL,
					demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					marketName: "",
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL,
					paymentType: PAYMENT_TYPE.BOTH
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			ssbt: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					type: FILTER_BETSLIP_MODES.ALL,
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					marketName: "",
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		},
		settled: {
			online: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					userNameOrId: "",
					userExternalId: "",
					type: FILTER_BETSLIP_MODES.ALL,
					status: [FILTER_BET_STATE.ALL],
					marketName: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h",
					testState: FILTER_TEST_STATE.ALL,
					demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			retail: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					userNameOrId: "",
					type: FILTER_BETSLIP_MODES.ALL,
					status: [FILTER_BET_STATE.ALL],
					marketName: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h",
					testState: FILTER_TEST_STATE.ALL,
					demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL,
					paymentType: PAYMENT_TYPE.BOTH
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			ssbt: {
				bets: [],
				total: 0,
				filters: {
					projectIds: [],
					betSlipId: "",
					betId: "",
					eventId: "",
					gameType: "",
					type: FILTER_BETSLIP_MODES.ALL,
					status: [FILTER_BET_STATE.ALL],
					marketName: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h",
					showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
					bonusId: "",
					betType: FILTER_REPORT_BONUS_TYPE.ALL
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "BetTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		},
		requests: {
			requests: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "RequestTime",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				ticketType: FILTER_PRINT_TYPE.ALL,
				betSlipId: "",
				betShopId: ""
			}
		},
		dbCacheCompare: {
			data: []
		}
	},
	events: {
		isLoading: false,
		event: {},
		isEventLoading: false,
		online: getOnlineEventsDefaultState(),
		retail: getRetailEventsDefaultState(),
		ssbt: getSSBTEventsDefaultState()
	},
	players: {
		isLoading: false,
		isSaving: false,
		players: [],
		total: 0,
		filters: {
			projectIds: [],
			userNameOrId: "",
			externalId: "",
			state: FILTER_USER_STATE.ALL,
			from: moment(yesterday(), `${DATE_FORMAT}`).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
			to: moment(new Date(), `${DATE_FORMAT}`).set("hour", 23).set("minute", 59).set("second", 59).toDate(),
			lastUpdateFrom: "",
			lastUpdateTo: "",
			showIn: FILTER_SHOW_IN_CURRENCY.ORIGINAL,
			testState: FILTER_TEST_STATE.ALL
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "LastActiveAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		quickInfo: {
			isLoading: false,
			quickInfo: {
				personalInfo: {},
				totals: {},
				topMarkets: {
					markets: []
				}
			}
		},
		viewingPlayer: {
			generalInfo: {
				personalInfo: {},
				totals: {},
				topMarkets: {
					markets: []
				}
			},
			sessions: {
				sessions: [],
				total: 0,
				filters: {
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CreateTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			betHistory: {
				pendings: {
					bets: [],
					total: 0,
					filters: {
						betSlipId: "",
						betId: "",
						eventId: "",
						type: FILTER_BETSLIP_MODES.ALL,
						demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
						bonusId: "",
						betType: FILTER_REPORT_BONUS_TYPE.ALL,
						gameCategory: ""
					},
					sorting: {
						page: 1,
						limit: 10,
						orderBy: "BetTime",
						orderDirection: ORDER_DIRECTION.DESC
					}
				},
				settled: {
					bets: [],
					total: 0,
					filters: {
						betSlipId: "",
						betId: "",
						eventId: "",
						type: FILTER_BETSLIP_MODES.ALL,
						status: [FILTER_BET_STATE.ALL],
						demoState: FILTER_BETSLIP_MODE_TYPE.REAL,
						bonusId: "",
						betType: FILTER_REPORT_BONUS_TYPE.ALL,
						from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
						to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
						quickFilters: "last_24_h",
						gameCategory: ""
					},
					sorting: {
						page: 1,
						limit: 10,
						orderBy: "BetTime",
						orderDirection: ORDER_DIRECTION.DESC
					}
				}
			}
		}
	},
	reports: {
		betshopPerformance: {
			betshopPerformanceReport: [],
			isLoading: false,
			filters: {
				id: "",
				from: moment(yesterday(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(getNow(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h",
				gameType: ""
			}
		},
		projectPerformance: {
			projectPerformanceReport: [],
			isLoading: false,
			filters: {
				id: "",
				from: moment(yesterday(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(getNow(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h",
				gameType: "",
				gameCategory: ""
			}
		},
		partnerOnlineReport: {
			partnerOnlineReport: [],
			partnerOnlineReportTotal: {},
			isLoading: false,
			filters: {
				from: moment(yesterday(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				to: moment(getNow(true), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
				quickFilters: "last_24_h"
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "Name",
				orderDirection: ORDER_DIRECTION.DESC
			}
		}
	},
	transactions: {
		isLoading: false,
		isHistoryLoading: false,
		isResendInProcess: false,
		transactionsPageActiveTab: "1",
		rollbacks: {
			rollbacksPageActiveTab: PROJECT_TYPE.ONLINE,
			online: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			ssbt: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			retail: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		},
		results: {
			resultsPageActiveTab: PROJECT_TYPE.ONLINE,
			online: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			retail: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			ssbt: {
				transactions: [],
				total: 0,
				filters: {
					projectId: "",
					betSlipId: "",
					isFailed: FILTER_TRANSACTION_STATUS.ALL,
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "LastActionTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		}
	},
	userLogs: {
		isLoading: false,
		userLogs: [],
		total: 0,
		filters: {
			userNameOrId: "",
			resource: "",
			resourceId: "",
			action: "",
			actionType: "",
			year: moment().year(),
			month: moment().month() + 1
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "LogTime",
			orderDirection: ORDER_DIRECTION.DESC
		},
		resources: {}
	},
	errors: {
		errors: [],
		isLoading: false,
		isErrorLoading: false,
		total: 0,
		filters: {
			userId: "",
			controller: "",
			action: "",
			year: moment().year(),
			month: moment().month() + 1,
			excludeController: [],
			excludeAction: ""
		},
		sorting: {
			page: 1,
			limit: 10
		},
		error: null
	},
	requests: {
		requests: [],
		isLoading: false,
		isRequestLoading: false,
		total: 0,
		filters: {
			userId: "",
			aPIType: FILTER_REQUEST_API_TYPE.ADMIN,
			controller: "",
			action: "",
			statusCode: "",
			year: moment().year(),
			month: moment().month() + 1
		},
		sorting: {
			page: 1,
			limit: 10
		}
	},
	jobs: {
		jobs: [],
		isLoading: false,
		isSaving: false,
		jobSettings: {}
	},
	monitoring: {
		isLoading: false,
		isServicesLoading: false,
		services: [],
		service: {}
	},
	developerSpaceStreams: {
		isLoading: false,
		total: 0,
		streams: []
	},
	streams: {
		isLoading: false,
		isSaving: false,
		streams: [],
		availableGames: [],
		generalInfo: {
			id: null,
			name: null,
			streamType: 0,
			partnerId: null,
			partnerName: null,
			games: [],
			streamUrl: null,
			created: null,
			lastUpdate: null,
			isTesting: false
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		}
	},
	streamServers: {
		isAllLoading: false,
		data: [],
		availableGames: {}
	},
	postDeploymentActions: {
		languageCodes: [],
		isLoading: false
	},
	games: {
		games: [],
		addTeamsLoading: false,
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			[GAME_CATEGORY.SCHEDULED]: getGameDefaultFilters(),
			[GAME_CATEGORY.INSTANT]: getGameDefaultFilters()
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingGame: {
			generalInfo: {
				partnerName: "",
				sceneCount: 6,
				cycleMinutes: GAME_CYCLE_MINUTES.CYCLE3MIN,
				activeGameCount: 10,
				closeBetSeconds: 5,
				participants: [],
				name: ""
			},
			probabilities: {
				footballSceneProbabilities: {
					goalScenes: [],
					noGoalScenes: []
				},
				penaltyGoalMissProbabilities: {
					goalScenes: [],
					noGoalScenes: []
				},
				footballHandicapProbability: {
					maxDifference: 2.5
				},
				footballTeams: {
					teams: []
				},
				penaltyTeams: {
					teams: [],
					teamFormat: GAME_TEAM_FORMAT.NATIONAL_TEAMS
				},
				strengths: [],
				competitionsAndRaces: {
					raceFormat: 0
				}
			},
			rtp: {
				allowOverride: true,
				minPossibleValue: 0,
				maxPossibleValue: 0,
				rtPs: []
			},
			banners: {},
			streamConfiguration: {}
		},
		allTeams: {
			data: [],
			isLoading: false
		}
	},
	boxes: {
		boxes: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingBox: {
			generalInfo: {
				name: "",
				connectionInstruction: ""
			},
			games: {
				games: [],
				isLoading: false
			},
			rtp: {
				isLoading: false,
				data: {
					allowOverride: true,
					minPossibleValue: 0,
					maxPossibleValue: 0,
					rtPs: []
				}
			}
		}
	},
	betshops: {
		betshops: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			nameOrId: "",
			code: "",
			groupId: "",
			itemState: FILTER_STATUS.ALL,
			testState: FILTER_TEST_STATE.ALL,
			to: "",
			from: "",
			lastUpdateFrom: "",
			lastUpdateTo: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingBetshop: {
			generalInfo: {
				groupId: "",
				name: "",
				address: "",
				tel: "",
				email: "",
				ticketLanguageCode: "",
				liveMonitorLanguageCode: "EN",
				enabled: true
			},
			currency: {
				code: "",
				favoriteBets: [],
				retailFavoriteBets: [],
				betLimits: {
					singleMin: "",
					singleMax: "",
					multiMin: "",
					multiMax: "",
					singlePossibleWinMax: "",
					multiPossibleWinMax: ""
				}
			},
			boxes: {
				isLoading: false,
				boxes: [],
				availableBoxes: [],
				isAvailableBoxesLoading: false
			},
			operatingHours: {
				allowOverride: false,
				workingHours: {
					weekDays: {
						[WEEKDAYS.MONDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.TUESDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.WEDNESDAY]: {
							type: WORKING_HOURS_TYPE.ALWAYS_OPEN
						},
						[WEEKDAYS.THURSDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.FRIDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.SATURDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN },
						[WEEKDAYS.SUNDAY]: { type: WORKING_HOURS_TYPE.ALWAYS_OPEN }
					}
				},
				publicHolidays: {
					days: [],
					disableCashierSoftware: true
				},
				timeZoneId: "",
				dst: 0
			},
			limitSettings: {
				enabled: false,
				fixedAmount: 0,
				frequencySettings: {
					every: 1,
					at: {
						hour: 0,
						minute: 0
					},
					timeZoneId: "",
					dst: 0
				}
			},
			bonusConfigs: {
				enabled: false,
				amount: null,
				type: BETSHOP_BONUS_CONFIG_TYPE.EQUAL
			},
			customization: {
				ui: {}
			}
		},
		viewingBetshop: {
			activity: {
				activities: [],
				isLoading: false,
				total: 0,
				filters: {
					transactionId: "",
					type: FILTER_ACTIVITY_TYPE.ALL,
					userNameOrId: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "ChangeTime",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			sessions: {
				sessions: [],
				isLoading: false,
				total: 0,
				filters: {
					settlementPrinted: FILTER_SETTLEMENT_PRINTED.ALL,
					userNameOrId: "",
					from: moment(yesterday(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					quickFilters: "last_24_h"
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CreateTime",
					orderDirection: ORDER_DIRECTION.DESC
				},
				currentSession: {
					isLoading: false,
					settlementReport: {
						report: {
							footballSingleMatch: {},
							horseRaceFlat: {},
							greyhoundRace: {}
						},
						betSlipCount: 0,
						wonCount: 0,
						playedCount: 0,
						balance: 0,
						canceled: 0,
						ggr: 0,
						netTurnover: 0,
						payout: 0,
						turnover: 0,
						won: 0,
						wonUnpaid: 0,
						bonusTurnover: 0,
						bonusGGR: 0,
						bonusWon: 0,
						bonusPayout: 0,
						bonusWonUnpaid: 0,
						bonusBetSlipsCount: 0,
						bonusWonCount: 0
					}
				}
			}
		},
		wallet: {
			isSaving: false,
			isLoading: false,
			wallet: {
				balance: 0
			}
		}
	},
	cashiers: {
		cashiers: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		isAvailableBetshopsLoading: false,
		availableBetshops: {},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			onlineState: FILTER_USER_ONLINE_STATE.ALL,
			betShopId: "",
			from: "",
			to: "",
			lastUpdateFrom: "",
			lastUpdateTo: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "FirstName",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingCashier: {
			generalInfo: {
				firstName: "",
				lastName: "",
				betShopId: ""
			}
		}
	},
	users: {
		users: [],
		inactiveUsers: [],
		isInactiveUsersLoading: false,
		isLoading: false,
		isSaving: false,
		total: 0,
		isAvailablePartnersLoading: false,
		availablePartners: {},
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			permissionGroup: "",
			from: "",
			to: "",
			lastUpdateFrom: "",
			lastUpdateTo: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingUser: {
			generalInfo: {
				firstName: "",
				lastName: "",
				gender: USER_GENDER.NONE,
				tel: "",
				address: "",
				allowEdit: true,
				experationEnabled: false,
				experationDate: "",
				partnerType: null
			},
			partnerAccess: {
				partners: [],
				mainPartner: {},
				availablePartners: []
			},
			projectAccess: {
				partners: []
			},
			betShopAccess: {
				partners: []
			},
			permissions: [],
			permissionGroups: {
				groups: [],
				availableGroups: {},
				isAvailableGroupsLoading: false
			}
		}
	},
	accessManagers: {
		data: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			userNameOrId: "",
			state: FILTER_USER_STATE.ALL,
			permissionGroup: "",
			from: "",
			to: "",
			lastUpdateFrom: "",
			lastUpdateTo: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingAccessManager: {
			generalInfo: {}
		}
	},
	permissionRequests: {
		isLoading: false,
		isSaving: false,
		pending: {
			requests: []
		},
		history: {
			history: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "ModifiedAt",
				orderDirection: ORDER_DIRECTION.DESC
			},
			filters: {
				objectType: "",
				actionTypes: "",
				status: "",
				from: "",
				to: "",
				lastUpdateFrom: "",
				lastUpdateTo: ""
			}
		},
		count: {}
	},
	riskManagement: {
		bigMultipliers: {
			history: {
				filters: {
					betSlipId: "",
					betId: "",
					from: "",
					to: "",
					playerExternalId: "",
					playerId: "",
					partnerId: "",
					partnerName: "",
					projectId: "",
					projectName: "",
					minBetAmount: "",
					maxBetAmount: "",
					minWinAmount: "",
					maxWinAmount: "",
					minMultiplier: "",
					maxMultiplier: "",
					gameType: "",
					gameCategory: ""
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CalculateTime",
					orderDirection: ORDER_DIRECTION.DESC
				},
				isLoading: false,
				total: 0,
				data: []
			},
			configurations: {
				isLoading: false,
				isSaving: false,
				data: {
					minBetAmount: "",
					minMultiplier: "",
					actionType: RISK_MANAGEMENT_ACTION_TYPES.NONE,
					emails: []
				}
			}
		},
		betAnomalies: {
			history: {
				filters: {
					playerExternalId: "",
					playerId: "",
					partnerId: "",
					partnerName: "",
					projectId: "",
					projectName: "",

					minWinCount: "",
					maxWinCount: "",
					minWinPercent: "",
					maxWinPercent: "",
					minTotalWinAmount: "",
					maxTotalWinAmount: "",

					from: "",
					to: "",
					riskCaseStatus: ""
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "betTime",
					orderDirection: ORDER_DIRECTION.DESC
				},
				isLoading: false,
				isSaving: false,
				total: 0,
				data: []
			},
			configurations: {
				isLoading: false,
				isSaving: false,
				data: {
					minBetCount: "",
					winAnomalyPercent: "",
					timePeriod: RISK_MANAGEMENT_BET_ANOMALIES_TIME_PERIODS.LAST_HOUR,
					actionType: RISK_MANAGEMENT_ACTION_TYPES.NONE,
					emails: []
				}
			}
		},
		playerSuspiciousActivities: {
			history: {
				filters: {
					playerId: "",
					playerExternalId: "",
					from: "",
					to: "",
					partnerId: "",
					partnerName: "",
					projectId: "",
					projectName: "",
					minBetAmount: "",
					maxBetAmount: "",
					minAvgBetAmount: "",
					maxAvgBetAmount: "",
					minDeviation: "",
					maxDeviation: ""
				},
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "CalculateTime",
					orderDirection: ORDER_DIRECTION.DESC
				},
				isLoading: false,
				total: 0,
				data: []
			},
			configurations: {
				isLoading: false,
				isSaving: false,
				total: 0,
				data: []
			}
		},
		topPlayers: {
			list: {
				filters: {
					from: moment(moment().startOf("day"), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					to: moment(new Date(), `${DATE_FORMAT} ${TIME_FORMAT}`).toDate(),
					partnerId: "",
					partnerName: "",
					projectId: "",
					projectName: "",
					limit: 20
				},
				sorting: {
					orderBy: "playerGGR",
					orderDirection: ORDER_DIRECTION.DESC
				},
				isLoading: false,
				total: 0,
				data: []
			},
			playerDetails: {
				id: null,
				total: 0,
				data: [],
				isLoading: false
			}
		}
	},
	surveys: {
		surveys: [],
		quickInfo: {
			isLoading: false,
			quickInfo: {
				id: "",
				name: "",
				startDate: "",
				endDate: "",
				lastUpdatedAt: "",
				lastUpdatedBy: "",
				createdAt: "",
				createdBy: "",
				partnersCount: 0,
				playersCount: 0,
				responseRate: 0,
				responseScore: 0,
				totalResponses: 0,
				state: SURVEY_STATE.NONE
			}
		},
		isLoading: false,
		isSaving: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "CreatedAt",
			orderDirection: ORDER_DIRECTION.DESC
		},
		editingSurvey: {
			isLoading: false,
			isSaving: false,
			generalInfo: {
				id: "",
				name: "",
				projectType: PROJECT_TYPE.ONLINE,
				startDate: "",
				endDate: "",
				state: SURVEY_STATE.NONE,
				suggestedMessage: false,
				isTesting: false,
				enabled: false
			},
			availablePartners: [],
			partners: {
				partners: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10
				}
			},
			partnerFeedbacks: {
				partnerFeedbacks: [],
				total: 0,
				sorting: {
					page: 1,
					limit: 10,
					orderBy: "Date",
					orderDirection: ORDER_DIRECTION.DESC
				}
			}
		}
	},
	profile: {
		isLoading: false,
		isSaving: false,
		profile: {
			partners: []
		},
		isUserInfoLoading: true,
		userInfo: {
			partners: [],
			currency: {},
			currencies: [],
			currencyType: USER_SELECTED_CURRENCY_TYPE.DEFAULT
		},
		passwordSettings: {
			passwordSettings: {},
			isLoading: false
		},
		isPasswordChanging: false
	},
	permissionGroups: {
		isLoading: false,
		isSaving: false,
		myGroups: {
			groups: [],
			total: 0,
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "Created",
				orderDirection: ORDER_DIRECTION.DESC
			}
		},
		editingPermissionGroup: {
			generalInfo: {
				name: "",
				permissions: []
			},
			usersQuickView: {
				total: null,
				users: [],
				sorting: {}
			}
		}
	},
	notifications: {
		isLoading: false,
		notifications: [],
		sound: false
	},
	bonuses: {
		standard: {
			isLoading: false,
			isSaving: false,
			betshops: [],
			games: [],
			printVouchers: [],
			printVouchersTranslations: {},
			data: [],
			total: 0,
			filters: {
				nameOrId: "",
				bonusType: FILTER_BONUS_TYPE.ALL,
				currency: "",
				bonusState: FILTER_BONUS_STATE.ALL
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreationDate",
				orderDirection: ORDER_DIRECTION.DESC
			},
			quickInfo: {
				remainingQuantity: 0,
				isPrinted: false,
				expiredQuantity: 0,
				bonusRedeem: 0,
				bonusWin: 0,
				bonusGGR: 0,
				bonusBetsCount: 0,
				bonusBetsPerBetshop: [],
				id: null,
				bonusType: 0,
				gameType: 0,
				betType: 0,
				betshopIds: [],
				betshopNames: [],
				currency: null,
				winType: 0,
				quantity: 0,
				amount: 0,
				betProbability: 0,
				minOddsSingle: null,
				minOddsMulti: null,
				maxOddsSingle: null,
				maxOddsMulti: null,
				minAmountSingle: null,
				minAmountMulti: null,
				maxAmountSingle: null,
				maxAmountMulti: null,
				maxPossibleWinAmount: null,
				minPossibleWinAmount: null,
				endDate: "",
				startDate: ""
			},
			editingBonus: {
				generalInfo: {}
			}
		},
		jackpots: {
			isLoading: false,
			isSaving: false,
			betshops: [],
			games: [],
			data: [],
			total: 0,
			filters: {
				nameOrId: "",
				specificationType: FILTER_GAME_SPECIFICATION_TYPE.ALL,
				type: FILTER_JACKPOT_BONUS_TYPE.ALL,
				state: FILTER_JACKPOT_BONUS_STATE.ALL,
				from: "",
				to: ""
			},
			sorting: {
				page: 1,
				limit: 10,
				orderBy: "CreateTime",
				orderDirection: ORDER_DIRECTION.DESC
			},
			quickInfo: [],
			editingJackpot: {
				generalInfo: {},
				history: {
					data: [],
					total: 0,
					filters: {
						betSlipId: "",
						projectIds: [],
						status: "",
						from: "",
						to: "",
						wonLevelType: FILTER_JACKPOT_BONUS_LEVEL.ALL
					},
					sorting: {
						page: 1,
						limit: 10,
						orderBy: "levelType",
						orderDirection: ORDER_DIRECTION.DESC
					}
				}
			}
		}
	},
	translations: {
		isLoading: false,
		isGroupsLoading: false,
		isPublishing: false,
		isUnpublishing: false,
		isSystemAllLoading: false,
		isRuleAddLoading: false,
		isPartnerAvailableLanguagesForRuleLoading: false,
		groups: [],
		translations: [],
		liveMonitorTranslations: [],
		rules: {
			[REDUX_RULES_STORE_FIELD[GAME_CATEGORY.SCHEDULED]]: {
				main: getDefaultStateOfMainRules(SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value),
				history: getDefaultStateOfHistoryRules(),
				editingRule: {
					id: null
				}
			},
			[REDUX_RULES_STORE_FIELD[GAME_CATEGORY.INSTANT]]: {
				main: getDefaultStateOfMainRules(INSTANT_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value),
				history: getDefaultStateOfHistoryRules(),
				editingRule: {
					id: null
				}
			}
		},
		systemDefault: localStorageUtils.get("systemDefaultTranslation") === null ? true : localStorageUtils.get("systemDefaultTranslation"),
		partnerAvailableLanguagesForRule: {}
	},
	tickets: {
		isLoading: false,
		isSaving: false,
		ticketLogoId: null,
		terminalTicketLogoId: null,
		betTicket: {},
		terminalBetTicket: {},
		cancelTicket: {},
		payoutTicket: {},
		currentBetTicket: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			marketSelection: flagsToBinary(Object.values(TICKET_MARKET_DETAILS)),
			customText: "",
			enableBarcodePrinting: true,
			enableQRPrinting: true
		},
		currentCancelTicket: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			marketSelection: flagsToBinary(Object.values(TICKET_MARKET_DETAILS)),
			customText: "",
			enableBarcodePrinting: false
		},
		currentPayoutTicket: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			marketSelection: flagsToBinary(Object.values(TICKET_MARKET_DETAILS)),
			customText: "",
			enableBarcodePrinting: false
		},
		currentTerminalBetTicket: {
			generalDetails: flagsToBinary(Object.values(TICKET_GENERAL_DETAILS)),
			marketSelection: flagsToBinary(Object.values(TICKET_MARKET_DETAILS)),
			customText: "",
			enableBarcodePrinting: true,
			enableQRPrinting: true
		}
	},
	hotkeys: {
		isLoading: false,
		hotkeys: []
	},
	systemCurrencies: {
		systemCurrencies: [],
		availableCurrencies: {},
		systemAvailableCurrencies: [],
		isLoading: false,
		isSaving: false,
		isAvailableLoading: false,
		total: 0,
		filters: {
			code: null
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		}
	},
	systemLanguages: {
		systemLanguages: [],
		availableLanguages: {},
		systemAvailableLanguages: {},
		isLoading: false,
		isSaving: false,
		isAvailableLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		}
	},
	operators: {
		operators: [],
		isLoading: false,
		total: 0,
		sorting: {
			page: 1,
			limit: 20,
			orderBy: "LastUpdatedDate",
			orderDirection: ORDER_DIRECTION.DESC
		}
	},
	dashboard: {
		isPendingBetslipscountLoading: false,
		online: {
			isPlayerActivitiesLoading: false,
			isOnlinePlayersCountLoading: false,
			playerActivities: [],
			playersTotal: 0,
			onlinePlayersCount: 0,
			betslipActivities: [],
			betslipsTotal: 0,
			pendingBetslipsCount: 0,
			gamesPerformance: {
				source: {},
				calculated: {
					scheduledGamesPerformanceChart: [],
					instantGamesPerformanceChart: []
				},
				totalTurnover: null,
				totalWinAmount: null,
				totalGGRAmount: null,
				totalGGRPercent: null
			},
			totalBonusBetsCount: null,
			filters: {
				period: DASHBOARD_PERIOD.TODAY,
				projectId: "",
				gameType: "",
				gameCategory: ""
			},
			topMarkets: [],
			topPlayers: {
				total: 0,
				data: [],
				isLoading: false,
				sorting: {
					page: 1,
					limit: 20,
					orderBy: "LastUpdatedDate",
					orderDirection: ORDER_DIRECTION.DESC
				}
			},
			partnersRevenue: {
				isLoading: false,
				data: [],
				filters: {
					period: DASHBOARD_PERIOD.TODAY
				}
			},
			isApiKeyNamesLoading: false,
			apiKeyNames: []
		},
		retail: {
			betslipActivities: [],
			betslipsTotal: 0,
			pendingBetslipsCount: 0,
			topMarkets: [],
			gamesPerformance: {
				source: {},
				calculated: {
					scheduledGamesPerformanceChart: [],
					instantGamesPerformanceChart: []
				},
				totalTurnover: null,
				totalWinAmount: null,
				totalGGRAmount: null,
				totalGGRPercent: null
			},
			totalBonusBetsCount: null,
			filters: {
				period: DASHBOARD_PERIOD.TODAY,
				projectId: "",
				gameType: "",
				gameCategory: GAME_CATEGORY.SCHEDULED
			},
			partnersRevenue: {
				isLoading: false,
				data: [],
				filters: {
					period: DASHBOARD_PERIOD.TODAY
				}
			},
			isBetshopNamesLoading: false,
			betshopNames: []
		},
		isBetslipActivitiesLoading: false,
		isTopMarketsLoading: false,
		isGamesPerformanceLoading: false
	},
	maintenanceMode: {
		isLoading: false,
		maintenanceMode: {}
	},
	autoSuggestion: {
		apiKeyNames: [],
		gameNames: [],
		scheduledGameNames: [],
		instantGameNames: [],
		partnersNames: [],
		betshopNames: [],
		terminalNames: [],
		userNames: [],
		accessManagerNames: [],
		cashierNames: [],
		permissionGroupNames: [],
		controllers: [],
		bonusNames: [],
		jackpotNames: [],
		currencyNames: [],
		systemMarkets: {},
		boxes: [],
	},
	system: {
		isTimezonesLoading: false,
		timezones: []
	},
	betCorrections: {
		data: [],
		isLoading: false,
		isSaving: false,
		total: 0,
		filters: {
			partnerId: undefined,
			projectId: undefined,
			betSlipId: "",
			betId: "",
			eventId: "",
			playerId: undefined,
			playerExternalId: ""
		},
		sorting: {
			page: 1,
			limit: 10,
			orderBy: "Created",
			orderDirection: ORDER_DIRECTION.DESC
		}
	}
};
