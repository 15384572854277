import { connect } from "react-redux";
import SettledBetslipsFilters from "../../common/settled/filters.component";
import { PROJECT_TYPE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

const Filters = ({ filters, gameCategory, showBetsBySeparate = false }) => {
	return (
		<SettledBetslipsFilters
			projectNamesAutosuggestionType={AUTOSUGGESTION_TYPE.TERMINALS}
			filters={filters}
			projectType={PROJECT_TYPE.SSBT}
			gameCategory={gameCategory}
			showBetsBySeparate={showBetsBySeparate}
		/>
	);
};

const mapDispatchToProps = null;

const mapStateToProps = (state) => {
	return {
		filters: state.betslips.settled.ssbt.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
