import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_BETSHOP_BOXES, ADD_BETSHOP_BOX, UPDATE_BETSHOP_BOX, DELETE_BETSHOP_BOX, BETSHOP_BOXES_BEFORE, BETSHOP_BOXES_FINISH, SET_BETSHOP_AVAILABLE_BOXES, BETSHOP_AVAILABLE_BOXES_BEFORE, BETSHOP_AVAILABLE_BOXES_FINISH } from "store/actionTypes";

import { setBetshopSaveActionBefore, setBetshopSaveActionFinished } from "./betshops.action";

const setBetshopBoxes = (boxes) => ({
	type: SET_BETSHOP_BOXES,
	payload: { boxes }
});

const setAddBetshopBox = (box) => ({
	type: ADD_BETSHOP_BOX,
	payload: { box }
});

const setUpdateBetshopBox = (box) => ({
	type: UPDATE_BETSHOP_BOX,
	payload: { box }
});

const setDeleteBetshopBox = (id) => ({
	type: DELETE_BETSHOP_BOX,
	payload: { id }
});

const setBetshopBoxesBefore = () => ({
	type: BETSHOP_BOXES_BEFORE
});

const setBetshopBoxesFinished = () => ({
	type: BETSHOP_BOXES_FINISH
});

const setBetshopAvailableBoxes = (boxes) => ({
	type: SET_BETSHOP_AVAILABLE_BOXES,
	payload: { boxes }
});

const setBetshopAvailableBoxesBefore = () => ({
	type: BETSHOP_AVAILABLE_BOXES_BEFORE
});

const setBetshopAvailableBoxesFinished = () => ({
	type: BETSHOP_AVAILABLE_BOXES_FINISH
});

export const getBetshopBoxes = (id) => {
	return (dispatch) => {
		dispatch(setBetshopBoxesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOP_BOXES}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: boxes } }) => {
				dispatch(setBetshopBoxes(boxes));
				dispatch(setBetshopBoxesFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopBoxesFinished());
			});
	};
};

export const addBetshopBox = (id, boxId) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_BETSHOP_BOX}`,
			method: Methods.POST,
			data: { id, boxId }
		})
			.then(({ data: { value: box } }) => {
				dispatch(setAddBetshopBox(box));
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};

export const updateBetshopBox = (box) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_BETSHOP_BOX}`,
			method: Methods.POST,
			data: box
		})
			.then(({ data: { value: box } }) => {
				dispatch(setUpdateBetshopBox(box));
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};

export const deleteBetshopBox = (id, boxId) => {
	return (dispatch) => {
		dispatch(setBetshopSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_BETSHOP_BOX}`,
			method: Methods.DELETE,
			data: { id, boxId }
		})
			.then(() => {
				dispatch(setDeleteBetshopBox(boxId));
				dispatch(setBetshopSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopSaveActionFinished());
			});
	};
};

export const getAvailableBoxes = () => {
	return (dispatch) => {
		dispatch(setBetshopAvailableBoxesBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BETSHOP_AVAILABLE_BOXES}`,
			method: Methods.GET
		})
			.then(({ data: { value: boxes } }) => {
				dispatch(setBetshopAvailableBoxes(boxes));
				dispatch(setBetshopAvailableBoxesFinished());
			})
			.catch((ex) => {
				dispatch(setBetshopAvailableBoxesFinished());
			});
	};
};
