import { getBoxes } from "store/actions/dashboard/autoSuggestion/autoSuggestion.action.js";

/**
 * Enum for autoSuggestion types.
 * @readonly
 * @enum {number}
 */
export const AUTOSUGGESTION_TYPE = {
	API_KEY_NAMES: 1,
	PARTNER_NAMES: 2,
	GAME_NAMES: 3,
	BETSHOP_NAMES: 4,
	USER_NAMES: 5,
	ACCESS_MANAGER_NAMES: 6,
	CASHIER_NAMES: 7,
	PERMISSION_GROUP_NAMES: 8,
	CONTROLLERS: 9,
	BONUS_NAMES: 10,
	SYSTEM_MARKETS: 11,
	CURRENCY_NAMES: 12,
	JACKPOT_NAMES: 13,
	TERMINALS: 14,
	SCHEDULED_GAME_NAMES: 15,
	INSTANT_GAME_NAMES: 16,
	BOXES: 17,
};

/**
 * Mapping of Auto-Suggestion Types to Action and State Names.
 *
 * This object maps different Auto-Suggestion Types to their associated
 * action names and state names for use in the application.
 *
 * @type {object.<AUTOSUGGESTION_TYPE, { actionName: string, stateName: string }>}
 */
export const AUTOSUGGESTION_MAPPING = {
	[AUTOSUGGESTION_TYPE.API_KEY_NAMES]: { actionName: "getApikeyNames", stateName: "apiKeyNames" },
	[AUTOSUGGESTION_TYPE.PARTNER_NAMES]: { actionName: "getPartnersNames", stateName: "partnersNames" },
	[AUTOSUGGESTION_TYPE.GAME_NAMES]: { actionName: "getGameNames", stateName: "gameNames" },
	[AUTOSUGGESTION_TYPE.SCHEDULED_GAME_NAMES]: { actionName: "getScheduledGameNames", stateName: "scheduledGameNames" },
	[AUTOSUGGESTION_TYPE.INSTANT_GAME_NAMES]: { actionName: "getInstantGameNames", stateName: "instantGameNames" },
	[AUTOSUGGESTION_TYPE.BETSHOP_NAMES]: { actionName: "getBetshopNames", stateName: "betshopNames" },
	[AUTOSUGGESTION_TYPE.TERMINALS]: { actionName: "getTerminalNames", stateName: "terminalNames" },
	[AUTOSUGGESTION_TYPE.USER_NAMES]: { actionName: "getUserNames", stateName: "userNames" },
	[AUTOSUGGESTION_TYPE.ACCESS_MANAGER_NAMES]: { actionName: "getAccessManagerNames", stateName: "accessManagerNames" },
	[AUTOSUGGESTION_TYPE.CASHIER_NAMES]: { actionName: "getCashierNames", stateName: "cashierNames" },
	[AUTOSUGGESTION_TYPE.PERMISSION_GROUP_NAMES]: { actionName: "getPermissionGroupNames", stateName: "permissionGroupNames" },
	[AUTOSUGGESTION_TYPE.CONTROLLERS]: { actionName: "getControllers", stateName: "controllers" },
	[AUTOSUGGESTION_TYPE.BONUS_NAMES]: { actionName: "getBonusNames", stateName: "bonusNames" },
	[AUTOSUGGESTION_TYPE.JACKPOT_NAMES]: { actionName: "getJackpotNames", stateName: "jackpotNames" },
	[AUTOSUGGESTION_TYPE.SYSTEM_MARKETS]: { actionName: "getSystemMarkets", stateName: "systemMarkets" },
	[AUTOSUGGESTION_TYPE.CURRENCY_NAMES]: { actionName: "getCurrencyNames", stateName: "currencyNames" },
	[AUTOSUGGESTION_TYPE.BOXES]: { actionName: "getBoxes", stateName: "boxes" },
};
