import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import RetailConfigsComponent from "./retail-configs";
import { updateLocationHash, getHashValue } from "utils/common";
import OnlineRTPComponent from "components/dashboard/partners/edit/sections/online/online-rtp.jsx";
import RetailRTPComponent from "components/dashboard/partners/edit/sections/retail/retail-rtp.jsx";
import { hasPermission } from "utils/permissions.js";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";

/** Retail Tab Component */
const RetailSettingsComponent = ({ onTabChange }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf RetailSettingsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	useEffect(() => {
		onTabChange(unsavedTabs.length > 0);
	}, [unsavedTabs]);

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf RetailSettingsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Tabs
			animated={false}
			className="sub-tabs"
			defaultActiveKey={getHashValue("subtab")}
			onChange={(key) => updateLocationHash("subtab=" + key, true)}
			items={[
				{
					key: "1",
					label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.retail_settings.tabs.retail_configs")}</span>,
					children: <RetailConfigsComponent onTabChange={(status) => changeTabSavedStatus(status, "1")} />
				},
				hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_RETAIL_RTP, action: PERMISSION_ACTION.VIEW })
					? {
						key: "2",
						label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.retail_settings.tabs.rtp_configs")}</span>,
						children: <RetailRTPComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
					}
					: null
			]}
		/>
	);
};

/** RetailSettingsComponent propTypes
 * PropTypes
 */
RetailSettingsComponent.propTypes = {
	/** Fires when for any form in tab, saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

export default RetailSettingsComponent;
