import { useState, useEffect, useRef } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

import useCurrentPath from "hooks/useCurrentPath";

import { Layout } from "antd";

const { Header: AntHeader } = Layout;

import GlobalPartnersFilter from "../globalPartnersFilter";
import PartnerCurrencyDropdown from "../partnerCurrencyDropdown";
import ProfileDropdown from "../profileDropdown";
import Notifications from "../../dashboard/common/notifications";

import { setCurrencyType } from "store/actions/dashboard/profile/userInfo.action";

import userInfoType from "types/profile/userInfo.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import Paths from "constants/path.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { REDUX_RULES_STORE_FIELD } from "constants/rules.constants";
import { USER_SELECTED_CURRENCY_TYPE } from "constants/user.constants";

let interval;

const PARTNER_ONLINE_AVAILABLE_PATHS = {
	[Paths.DASHBOARD_ONLINE_APIKEYS]: Paths.DASHBOARD_ONLINE_APIKEYS,
	[Paths.DASHBOARD_ONLINE_APIKEYS_EDIT]: Paths.DASHBOARD_ONLINE_APIKEYS_EDIT,
	[Paths.DASHBOARD_BETSLIPS_ONLINE]: Paths.DASHBOARD_BETSLIPS_ONLINE,
	[Paths.DASHBOARD_BETSLIPS_RETAIL]: Paths.DASHBOARD_BETSLIPS_RETAIL,
	[Paths.DASHBOARD_EVENTS_ONLINE]: Paths.DASHBOARD_EVENTS_ONLINE,
	[Paths.DASHBOARD_EVENTS_RETAIL]: Paths.DASHBOARD_EVENTS_RETAIL,
	[Paths.DASHBOARD_EVENTS_SSBT]: Paths.DASHBOARD_EVENTS_SSBT,
	[Paths.DASHBOARD_ONLINE_PLAYERS]: Paths.DASHBOARD_ONLINE_PLAYERS,
	[Paths.DASHBOARD_ONLINE_PLAYERS_VIEW]: Paths.DASHBOARD_ONLINE_PLAYERS_VIEW,
	[Paths.DASHBOARD_SCHEDULED_GAMES]: Paths.DASHBOARD_SCHEDULED_GAMES,
	[Paths.DASHBOARD_SCHEDULED_GAMES_EDIT]: Paths.DASHBOARD_SCHEDULED_GAMES_EDIT,
	[Paths.DASHBOARD_INSTANT_GAMES]: Paths.DASHBOARD_INSTANT_GAMES,
	[Paths.DASHBOARD_INSTANT_GAMES_EDIT]: Paths.DASHBOARD_INSTANT_GAMES_EDIT,
	[Paths.DASHBOARD_USERMANAGEMENT_USERS]: Paths.DASHBOARD_USERMANAGEMENT_USERS,
	[Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT,
	[Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS]: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS,
	[Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT,
	[Paths.DASHBOARD_CMS_TRANSLATIONS]: Paths.DASHBOARD_CMS_TRANSLATIONS,
	[Paths.DASHBOARD_TRANSACTIONS]: Paths.DASHBOARD_TRANSACTIONS,
	[Paths.DASHBOARD_USER_LOGS]: Paths.DASHBOARD_USER_LOGS,
	[Paths.DASHBOARD_DEVELOPER_ERRORS]: Paths.DASHBOARD_DEVELOPER_ERRORS,
	[Paths.DASHBOARD_DEVELOPER_REQUESTS]: Paths.DASHBOARD_DEVELOPER_REQUESTS,
	[Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE]: Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE,
	[Paths.DASHBOARD_STATISTICS]: Paths.DASHBOARD_STATISTICS,
	[Paths.DASHBOARD_STREAMS]: Paths.DASHBOARD_STREAMS,
	[Paths.DASHBOARD_STREAMS_EDIT]: Paths.DASHBOARD_STREAMS_EDIT,
	[Paths.DASHBOARD_SSBT_EDIT]: Paths.DASHBOARD_SSBT_EDIT,
	[Paths.DASHBOARD_SSBT]: Paths.DASHBOARD_SSBT
};
const PARTNER_RETAIL_AVAILABLE_PATHS = {
	[Paths.DASHBOARD_BOXES]: Paths.DASHBOARD_BOXES,
	[Paths.DASHBOARD_BOXES_EDIT]: Paths.DASHBOARD_BOXES_EDIT,
	[Paths.DASHBOARD_SCHEDULED_GAMES]: Paths.DASHBOARD_SCHEDULED_GAMES,
	[Paths.DASHBOARD_SCHEDULED_GAMES_EDIT]: Paths.DASHBOARD_SCHEDULED_GAMES_EDIT,
	[Paths.DASHBOARD_INSTANT_GAMES]: Paths.DASHBOARD_INSTANT_GAMES,
	[Paths.DASHBOARD_INSTANT_GAMES_EDIT]: Paths.DASHBOARD_INSTANT_GAMES_EDIT,
	[Paths.DASHBOARD_BETSLIPS_ONLINE]: Paths.DASHBOARD_BETSLIPS_ONLINE,
	[Paths.DASHBOARD_BETSLIPS_RETAIL]: Paths.DASHBOARD_BETSLIPS_RETAIL,
	[Paths.DASHBOARD_RETAIL_BETSHOPS]: Paths.DASHBOARD_RETAIL_BETSHOPS,
	[Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT]: Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT,
	[Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW]: Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW,
	[Paths.DASHBOARD_USERMANAGEMENT_USERS]: Paths.DASHBOARD_USERMANAGEMENT_USERS,
	[Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT,
	[Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS]: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS,
	[Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT,
	[Paths.DASHBOARD_TRANSACTIONS]: Paths.DASHBOARD_TRANSACTIONS,
	[Paths.DASHBOARD_USER_LOGS]: Paths.DASHBOARD_USER_LOGS,
	[Paths.DASHBOARD_CMS_TICKETS]: Paths.DASHBOARD_CMS_TICKETS,
	[Paths.DASHBOARD_CMS_TRANSLATIONS]: Paths.DASHBOARD_CMS_TRANSLATIONS,
	[Paths.DASHBOARD_DEVELOPER_ERRORS]: Paths.DASHBOARD_DEVELOPER_ERRORS,
	[Paths.DASHBOARD_DEVELOPER_REQUESTS]: Paths.DASHBOARD_DEVELOPER_REQUESTS,
	[Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS]: Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS,
	[Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT]: Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT,
	[Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE]: Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE,
	[Paths.DASHBOARD_BONUSES_STANDARD]: Paths.DASHBOARD_BONUSES_STANDARD,
	[Paths.DASHBOARD_BONUSES_STANDARD_EDIT]: Paths.DASHBOARD_BONUSES_STANDARD_EDIT,
	[Paths.DASHBOARD_BONUSES_JACKPOT]: Paths.DASHBOARD_BONUSES_JACKPOT,
	[Paths.DASHBOARD_BONUSES_JACKPOT_EDIT]: Paths.DASHBOARD_BONUSES_JACKPOT_EDIT,
	[Paths.DASHBOARD_STATISTICS]: Paths.DASHBOARD_STATISTICS,
	[Paths.DASHBOARD_STREAMS]: Paths.DASHBOARD_STREAMS,
	[Paths.DASHBOARD_STREAMS_EDIT]: Paths.DASHBOARD_STREAMS_EDIT,
	[Paths.DASHBOARD_SSBT_EDIT]: Paths.DASHBOARD_SSBT_EDIT,
	[Paths.DASHBOARD_SSBT]: Paths.DASHBOARD_SSBT
};
const PARTNER_SSBT_AVAILABLE_PATHS = {
	[Paths.DASHBOARD_BETSLIPS_SSBT]: Paths.DASHBOARD_BETSLIPS_SSBT
};

const PARTNER_DISABLED_PATHS = {
	[Paths.DASHBOARD_ONLINE_APIKEYS_EDIT]: Paths.DASHBOARD_ONLINE_APIKEYS_EDIT,
	[Paths.DASHBOARD_BOXES_EDIT]: Paths.DASHBOARD_BOXES_EDIT,
	[Paths.DASHBOARD_SCHEDULED_GAMES_EDIT]: Paths.DASHBOARD_SCHEDULED_GAMES_EDIT,
	[Paths.DASHBOARD_SCHEDULED_GAMES_EDIT]: Paths.DASHBOARD_SCHEDULED_GAMES_EDIT,
	[Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT]: Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT,
	[Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW]: Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW,
	[Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT,
	[Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT]: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT,
	[Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT]: Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT,
	[Paths.DASHBOARD_ONLINE_PLAYERS_VIEW]: Paths.DASHBOARD_ONLINE_PLAYERS_VIEW,
	[Paths.DASHBOARD_BONUSES_STANDARD_EDIT]: Paths.DASHBOARD_BONUSES_STANDARD_EDIT,
	[Paths.DASHBOARD_BONUSES_JACKPOT_EDIT]: Paths.DASHBOARD_BONUSES_JACKPOT_EDIT,
	[Paths.DASHBOARD_STREAMS_EDIT]: Paths.DASHBOARD_STREAMS_EDIT
};

const selectSystemDefaultTranslation = (state) => state.translations.systemDefault;
const selectSystemDefaultRules = (gameCategory) => (state) => state.translations.rules[REDUX_RULES_STORE_FIELD[gameCategory]].main.systemDefault;

/** Dashboard Header Component */
const Header = ({ userInfo }) => {
	const dateTimeRef = useRef(null);
	const [searchParams] = useSearchParams();
	const path = useCurrentPath();
	const { t } = useTranslation();
	const systemDefaultTranslation = useSelector(selectSystemDefaultTranslation);
	const systemDefaultRules = useSelector(selectSystemDefaultRules(GAME_CATEGORY.SCHEDULED) || selectSystemDefaultRules(GAME_CATEGORY.INSTANT));

	const dispatch = useDispatch();

	const isOnlinePath = PARTNER_ONLINE_AVAILABLE_PATHS[path];
	const isRetailPath = PARTNER_RETAIL_AVAILABLE_PATHS[path];
	const isSSBTPath = PARTNER_SSBT_AVAILABLE_PATHS[path];
	const isRulesPath = path.includes(Paths.DASHBOARD_CMS_RULES);
	const isPartnerDropDownVisible = isOnlinePath || isRetailPath || isRulesPath || isSSBTPath;
	const isPartnerDropdownDisabled =
		PARTNER_DISABLED_PATHS[path] ||
		(systemDefaultTranslation && path === Paths.DASHBOARD_CMS_TRANSLATIONS) ||
		(searchParams.get("eventId") && [Paths.DASHBOARD_EVENTS_ONLINE, Paths.DASHBOARD_EVENTS_RETAIL].includes(path)) ||
		(isRulesPath && systemDefaultRules);

	useEffect(() => {
		if (!isPartnerDropDownVisible) {
			dispatch(setCurrencyType(USER_SELECTED_CURRENCY_TYPE.DEFAULT));
		}
	}, [isPartnerDropDownVisible]);

	/** Run the clock */
	useEffect(() => {
		dateTimeRef.current.textContent  = moment.utc(new Date()).local().format(`${DATE_FORMAT} ${TIME_FORMAT}`)

		interval = setInterval(() => {
			dateTimeRef.current.textContent  = moment.utc(new Date()).local().format(`${DATE_FORMAT} ${TIME_FORMAT}`)
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<AntHeader className="header">
			<div className="dashboard-header">
				<div className="dashboard-header-left">
					<span className="dashboard-header-date" ref={dateTimeRef}>-</span>
				</div>

				<div className="dashboard-header-right">
					{userInfo.maintenanceMode && (
						<div className="dashboard-header-maintenance">
							<i className="icon-warning" />
							<span>{t("pages.dashboard.maintenance_mode.virtuals_are_on_maintenance_now")}</span>
						</div>
					)}

					<GlobalPartnersFilter
						isOnlinePath={isOnlinePath}
						isRetailPath={isOnlinePath}
						isVisible={isPartnerDropDownVisible}
						isDisabled={isPartnerDropdownDisabled}
					/>
					<PartnerCurrencyDropdown isPartnerDropDownVisible={isPartnerDropDownVisible} />
					<Notifications />
					<ProfileDropdown />
				</div>
			</div>
		</AntHeader>
	);
};

/** Header propTypes
 * PropTypes
 */
Header.propTypes = {
	/** Redux state property, the user info */
	userInfo: userInfoType
};

const mapStateToProps = (state) => {
	return {
		userInfo: state.profile.userInfo
	};
};

export default connect(mapStateToProps, null)(Header);
