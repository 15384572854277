import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setBoxSaveActionBefore, setBoxSaveActionFinished } from "./boxes.action";

import { SET_BOX_GAME_RTP, BOX_GAMES_RTP_BEFORE, BOX_GAMES_RTP_FINISH } from "store/actionTypes";

const setBoxGameRTP = (rtp) => ({
	type: SET_BOX_GAME_RTP,
	payload: { rtp }
});

const setBoxGamesRTPBefore = () => ({
	type: BOX_GAMES_RTP_BEFORE
});

const setBoxGamesRTPFinished = () => ({
	type: BOX_GAMES_RTP_FINISH
});

export const getBoxGameRTPs = (params) => {
	return (dispatch) => {
		dispatch(setBoxGamesRTPBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_GET_RTP}`,
			method: Methods.GET,
			params: { ...params }
		})
			.then(({ data: { value: rtp } }) => {
				dispatch(setBoxGameRTP(rtp));
				dispatch(setBoxGamesRTPFinished());
			})
			.catch((ex) => {
				dispatch(setBoxGamesRTPFinished());
			});
	};
};

export const saveBoxGameRTPs = ({ data, onSuccess }) => {
	return (dispatch) => {
		dispatch(setBoxSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BOX_SAVE_RTP}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { message: msg, status, value: rtp } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					onSuccess && onSuccess();
					message.success(msg);
					dispatch(setBoxGameRTP(rtp));
				}
				dispatch(setBoxSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setBoxSaveActionFinished());
			});
	};
};

export const resetBoxGameRTPs = () => {
	return (dispatch) => {
		dispatch(setBoxGameRTP({ rtPs: [], allowOverride: true, minPossibleValue: 0, maxPossibleValue: 0 }));
	};
};
