import { SCHEDULED_GAME_TYPE } from "constants/game.constants";

export const TRANSLATION_SOURCE_TYPE = {
	SELF: 0,
	OVERRIDEN: 1,
	SYSTEM_TRANSLATION: 2,
	PARTNER_DEFAULT: 3,
	SYSTEM_DEFAULT: 4
};

export const LIVE_MONITOR_TRANSLATION_GROUP_ID = "89ebd9ef6ec146128737294e5582569a";

export const LIVE_MONITOR_TRANSLATION_SUB_GROUPS_IDS = {
	0: "785b71a4d16d4455b74162fdd480aab7",
	[SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value]: "e6737615cd514b088700d758fb9b0cb2",
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "d491e6acbaa4439aa304c8f037f4fc1d",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "eacd221601b9456ba80b60ffefc4263d",
	[SCHEDULED_GAME_TYPE.ENGLISH_LEAGUE.value]: "019ed5d90aa94051a88c788ae0eb242a",
	[SCHEDULED_GAME_TYPE.AFRICAN_CUP.value]: "7999928b83574144bd290678e63a9495",
	[SCHEDULED_GAME_TYPE.WORLD_CUP.value]: "f5f0c582fa2b4ac48354d1512bff69fd",
	[SCHEDULED_GAME_TYPE.EUROPEAN_CUP.value]: "2162d4341f17413e94a48d7bd8dec93b",
	[SCHEDULED_GAME_TYPE.CHAMPIONS_LEAGUE.value]: "dc87449e7fe1459097734c2168577356",
	[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]: "17265eafb69a4008aff51d065216d699",
	[SCHEDULED_GAME_TYPE.COPA_LIBERTADORES.value]: "bd660749352f41fcb7324d85c1c8a6a8",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "7ed1cf30d0f5458897874437a2d3cce5"
}

export const MARKETS_TRANSLATIONS_GROUP_NAMES_TEXT = {
	ScheduledMarkets: "Scheduled Games Markets",
	InstantMarkets: "Instant Games Markets"
}