import { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Row, Col } from "antd";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import QuickInfo from "components/common/quickInfo";

import { getPlayerQuickInfo } from "store/actions/dashboard/online/players/quickInfo.action";

import { FILTER_SHOW_IN_CURRENCY } from "constants/filter.constants";
import { SCHEDULED_GAME_TYPE_ICON_MAPPER } from "constants/game.constants";

import { numberWithSpaces } from "utils/common";
import { convertCurrencies, getPartnerCurrency, getPartnerCurrencyDecimalCount } from "utils/currency";
import { makeGroupTitle } from "utils/markets";

import playerQuickInfoType from "types/player/quickInfo.type";
import currencyType from "types/common/currency.type";

/** Players Quick Info Component */

const PlayerQuickInfo = ({ id, onClose, quickInfo, getPlayerQuickInfo, loading, filters, globalPartnerId, currency, currencies }) => {
	const { t } = useTranslation();

	const totals = quickInfo?.totals ?? {};
	const personalInfo = quickInfo?.personalInfo ?? {};
	const currencyCode = personalInfo.currencyCode ?? "";

	/** Function to get current currency depend on 'show in' filter
	 * @function
	 * @returns {string} - currency code
	 * @memberOf PlayerQuickInfo
	 */
	const getCurrentCurrency = () => getPartnerCurrency(filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL ? currencyCode : currency.code, currencies, globalPartnerId);

	/** Function to convert value to current currency depend on 'show in' filter
	 * @function
	 * @param {number} value - initial value
	 * @returns {number} - converted value
	 * @memberOf PlayerQuickInfo
	 */
	const getConvertedValue = (value) =>
		filters.showIn === FILTER_SHOW_IN_CURRENCY.ORIGINAL
			? numberWithSpaces(value.toFixed(getPartnerCurrencyDecimalCount(currencyCode, currencies, globalPartnerId)))
			: numberWithSpaces(convertCurrencies(value, currencyCode, currency.code, currencies, globalPartnerId));

	return (
		<QuickInfo loadFn={() => getPlayerQuickInfo(id)} title={t("pages.dashboard.players.player_quick_info")} visible={id !== null} onClose={onClose} loading={loading}>
			<Fragment>
				<div className="dashboard-quick-info-section">
					<div className="dashboard-quick-info-section-header">
						<span>{t("pages.dashboard.players.personal_info")}</span>
					</div>
					<div className="dashboard-quick-info-section-content">
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.id")}</span>
									<b title={personalInfo.id}>{personalInfo.id}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.external_id")}</span>
									<b title={personalInfo.externalId}>{personalInfo.externalId}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.userName")}</span>
									<b>{personalInfo.userName}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.partner")}</span>
									<b>{personalInfo.partnerName}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.project")}</span>
									<b>{personalInfo.projectName}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.currency")}</span>
									<b>{currencyCode}</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.created_at")}</span>
									<b>
										{personalInfo.created
											? moment
													.utc(personalInfo.created)
													.local()
													.format(DATE_TIME_FORMAT)
											: ""}
									</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.last_active_at")}</span>
									<b>
										{personalInfo.lastActiveAt
											? moment
													.utc(personalInfo.lastActiveAt)
													.local()
													.format(DATE_TIME_FORMAT)
											: ""}
									</b>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="dashboard-quick-info-section-content-info">
									<span>{t("pages.dashboard.players.status")}</span>
									<b>{personalInfo.state ? t("pages.dashboard.users.status_" + personalInfo.state) : ""}</b>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="dashboard-quick-info-section">
					<div className="dashboard-quick-info-section-header">
						<span>{t("pages.dashboard.players.total_value")}</span>
					</div>
					<div className="dashboard-quick-info-section-content">
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4 className="dashboard-quick-info-section-content-title">{t("pages.dashboard.players.financial")}</h4>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="ggr">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ggr")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.ggr ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="ggrPrecent">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ggr")} %</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{totals.ggrPercent ?? 0} %</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="ngr">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.ngr")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.ngr ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="win">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.win_amount")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.won ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="canceled">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.canceled_amount")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.canceled ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="turnover">
									<div className="player-info-card-top">
										<i className="icon-turnover" />
										<span>{t("pages.dashboard.players.turnover")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.turnover ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="balance">
									<div className="player-info-card-top">
										<i className="icon-currency" />
										<span>{t("pages.dashboard.players.current_balance")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{`${getConvertedValue(totals.currentBalance ?? 0)} ${getCurrentCurrency()}`}</b>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="player-info-card" data-type="betslips">
									<div className="player-info-card-top">
										<i className="icon-betslips" />
										<span>{t("pages.dashboard.players.bets")}</span>
									</div>
									<div className="player-info-card-bottom">
										<b>{totals.betSlipsTotal ?? 0}</b>
									</div>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4 className="dashboard-quick-info-section-content-title">{t("pages.dashboard.players.top_markets")}</h4>
							</Col>
							{(quickInfo?.topMarkets?.markets ?? []).map((market, index) => (
								<Col xs={24} sm={12} xl={6} key={index}>
									<div className="player-info-card" data-type="market">
										<div className="player-info-card-top">
											<i className={SCHEDULED_GAME_TYPE_ICON_MAPPER[market.gameType] || ""} />
											<span>{makeGroupTitle(0, market.market, {}, market.argument, null, market.gameType, market.gameCategory)}</span>
										</div>
										<div className="player-info-card-bottom">
											<b>{market.betCount}</b>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</div>
				</div>
			</Fragment>
		</QuickInfo>
	);
};

/** PlayerQuickInfo propTypes
 * PropTypes
 */
PlayerQuickInfo.propTypes = {
	/** The id of the player which quick info is showing */
	id: PropTypes.string,
	/** Function to call on qucik info close */
	onClose: PropTypes.func,
	/** Redux action to get player quick info */
	getPlayerQuickInfo: PropTypes.func,
	/** Redux state property, is true when loading quick info */
	loading: PropTypes.bool,
	/** Redux state property, is true when loading quick info */
	quickInfo: playerQuickInfoType,
	/** Redux state property, players filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, the current user currency */
	currency: currencyType,
	/** Redux state property, the current user currencies */
	currencies: PropTypes.arrayOf(currencyType)
};

const mapStateToProps = (state) => {
	return {
		loading: state.players.quickInfo.isLoading,
		quickInfo: state.players.quickInfo.quickInfo,
		filters: state.players.filters,
		globalPartnerId: state.partner.globalPartnerId,
		currencies: state.profile.userInfo.currencies,
		currency: state.profile.userInfo.currency
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlayerQuickInfo: (id) => {
		dispatch(getPlayerQuickInfo(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerQuickInfo);
