import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import RtpComponent from "./rtpComponent";
import { POPUP_SIZE } from "constants/common.constants";
import { INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE, GAME_CATEGORY } from "constants/game.constants";


const EditGameModal = ({ isModalVisible, setIsModalVisible, gameId, gameType, gameCategory }) => {
	const { t } = useTranslation();

	const onClose = () => {
		setIsModalVisible({ id: null, type: null });
	};

	return (
		<Modal
			open={isModalVisible}
			onCancel={() => setIsModalVisible({ id: null, type: null })}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			title={t("pages.dashboard.apikeys.edit_game")}
			footer={null}
			centered
			destroyOnClose
			className="apikey-edit-game-modal"
		>
			<RtpComponent gameCategory={gameCategory} onClose={onClose} gameId={gameId} gameType={gameType} />
		</Modal>
	);
};

EditGameModal.propTypes = {
	/** Game rtp | label data edit modal visibility state */
	isModalVisible: PropTypes.string,
	/** Set setCurrentRTPGameId and toggle popup state */
	setIsModalVisible: PropTypes.func,
	/** Api keys Current editing game id */
	gameId: PropTypes.string,
	/** Current game type */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

export default EditGameModal;
