import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "components/common/breadcrumbs/index.jsx";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/permissions.js";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import ExportButton from "components/common/exportButton/index.jsx";
import Table from "components/common/table";
import ApiUrls from "constants/api.constants.js";
import moment from "moment/moment.js";
import { DATE_TIME_FORMAT } from "constants/date.constants.js";
import { Tooltip } from "antd";
import Filters from "components/dashboard/ssbt/filters.component.jsx";
import Paths from "constants/path.constants.js";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants.js";
import { useNavigate } from "react-router-dom";
import { getSSBTs, setSSBTsSortingAction } from "store/actions/dashboard/ssbt/ssbt.action.js";

const SSBTComponent = () => {
	const ssbts = useSelector((state) => state.ssbts.ssbts);
	const filters = useSelector((state) => state.ssbts.filters);
	const total = useSelector((state) => state.ssbts.total);
	const sorting = useSelector((state) => state.ssbts.sorting);
	const isLoading = useSelector((state) => state.ssbts.isLoading);
	const globalPartnerId = useSelector((state) => state.partner.globalPartnerId);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const renderGames = (value) =>
		value ? (
			<div style={{ maxWidth: 350 }}>
				<Tooltip
					title={value.map((v) => (
						<div key={v}>{v}</div>
					))}
					overlayInnerStyle={{ width: "max-content" }}
					getPopupContainer={(target) => document.body}
				>
					<div className="vs--ellipsis">{value.join(", ")}</div>
				</Tooltip>
			</div>
		) : (
			" - "
		);

	const columns = [
		{
			title: t("pages.dashboard.ssbt.name"),
			dataIndex: "name",
			render: (value, record) => (record.isTesting ? value + " (Test)" : value)
		},
		{
			title: t("pages.dashboard.ssbt.games"),
			dataIndex: "scheduledGames",
			render: renderGames,
			sorter: false
		},
		{
			title: t("pages.dashboard.ssbt.created_at"),
			dataIndex: "created",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		},
		{
			title: t("pages.dashboard.ssbt.last_modified_at"),
			dataIndex: "lastUpdate",
			render: (value) => moment.utc(value).local().format(DATE_TIME_FORMAT)
		}
	];

	const generateAction = () => {
		return {
			edit: {
				handler: (record) => {
					navigate(Paths.DASHBOARD_SSBT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, `${record.id}?PI=${record.partnerId}`));
				}
			}
		};
	};

	return (
		<div className="dashboard-section table-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.ssbt") }]} />
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
						{hasPermission({
							resource: PERMISSION_RESOURCE.TERMINAL,
							action: PERMISSION_ACTION.EXPORT
						}) ? (
							<ExportButton
								columns={columns.map((c) => ({
									title: c.title,
									key: c.dataIndex
								}))}
								tableName={t("pages.dashboard.menu.ssbt")}
								url={ApiUrls.EXPORT_SSBT}
								filters={filters}
								sorting={sorting}
							/>
						) : null}
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={ssbts}
					total={total}
					loadFn={(fromFirstPage) => dispatch(getSSBTs(fromFirstPage))}
					sorting={sorting}
					setSortingFn={(sorting) => dispatch(setSSBTsSortingAction(sorting))}
					updateProps={[globalPartnerId]}
					actions={generateAction()}
					isDisabled={() => false}
				/>
			</div>
		</div>
	);
};

export default SSBTComponent;
