import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import {
	RISK_MANAGEMENT_TOP_PLAYERS_ACTION_BEFORE,
	RISK_MANAGEMENT_TOP_PLAYERS_ACTION_FINISH,
	RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_BEFORE,
	RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_FINISH,
	RISK_MANAGEMENT_SET_TOP_PLAYERS_FILTERS,
	RISK_MANAGEMENT_SET_TOP_PLAYERS_SORTING,
	RISK_MANAGEMENT_SET_TOP_PLAYERS,
	RISK_MANAGEMENT_SET_TOP_PLAYER_DETAILS
} from "store/actionTypes";


export const setTopPlayersBefore = () => ({
	type: RISK_MANAGEMENT_TOP_PLAYERS_ACTION_BEFORE
});

export const setTopPlayersFinish = () => ({
	type: RISK_MANAGEMENT_TOP_PLAYERS_ACTION_FINISH
});

export const setTopPlayerDetailsBefore = () => ({
	type: RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_BEFORE
});

export const setTopPlayerDetailsFinish = () => ({
	type: RISK_MANAGEMENT_TOP_PLAYER_DETAILS_ACTION_FINISH
});

export const setTopPlayersFilters = (filters) => ({
	type: RISK_MANAGEMENT_SET_TOP_PLAYERS_FILTERS,
	payload: { filters }
})

export const setTopPlayersSorting = (sorting) => ({
	type: RISK_MANAGEMENT_SET_TOP_PLAYERS_SORTING,
	payload: { sorting }
});

export const setTopPlayersData = (payload) => ({
	type: RISK_MANAGEMENT_SET_TOP_PLAYERS,
	payload: payload
})

export const setTopPlayerDetailsData = (payload) => ({
	type: RISK_MANAGEMENT_SET_TOP_PLAYER_DETAILS,
	payload: payload
})

export const resetTopPlayerDetailsData = () => {
	return setTopPlayerDetailsData({
		id: null,
		total: 0,
		data: []
	})
}

export const getTopPlayers = (fromFirstPage) => {
	return (dispatch, getState) => {
		dispatch(setTopPlayersBefore());
		const topPlayersList = getState().riskManagement.topPlayers.list;
		const { partnerName, projectName, ...filters } = topPlayersList.filters;
		const params = {
			...topPlayersList.sorting,
			...filters,
			page: fromFirstPage ? 1 : topPlayersList.sorting.page
		};
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_TOP_PLAYERS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value } }) => {
				dispatch(setTopPlayersData({ total: value.item1, data: value.item2 }));
				if (fromFirstPage) {
					dispatch(setTopPlayersSorting({ ...getState().riskManagement.topPlayers.list.sorting, page: 1 }));
				}
			})
			.finally(() => {
				dispatch(setTopPlayersFinish());
			});
	};
}

export const getTopPlayer = (playerId) => {
	return (dispatch, getState) => {
		dispatch(setTopPlayerDetailsBefore());
		const topPlayersList = getState().riskManagement.topPlayers.list;
		const { from, to } = topPlayersList.filters;
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RISK_MANAGEMENT_TOP_PLAYER}`,
			method: Methods.GET,
			params: {
				playerId,
				from,
				to
			}
		})
			.then(({ data: { value } }) => {
				dispatch(setTopPlayerDetailsData({ id: playerId, total: value.item1, data: value.item2 }));
			})
			.finally(() => {
				dispatch(setTopPlayerDetailsFinish());
			});
	};
}