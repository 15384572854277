import { Fragment } from "react";
import BetslipsComponent from "./betslips";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";

const SsbtBetslipsComponent = ({ showBetsBySeparate = false }) => {
	return (
		<Fragment>
			<div className="dashboard-section">
				<BetslipsComponent
					activeTab={PROJECT_TYPE.SSBT.toString()}
					gameCategory={GAME_CATEGORY.SCHEDULED}
					showBetsBySeparate={showBetsBySeparate}
				/>
			</div>
		</Fragment>
	);
};

export default SsbtBetslipsComponent;
