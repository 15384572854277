import axios from "axios";
import { flagsToBinary } from "utils/common";
import Methods from "constants/methods.constants";
import ApiUrls, { SPECIFIC_ENDPOINTS } from "constants/api.constants";

import { PROJECT_TYPE } from "constants/common.constants";
import { PAYMENT_TYPE } from "constants/partner.constants";
import { FILTER_BET_STATE } from "constants/filter.constants";

import { SET_SETTLED_BETSLIPS, SET_SETTLED_BETSLIPS_SORTING, SET_SETTLED_BETSLIPS_FILTERS } from "../../../actionTypes";

import { setBetslipsActionBefore, setBetslipsActionFinished } from "./betslips.action";
import { assignIdsForBets } from "utils/betslips";

const setSettledBets = (data, projectType) => ({
	type: SET_SETTLED_BETSLIPS,
	payload: { data, projectType }
});

export const setSettledBetsSorting = (sorting, projectType) => ({
	type: SET_SETTLED_BETSLIPS_SORTING,
	payload: { sorting, projectType }
});

export const setSettledBetsFilters = (filters, projectType) => ({
	type: SET_SETTLED_BETSLIPS_FILTERS,
	payload: { filters, projectType }
});

export const getSettledBets = (projectType, fromFirstPage, gameCategory = "", showBetsBySeparate = false) => {
	return (dispatch, getState) => {
		dispatch(setBetslipsActionBefore());

		const projectT = projectType === PROJECT_TYPE.ONLINE ? "online" : projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";

		const projectIds = getState().betslips.settled[projectT].filters.projectIds;
		const qsProjectIds = projectIds ? projectIds.map((id) => `projectIds=${id}`).join("&") : "";

		const filters = { ...getState().betslips.settled[projectT].filters };
		delete filters["projectIds"];

		if (projectType === PROJECT_TYPE.RETAIL && filters["paymentType"] === PAYMENT_TYPE.BOTH) {
			delete filters["paymentType"];
		}

		const { GET_BETS, GET_BETS_NEW } = SPECIFIC_ENDPOINTS[projectType];

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${showBetsBySeparate ? GET_BETS_NEW : GET_BETS}?${qsProjectIds}`,
			method: Methods.GET,
			params: {
				...getState().betslips.settled[projectT].sorting,
				...filters,
				status: filters.status.length === 1 && filters.status[0] === FILTER_BET_STATE.ALL ? null : flagsToBinary(filters.status),
				projectType: projectType,
				page: fromFirstPage ? 1 : getState().betslips.settled[projectT].sorting.page,
				isPending: false,
				gameCategory
			}
		})
			.then(({ data: { value: data } }) => {
				const mappedData = assignIdsForBets(data, showBetsBySeparate)
				dispatch(setSettledBets(mappedData, projectType))
				if (fromFirstPage) dispatch(setSettledBetsSorting({ ...getState().betslips.settled[projectT].sorting, page: 1 }));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const changeSettledBet = (id, betId, reason, status, gameCategory = "") => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETS_CHANGE_BET}`,
			method: Methods.POST,
			data: { id, betId, reason, status }
		})
			.then(() => {
				dispatch(getSettledBets(PROJECT_TYPE.RETAIL, false, gameCategory));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};

export const changeSettledBetslip = (id, reason, status, gameCategory = "") => {
	return (dispatch) => {
		dispatch(setBetslipsActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.BETS_CHANGE_BETSLIP}`,
			method: Methods.POST,
			data: { id, reason, status }
		})
			.then(() => {
				dispatch(getSettledBets(PROJECT_TYPE.RETAIL, false, gameCategory));
				dispatch(setBetslipsActionFinished());
			})
			.catch(() => {
				dispatch(setBetslipsActionFinished());
			});
	};
};
