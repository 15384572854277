import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Input, Spin, Select } from "antd";
import NumericInput from "components/common/numericInput";
const { Item: FormItem } = Form;
import { getCompetitionsAndRaces, saveCompetitionsAndRaces } from "store/actions/dashboard/virtuals/games/probabilities.action";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { flagsToBinary, binaryToFlags, isRacingGame, isValidDecimal } from "utils/common";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import {
	GAME_CATEGORY,
	INSTANT_GAME_TYPE,
	INSTANT_GAME_TYPE_FORMAT,
	SCHEDULED_GAME_TYPE,
	SCHEDULED_GAME_TYPE_FORMAT
} from "constants/game.constants";
import competitionsAndRacesType from "types/game/competitonsAndRaces.type";
import gameGeneralInfoType from "types/game/generalInfo.type";
import { MAX_DECIMALS_COUNT } from "constants/common.constants";

const localGameFormatTranslationStrings = {
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "pages.dashboard.games.probabilities.horses",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "pages.dashboard.games.probabilities.greyhounds",
	[INSTANT_GAME_TYPE.GREYHOUNDS_RACE.value]: "pages.dashboard.games.probabilities.greyhounds",
	[SCHEDULED_GAME_TYPE.KENO.value]: "common.keno",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "pages.dashboard.games.probabilities.horses"
};

const localRaceFormatTranslationStrings = {
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "pages.dashboard.games.probabilities.race_format",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "pages.dashboard.games.probabilities.race_format",
	[SCHEDULED_GAME_TYPE.KENO.value]: "pages.dashboard.games.probabilities.keno_format",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "pages.dashboard.games.probabilities.race_format"
};

const localCompetitionsStrings = {
	[SCHEDULED_GAME_TYPE.HORSES_RACE.value]: "Sandy Peaks",
	[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value]: "Pink Park",
	[SCHEDULED_GAME_TYPE.STEEPLECHASING.value]: "Adventure Park",
	[INSTANT_GAME_TYPE.GREYHOUNDS_RACE.value]: "Pink Park"
};

/** Game Edit Page Probabilities Tab racing competitions and races subTab Component */
const CompetitionsAndRacesComponent = ({
	isSaving,
	isLoading,
	getCompetitionsAndRaces,
	saveCompetitionsAndRaces,
	competitionsAndRaces,
	generalInfo,
	onTabChange,
	gameCategory
}) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Load game handicap Ppobability */
	useEffect(() => {
		if (routeParams.id) {
			getCompetitionsAndRaces(routeParams.id, generalInfo.type, gameCategory);
		}
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const updateObj = {
			raceFormat: binaryToFlags(Object.values(getFormats()), competitionsAndRaces.raceFormat)
		};

		if (generalInfo.type === SCHEDULED_GAME_TYPE.STEEPLECHASING.value) {
			updateObj.failProbability = isNaN(competitionsAndRaces.failProbability) ? null : parseFloat(competitionsAndRaces.failProbability);
		}

		setFieldsValue(updateObj);
	}, [competitionsAndRaces]);

	/** Function to get race formats depend on game type
	 * @function
	 * @returns {object}
	 * @memberOf CompetitionsAndRacesComponent
	 */
	const getFormats = () =>
		gameCategory === GAME_CATEGORY.SCHEDULED ? SCHEDULED_GAME_TYPE_FORMAT[generalInfo.type] : INSTANT_GAME_TYPE_FORMAT[generalInfo.type];

	/** Fires when form submitted
	 * @function
	 * @memberOf CompetitionsAndRacesComponent
	 */
	const handleForm = () => {
		validateFields()
			.then(({ raceFormat, failProbability }) => {
				const savePayload = {
					id: routeParams.id,
					raceFormat: flagsToBinary(raceFormat),
					gameType: generalInfo.type
				};

				if (generalInfo.type === SCHEDULED_GAME_TYPE.STEEPLECHASING.value) {
					savePayload.failProbability = parseFloat(failProbability);
				}

				saveCompetitionsAndRaces(savePayload, gameCategory);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf CompetitionsAndRacesComponent
	 */
	const formChanged = (formValues) => {
		if (formValues.raceFormat) {
			formValues.raceFormat = flagsToBinary(formValues.raceFormat);
		}
		return isFormChanged(formValues, { ...competitionsAndRaces });
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					raceFormat: binaryToFlags(Object.values(getFormats()), competitionsAndRaces.raceFormat),
					failProbability: 0.1
				}}
				onValuesChange={(changed, formValues) => {
					setIsFormTouched(formChanged({ ...formValues, failProbability: parseFloat(formValues.failProbability) }));
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12}>
								<FormItem
									label={t(localRaceFormatTranslationStrings[generalInfo.type]) || ""}
									name="raceFormat"
									rules={[{ required: true, message: t("validation.field_required") }]}
								>
									<Select
										mode="multiple"
										disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY })}
										suffixIcon={<i className="icon-down" />}
									>
										{Object.entries(getFormats()).map(([key, value]) => {
											const num = key.split("_")[1];
											let text = t(localGameFormatTranslationStrings[generalInfo.type]);

											if (generalInfo.type === SCHEDULED_GAME_TYPE.KENO.value) {
												text = text + num;
											} else {
												text = num + text;
											}
											return (
												<Select.Option key={key} value={value}>
													{text}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
							</Col>
						</Row>
						{isRacingGame(generalInfo.type, gameCategory) && (
							<Row gutter={[16, 0]}>
								<Col xs={24} sm={12} xl={6}>
									<FormItem label={t("pages.dashboard.games.probabilities.competitions")}>
										<Input disabled={true} value={localCompetitionsStrings[generalInfo.type] || ""} />
									</FormItem>
								</Col>
							</Row>
						)}
						{generalInfo.type === SCHEDULED_GAME_TYPE.STEEPLECHASING.value ? (
							<Col xs={24} sm={12} xl={6} className="vs--mt-10">
								<FormItem
									label={<b>{t("common.horse_fail_probabilty")}</b>}
									name="failProbability"
									rules={[
										{
											required: true,
											message: t("validation.field_required")
										},
										() => ({
											validator(_, value) {
												if (value === "0.") {
													return Promise.reject(t("common.decimal_value_validation_error"));
												}
												const numericValue = Number(value) || 0;
												if (numericValue < 0 || numericValue > 0.4) {
													return Promise.reject(t("common.failProbability_min_max_validation"));
												}

												if (!isValidDecimal(numericValue, MAX_DECIMALS_COUNT)) {
													return Promise.reject(t("common.decimal_value_validation_error"));
												}

												return Promise.resolve();
											}
										})
									]}
								>
									<NumericInput decimalsCount={2} />
								</FormItem>
							</Col>
						) : null}
					</div>
					{hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY }) && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** CompetitionsAndRacesComponent propTypes
 * PropTypes
 */
CompetitionsAndRacesComponent.propTypes = {
	/** Redux state property, is true when rtps is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Redux action to get racing competitions and races */
	getCompetitionsAndRaces: PropTypes.func,
	/** Redux action to save racing competitions and races */
	saveCompetitionsAndRaces: PropTypes.func,
	/** Redux state, represents the racing competitions and races data of current editing game  */
	competitionsAndRaces: competitionsAndRacesType,
	/** Redux state, represents the general info of current editing game  */
	generalInfo: gameGeneralInfoType,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getCompetitionsAndRaces: (id, gameType, gameCategory) => {
		dispatch(getCompetitionsAndRaces(id, gameType, gameCategory));
	},
	saveCompetitionsAndRaces: (data, gameCategory) => {
		dispatch(saveCompetitionsAndRaces(data, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		competitionsAndRaces: state.games.editingGame.probabilities.competitionsAndRaces,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		generalInfo: state.games.editingGame.generalInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionsAndRacesComponent);
