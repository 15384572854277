import {
	SET_SSBT_UPDATE_GAME,
	SET_EDITING_SSBT_GAMES_ACTION,
	SET_SSBT_GAMES_ACTION_BEFORE,
	SET_SSBT_GAMES_ACTION_FINISH,
	SET_SSBTS_ACTION,
	SET_SSBTS_ACTION_BEFORE,
	SET_SSBTS_ACTION_FINISH,
	SET_SSBTS_FILTERS_ACTION,
	SET_SSBTS_SORTING_ACTION,
	SET_SSBT_SAVE_ACTION_BEFORE,
	SET_SSBT_SAVE_ACTION_FINISH,
	SET_SSBT_DELETE_GAME,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION_BEFORE,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION_FINISH,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION,
	SET_SSBT_ADD_ONS_ACTION_BEFORE,
	SET_SSBT_ADD_ONS_ACTION_FINISH,
	SET_SSBT_ADD_ONS_SAVE_BEFORE,
	SET_SSBT_ADD_ONS_SAVE_FINISH,
	SET_SSBT_ADD_ONS
} from "store/actionTypes.js";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SSBTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SET_SSBTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case SET_SSBT_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true
			};
		case SET_SSBT_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false
			};
		case SET_SSBTS_ACTION:
			return {
				...state,
				ssbts: payload.item2,
				total: payload.item1
			};
		case SET_SSBTS_SORTING_ACTION:
			return {
				...state,
				sorting: payload
			};
		case SET_SSBTS_FILTERS_ACTION:
			return {
				...state,
				filters: payload,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_EDITING_SSBT_GAMES_ACTION:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					games: payload
				}
			};
		case SET_SSBT_GAMES_ACTION_BEFORE:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					gamesLoading: true
				}
			};
		case SET_SSBT_GAMES_ACTION_FINISH:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					gamesLoading: false
				}
			};
		case SET_SSBT_UPDATE_GAME:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					games: state.editingSSBT.games.map((game) => (game.id === payload.id ? payload : game))
				}
			};
		case SET_SSBT_DELETE_GAME:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					games: state.editingSSBT.games.filter((game) => game.id !== payload)
				}
			};
		case SET_SSBT_GAME_OTHER_CONFIGS_ACTION_BEFORE:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					otherConfigs: {
						...state.editingSSBT.otherConfigs,
						isLoading: true
					}
				}
			};
		case SET_SSBT_GAME_OTHER_CONFIGS_ACTION_FINISH:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					otherConfigs: {
						...state.editingSSBT.otherConfigs,
						isLoading: false
					}
				}
			};
		case SET_SSBT_GAME_OTHER_CONFIGS_ACTION:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					otherConfigs: {
						...state.editingSSBT.otherConfigs,
						data: payload
					}
				}
			};
		case SET_SSBT_ADD_ONS_ACTION_BEFORE:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					addOns: {
						...state.editingSSBT.addOns,
						isLoading: true
					}
				}
			};
		case SET_SSBT_ADD_ONS_ACTION_FINISH:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					addOns: {
						...state.editingSSBT.addOns,
						isLoading: false
					}
				}
			};
		case SET_SSBT_ADD_ONS_SAVE_BEFORE:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					addOns: {
						...state.editingSSBT.addOns,
						isSaving: true
					}
				}
			};
		case SET_SSBT_ADD_ONS_SAVE_FINISH:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					addOns: {
						...state.editingSSBT.addOns,
						isSaving: false
					}
				}
			};
		case SET_SSBT_ADD_ONS:
			return {
				...state,
				editingSSBT: {
					...state.editingSSBT,
					addOns: {
						...state.editingSSBT.addOns,
						data: {
							...state.editingSSBT.addOns.data,
							...payload.data,
							boostFactors: {
								...state.editingSSBT.addOns.data.boostFactors,
								...payload.data.boostFactors
							}
						}
					}
				}
			};
		default:
			return state;
	}
};
