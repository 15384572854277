import Breadcrumbs from "components/common/breadcrumbs";
import { useTranslation } from "react-i18next";
import TopPlayersMainTable from "./mainTable";
import { Fragment } from "react";

const TopPlayers = () => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.risk_management"), path: "" }, { title: t("pages.dashboard.menu.top_players"), path: "" }]} />
				<TopPlayersMainTable />
			</div>
		</Fragment>
	);
};

export default TopPlayers;
