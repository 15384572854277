import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import Table from "components/common/table";
import GameAddComponent from "./game-add.component";
import EditGameModal from "./editGameModal";
import apiKeyGameType from "types/apiKey/apiKeyGame.type";
import { DATE_TIME_FORMAT } from "constants/date.constants";
import {
	getApiKeyGames,
	updateApiKeyGame,
	deleteApiKeyGame,
	resetApiKeyGames,
	saveApiKeyGames
} from "store/actions/dashboard/online/apikeys/games.action";
import { hasPermission, hasOneOfPermissions } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import {
	GAME_LABEL_TYPE_MAPPER,
	GAME_LABEL_TYPE_FLAGS,
	GAME_CATEGORY,
	REDUX_EDITING_API_KEY_GAME_FIELD,
	GAME_CHANNEL,
	INSTANT_GAME_TYPE
} from "constants/game.constants";
import { SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { arrayMove, getGamesFromConfig } from "utils/common";
import { binaryToFlags } from "utils/common";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action";

/** Api Key Edit Page Games Tab Component */
const GamesComponent = ({
	apiKeyGames,
	gamesListForAdding,
	getGames,
	getApiKeyGames,
	saveApiKeyGames,
	resetApiKeyGames,
	isLoading,
	updateApiKeyGame,
	deleteApiKeyGame,
	gameCategory
}) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [showAddPopup, setShowAddPopup] = useState(false);
	const [currentRTPGameId, setCurrentRTPGameId] = useState(null);
	const [currentGameType, setCurrentGameType] = useState(null);

	const isModifyDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_GAMES, action: PERMISSION_ACTION.MODIFY });

	const setGameParams = (record) => {
		setCurrentRTPGameId(record.id);
		setCurrentGameType(record.type);
	};

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.games.name"),
			dataIndex: "name",
			sorter: false,
			className: "drag-visible"
		},
		{
			title: t("pages.dashboard.games.type"),
			dataIndex: "type",
			render: (value) => {
				const type = Object.values(gameCategory === GAME_CATEGORY.SCHEDULED ? SCHEDULED_GAME_TYPE : INSTANT_GAME_TYPE).find(
					(g) => g.value === value
				)?.type;
				return type ? t(`common.${type}`) : null;
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.games.state"),
			dataIndex: "label",
			render: (label = []) =>
				binaryToFlags(GAME_LABEL_TYPE_FLAGS, label).reduce((p, v, i) => p + (i > 0 ? ", " : "") + GAME_LABEL_TYPE_MAPPER[v], ""),
			sorter: false
		}
	];

	/** Get all games */
	useEffect(() => {
		getGames(gameCategory);
	}, [gameCategory]);

	/** Reset Api keys Games on component unmount */
	useEffect(() => () => resetApiKeyGames(gameCategory), [gameCategory]);

	/** Close game add popup, after game saved */
	useEffect(() => {
		setShowAddPopup(false);
	}, [apiKeyGames]);

	/* Specific case, view action busy for activiti functional */
	const generateEditAction = () => {
		let key = null;

		if (
			hasOneOfPermissions([
				{ resource: PERMISSION_RESOURCE.APIKEY_RTP, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.APIKEY_OTHER_CONFIGS, action: PERMISSION_ACTION.VIEW }
			])
		) {
			key = "view";
		}

		if (
			hasOneOfPermissions([
				{ resource: PERMISSION_RESOURCE.APIKEY_RTP, action: PERMISSION_ACTION.MODIFY },
				{ resource: PERMISSION_RESOURCE.APIKEY_OTHER_CONFIGS, action: PERMISSION_ACTION.MODIFY }
			])
		) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => setGameParams(record),
				title: t("pages.dashboard.apikeys.rtp.rtp_btn")
			}
		};
	};

	const getAvailableTypes = useCallback(() => {
		const possible = getGamesFromConfig(gameCategory ?? GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = apiKeyGames.map((g) => g.type);
		possible.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});
		return available;
	}, [gameCategory, apiKeyGames]);

	const loadFn = () => getApiKeyGames(routeParams.id, gameCategory);

	const initialGamesListForAdding = useMemo(() => {
		const available = getAvailableTypes();
		const initial = gamesListForAdding.filter((game) => available.includes(game.type));
		return initial;
	}, [gamesListForAdding, getAvailableTypes]);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_GAMES, action: PERMISSION_ACTION.CREATE }) ? (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button vs--ml-8"
						onClick={() => setShowAddPopup(true)}
						disabled={apiKeyGames.length === getGamesFromConfig(gameCategory).length || !initialGamesListForAdding.length}
					>
						<span>{t("pages.dashboard.apikeys.add_new_game")}</span>
					</Button>
				</div>
			) : null}
			<Table
				loading={isLoading}
				columns={columns}
				data={apiKeyGames}
				loadFn={loadFn}
				total={apiKeyGames.length}
				actions={{
					activate: isModifyDisabled
						? null
						: {
								isChecked: (record) => record.enabled,
								messageKey: "game",
								handler: (isChecked, record) =>
									updateApiKeyGame(
										{
											enabled: isChecked,
											gameId: record.id,
											id: routeParams.id
										},
										gameCategory
									),
								disabled: (record) => Boolean(record.disableInfo),
								info: (record) =>
									!record.enabled && record.disableInfo ? (
										<Fragment>
											<p>
												{t("pages.dashboard.games.game_disabled_tooltip")}
												<br />
											</p>
											{record.disableInfo && record.disableInfo.disableFrom ? (
												<span>
													<b>{t("common.from")} : </b>
													{moment.utc(record.disableInfo.disableFrom).local().format(DATE_TIME_FORMAT)}
													<br />
												</span>
											) : null}
											{record.disableInfo && record.disableInfo.disableTo ? (
												<span>
													<b>{t("common.to")} : </b>
													{moment.utc(record.disableInfo.disableTo).local().format(DATE_TIME_FORMAT)}
													<br />
												</span>
											) : null}
											<span>
												<b>{t("pages.dashboard.games.reason")} : </b>
												{record.disableInfo.disableReason}
											</span>
										</Fragment>
									) : null
							},
					delete: hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_GAMES, action: PERMISSION_ACTION.DELETE })
						? {
								messageKey: "game",
								handler: (record) => {
									deleteApiKeyGame(routeParams.id, record.id, gameCategory);
								}
							}
						: null,
					...generateEditAction()
				}}
				isDisabled={(record) => isModifyDisabled || !record.enabled}
				isDraggable={() => !isModifyDisabled}
				noPagination={true}
				uniqueKey="id"
				draggable={
					isModifyDisabled
						? null
						: {
								onDragEnd: (oldIndex, newIndex) => {
									const updatedGames = arrayMove(apiKeyGames, oldIndex, newIndex);
									saveApiKeyGames(routeParams.id, updatedGames, gameCategory);
								}
							}
				}
			/>

			{showAddPopup ? (
				<GameAddComponent
					onClose={() => {
						setShowAddPopup(false);
					}}
					onSuccess={loadFn}
					gameCategory={gameCategory}
					initialGameList={initialGamesListForAdding}
				/>
			) : null}

			<EditGameModal
				isModalVisible={currentRTPGameId}
				setIsModalVisible={setGameParams}
				gameId={currentRTPGameId}
				gameType={currentGameType}
				gameCategory={gameCategory}
			/>
		</div>
	);
};

/** GamesComponent propTypes
 * PropTypes
 */
GamesComponent.propTypes = {
	/** Redux action to get api keys */
	getApiKeyGames: PropTypes.func,
	/** Redux state property, represents the array of games of api key */
	apiKeyGames: PropTypes.arrayOf(apiKeyGameType),
	/** Redux state property, is true when loading api key games */
	isLoading: PropTypes.bool,
	/** Redux action to update api key game */
	updateApiKeyGame: PropTypes.func,
	/** Redux action to delete api key game */
	deleteApiKeyGame: PropTypes.func,
	/** Redux action to reset api key games */
	resetApiKeyGames: PropTypes.func,
	/** Redux action to save api key games */
	saveApiKeyGames: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyGames: (id, gameCategory) => {
		dispatch(getApiKeyGames(id, gameCategory));
	},
	saveApiKeyGames: (id, games, gameCategory) => {
		dispatch(saveApiKeyGames(id, games, gameCategory));
	},
	updateApiKeyGame: (game, gameCategory) => {
		dispatch(updateApiKeyGame(game, gameCategory));
	},
	deleteApiKeyGame: (id, gameId, gameCategory) => {
		dispatch(deleteApiKeyGame(id, gameId, gameCategory));
	},
	resetApiKeyGames: (gameCategory) => {
		dispatch(resetApiKeyGames(gameCategory));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory, GAME_CHANNEL.ONLINE));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isLoading: state.apikeys.editingApikey.games.isLoading,
		apiKeyGames: state.apikeys.editingApikey.games[REDUX_EDITING_API_KEY_GAME_FIELD[gameCategory]],
		gamesListForAdding: state.games.games
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesComponent);
