import { useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Row, Col, Select, DatePicker, Radio, Tooltip } from "antd";

import locale from "antd/es/date-picker/locale/en_US";

import { setEventsFilters, getEvents } from "store/actions/dashboard/events/events.action";

import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";

import useAutosuggestion from "hooks/useAutosuggestion";

import { monthAgo } from "utils/dateTime";
import { getGamesFromConfig } from "utils/common";

import { FILTER_EVENT_STATE } from "constants/filter.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";

const { Item: FormItem } = Form;

/** Results Page Filters Component */
const Filters = ({ setEventsFilters, getEvents, filters, projectType, globalPartnerId, gameCategory }) => {
	const { t } = useTranslation();
	const autoSuggestionValue = useAutosuggestion(
		projectType === PROJECT_TYPE.ONLINE
			? AUTOSUGGESTION_TYPE.API_KEY_NAMES
			: projectType === PROJECT_TYPE.RETAIL
				? AUTOSUGGESTION_TYPE.BETSHOP_NAMES
				: AUTOSUGGESTION_TYPE.TERMINALS,
		[globalPartnerId, projectType, gameCategory],
		{
			disableAction: () => !globalPartnerId,
			actionArguments: [{ params: { gameCategory: projectType !== PROJECT_TYPE.ONLINE ? null : gameCategory } }]
		}
	);

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{
			name: "projectId",
			title:
				projectType === PROJECT_TYPE.ONLINE
					? t("pages.dashboard.events.project")
					: projectType === PROJECT_TYPE.RETAIL
						? t("pages.dashboard.events.betshop")
						: t("pages.dashboard.events.terminal"),
			values: autoSuggestionValue.map((g) => ({ title: g.value, value: g.key }))
		},
		{ name: "id", title: t("pages.dashboard.events.event_id") },
		{
			name: "status",
			title: t("pages.dashboard.events.status"),
			values: [
				{ title: t("common.all"), value: FILTER_EVENT_STATE.ALL },
				{ title: t("pages.dashboard.events.completed"), value: FILTER_EVENT_STATE.FINISHED },
				{ title: t("pages.dashboard.events.cancelled"), value: FILTER_EVENT_STATE.CANCELED }
			]
		},
		{
			name: "gameType",
			title: t("pages.dashboard.events.game_type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				getGamesFromConfig(gameCategory).map((g) => ({
					title: t(`common.${g.type}`),
					value: g.value
				}))
			)
		},
		{
			name: "isResultCalculated",
			title: t("pages.dashboard.events.calculation_status"),
			values: [
				{ title: t("common.all"), value: "" },
				{ title: t("pages.dashboard.events.calculation_status_success"), value: "true" },
				{ title: t("pages.dashboard.events.calculation_status_fail"), value: "false" }
			]
		}
	];

	return (
		<FiltersWrapper
			filtersName={`events_${projectType}_${gameCategory}`}
			loadFn={() => getEvents(projectType, false, gameCategory)}
			setFiltersFn={(filters) => setEventsFilters(filters, projectType, gameCategory)}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList()}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={
								projectType === PROJECT_TYPE.ONLINE
									? t("pages.dashboard.events.project")
									: projectType === PROJECT_TYPE.RETAIL
										? t("pages.dashboard.events.betshop")
										: t("pages.dashboard.events.terminal")
							}
							name="projectId"
						>
							<SearchableSelect
								items={autoSuggestionValue}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.value}
								addNone={true}
								noneText={t("common.all")}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.events.event_id")} name="id">
							<NumericInput placeholder={`${t("common.enter")} ${t("pages.dashboard.events.event_id")}`} isInteger={true} maxLength={12} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.events.status")} name="status">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_EVENT_STATE.ALL}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								<Select.Option value={FILTER_EVENT_STATE.FINISHED}>
									<span title={t("pages.dashboard.events.completed")}>{t("pages.dashboard.events.completed")}</span>
								</Select.Option>
								<Select.Option value={FILTER_EVENT_STATE.CANCELED}>
									<span title={t("pages.dashboard.events.cancelled")}>{t("pages.dashboard.events.cancelled")}</span>
								</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.events.game_type")} name="gameType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								{getGamesFromConfig(gameCategory).map((g) => (
									<Select.Option value={g.value} key={g.value} title={t(`common.${g.type}`)}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.events.calculation_status")} name="isResultCalculated">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								<Select.Option value="true">{t("pages.dashboard.events.calculation_status_success")}</Select.Option>
								<Select.Option value="false">{t("pages.dashboard.events.calculation_status_fail")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.events.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get events */
	getEvents: PropTypes.func,
	/** Redux action to set events filters */
	setEventsFilters: PropTypes.func,
	/** Events filters */
	filters: PropTypes.object,
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getEvents: (projectType, fromFirstPage, gameCategory) => {
		dispatch(getEvents(projectType, fromFirstPage, gameCategory));
	},
	setEventsFilters: (filters, projectType, gameCategory) => {
		dispatch(setEventsFilters(filters, projectType, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
