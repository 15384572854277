import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "components/common/table";
import { Form, Col, Row, Modal, Select, Input } from "antd";
const { Item: FormItem } = Form;

import { POPUP_SIZE } from "constants/common.constants";
import { getTopPlayer, resetTopPlayerDetailsData } from "store/actions/dashboard/riskManagment/topPlayers/topPlayers.actions";
import { GAME_TYPE_MAPPER } from "constants/game.constants";

const dashedString = "- - -";

/** Risk Management Player Details Popup Component */
const PlayerDetails = ({ item, data, total, isLoading, getTopPlayer, resetTopPlayerDetailsData, onClose }) => {
	const { t } = useTranslation();

	const columns = [
		{
			title: t("pages.dashboard.events.game_name"),
			dataIndex: "totalCol",
			sorter: false,
			render: (value, record) => {
				const renderValue = (
					value === "total"
						? t("pages.dashboard.reports.total")
						: t(`common.${GAME_TYPE_MAPPER[record.gameCategory][record.gameType]}`)
				);
				
				return renderValue ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.dashboard.betCount"),
			dataIndex: "betsCount",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t('pages.dashboard.dashboard.turnover'),
			dataIndex: 'totalStake',
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.session.report.ggr"),
			dataIndex: "playerGGR",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		}
	];

	const getTotalData = () => {

		if (data.length < 2) return [];

		const totalData = {
			gameType: "",
			totalCol: "total",
			betsCount: 0,
			totalStake: 0,
			playerGGR: 0
		};
		
		data.forEach((detail) => {
			totalData.betsCount += detail.betsCount
			totalData.totalStake += detail.totalStake
			totalData.playerGGR += detail.playerGGR
		})

		totalData.totalStake = totalData.totalStake.toFixed(2);
		totalData.playerGGR = totalData.playerGGR.toFixed(2);
		
		return totalData;
	};

	useEffect(() => {
		getTopPlayer(item.playerId)
		return () => {
			resetTopPlayerDetailsData()
		}
	}, [item.playerId])

	return (
		<Modal
			open={true}
			title={`${t("common.view")} - ${t("pages.dashboard.reports.player")} ${item.playerExternalId}`}
			onCancel={onClose}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			okButtonProps={{ hidden: true }}
			centered
			maskClosable={true}
		>
			<Table
				loading={isLoading}
				columns={columns}
				data={data.concat(getTotalData())}
				noPagination={true}
				actions={{}}
				isDisabled={() => false}
				uniqueKey="gameType"
			/>
		</Modal>
	);
};

/** PlayerDetails propTypes
 * PropTypes
 */
PlayerDetails.propTypes = {
	isLoading: PropTypes.bool,
	getTopPlayer: PropTypes.func,
	item: PropTypes.object,
	onClose: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getTopPlayer: (playerId) => {
		dispatch(getTopPlayer(playerId));
	},
	resetTopPlayerDetailsData: () => {
		dispatch(resetTopPlayerDetailsData())
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.riskManagement.topPlayers.playerDetails.isLoading,
		total: state.riskManagement.topPlayers.playerDetails.total,
		data: state.riskManagement.topPlayers.playerDetails.data
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDetails);
