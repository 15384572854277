import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Tag, Tooltip } from "antd";

import Filters from "./filters";
import { useTranslation } from "react-i18next";
import { getTopPlayers, setTopPlayersSorting } from "store/actions/dashboard/riskManagment/topPlayers/topPlayers.actions";

import Table from "components/common/table";
import ExportButtonImminently from "components/common/exportButton/exportButtonImminently";

import { binaryToFlags, getGamesFromConfig } from "utils/common";
import { hasPermission } from "utils/permissions";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { EXPORT_TYPE } from "constants/common.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";
import { RISK_CASE_STATUS_COLOR, RISK_CASE_STATUS_TRANSLATIONS } from "constants/riskManagement.constants";
import PlayerDetails from "./playerDetails";
import "./styles.scss";

const dashedString = "- - -";

const TopPlayersMainTable = ({
	isLoading = false,
	topPlayersData = [],
	total = 0,
	filters = {},
	sorting = {},
	getTopPlayers = Function.prototype,
	setTopPlayersSorting = Function.prototype,
}) => {

	const { t } = useTranslation();

	const [showTopPlayerDetailsPopup, setShowTopPlayerDetailsPopup] = useState(null);

	const columns = [
		{
			title: t("common.partner"),
			dataIndex: "partnerName",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.bets.project"),
			dataIndex: "projectName",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.bets.player_id"),
			dataIndex: "playerId",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			},
			copy: true
		},
		{
			title: t("pages.dashboard.bets.player_external_id"),
			dataIndex: "playerExternalId",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			},
			copy: true
		},
		{
			title: t("pages.dashboard.dashboard.betCount"),
			dataIndex: "betsCount",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t('pages.dashboard.dashboard.turnover'),
			dataIndex: 'totalStake',
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		},
		{
			title: t("pages.dashboard.session.report.ggr"),
			dataIndex: "playerGGR",
			sorter: false,
			render: (value) => {
				return value ?? dashedString;
			}
		}
	];

	const { partnerName, ...exportFilters } = filters;

	return (
		<div className="dashboard-section top-players-table">
			<div className="dashboard-section-content">
				<div className="table-header">
					<div className="table-buttons-dropdowns">
					</div>
					<Filters />
				</div>
				<Table
					loading={isLoading}
					columns={columns}
					data={topPlayersData}
					loadFn={getTopPlayers}
					sorting={sorting}
					setSortingFn={setTopPlayersSorting}
					total={total}
					detailsType="table"
					uniqueKey="id"
					noPagination={true}
					actions={{
						view: {
							handler: (record) => {
								setShowTopPlayerDetailsPopup(record);
							}
						}
					}}
				/>
				{
					showTopPlayerDetailsPopup
						? <PlayerDetails item={showTopPlayerDetailsPopup} onClose={() => setShowTopPlayerDetailsPopup(null)} />
						: null
				}
			</div>
		</div>
	);
};

/**
 * TopPlayersMainTable PropTypes
 */
TopPlayersMainTable.propTypes = {
	filters: PropTypes.object,
	sorting: PropTypes.object,
	isLoading: PropTypes.bool,
	topPlayersData: PropTypes.arrayOf(PropTypes.object),
	total: PropTypes.number,
	getTopPlayers: PropTypes.func,
	setTopPlayersSorting: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getTopPlayers: (fromFirstPage) => {
		dispatch(getTopPlayers(fromFirstPage));
	},
	setTopPlayersSorting: (sorting) => {
		dispatch(setTopPlayersSorting(sorting));
	}
});

const mapStateToProps = (state) => ({
	filters: state.riskManagement.topPlayers.list.filters,
	sorting: state.riskManagement.topPlayers.list.sorting,
	isLoading: state.riskManagement.topPlayers.list.isLoading,
	topPlayersData: state.riskManagement.topPlayers.list.data,
	total: state.riskManagement.topPlayers.list.total
});

export default connect(mapStateToProps, mapDispatchToProps)(TopPlayersMainTable);
