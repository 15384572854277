import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Row, Col, Select, Form, Switch, Button, Dropdown, Menu, Tooltip, Spin } from "antd";
const { Item: FormItem } = Form;

import Breadcrumbs from "components/common/breadcrumbs";
import Table from "components/common/table";
import Question from "components/common/question";
import TranslationField from "./translationField.component";
import TranslationAddComponent from "./translation-add.component";

import translationGroupType from "types/translation/group.type";
import translationType from "types/translation/translation.type";

import {
	getTranslationGroups,
	getTranslations,
	setSystemDefault,
	publishTranslations,
	unpublishTranslations
} from "store/actions/dashboard/cms/translation/translation.action";
import { getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";
import { getPartnerAvailableLanguages } from "store/actions/dashboard/partners/languages.action";

import { hasPermission, hasPermissions, hasOneOfPermissions } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { GAME_CATEGORY, GAME_TYPE_MAPPER, INSTANT_GAME_TYPE_MAPPER, SCHEDULED_GAME_TYPE_MAPPER } from "constants/game.constants";
import { MARKETS_TRANSLATIONS_GROUP_NAMES_TEXT } from "constants/translation.constants";

/** Translations Page Component */
const TranslationsComponent = ({
	globalPartnerId,
	getTranslationGroups,
	isGroupsLoading,
	isLoading,
	isPublishing,
	isUnpublishing,
	getTranslations,
	translations,
	publishTranslations,
	unpublishTranslations,
	groups,
	systemDefault,
	setSystemDefault,
	getSystemAvailableLanguages,
	getPartnerAvailableLanguages,
	systemLanguages,
	partnerLanguages,
	isPartnerAvailableLoading,
	isSystemAvailableLoading
}) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	const { setFieldsValue } = formInstance;

	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const [selectedGroupID, setSelectedGroupID] = useState(null);

	/** State to show/hide translation creation popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);

	/** State to show/hide unpublish confirmation popup */
	const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);

	/** Load translation groups */
	useEffect(() => {
		getTranslationGroups();
		if (systemDefault) {
			if (hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW })) {
				getSystemAvailableLanguages();
			} else if (hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW })) {
				getPartnerAvailableLanguages();
				setSystemDefault(false);
			}
		} else {
			if (
				!hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW }) &&
				hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW })
			) {
				getSystemAvailableLanguages();
				setSystemDefault(true);
			}
		}
	}, []);

	/** Load translation groups */
	useEffect(() => {
		const data = {};
		translations.forEach((t) => {
			data[t.key] = data[t.key] || {};
			t.translations.forEach((tr) => {
				data[t.key][tr.languageCode.toLowerCase()] = tr.text;
			});
		});
		setFieldsValue({
			translations: data
		});

		setShowCreatePopup(false);
	}, [translations]);

	/** Load translation groups */
	useEffect(() => {
		if (systemDefault && Object.keys(systemLanguages).length > 0) {
			setSelectedLanguages(Object.keys(systemLanguages).splice(0, 3));
		} else if (!systemDefault && Object.keys(partnerLanguages).length > 0) {
			setSelectedLanguages(Object.keys(partnerLanguages).splice(0, 3));
		}
	}, [partnerLanguages, systemLanguages, systemDefault]);

	/** Load translation on resource change */
	useEffect(() => {
		if (selectedGroupID && selectedLanguages.length > 0) {
			handleResourceDropdownChange();
		}
	}, [selectedGroupID, selectedLanguages]);

	/** Load translation on global partner change */
	useEffect(() => {
		if (!systemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW })) {
			getPartnerAvailableLanguages();
		}
	}, [globalPartnerId]);

	/** Load Translations */
	useEffect(() => {
		if (
			((systemDefault && Object.keys(systemLanguages).length) || (!systemDefault && Object.keys(partnerLanguages).length)) &&
			selectedLanguages.length > 0 &&
			groups.length > 0
		) {
			const grId = selectedGroupID || groups[0].id;
			setSelectedGroupID(grId);
			setFieldsValue({
				group: grId
			});
		}
	}, [groups, selectedLanguages]);

	/** Set column dropdown values */
	useEffect(() => {
		setFieldsValue({
			languages: selectedLanguages
		});
	}, [selectedLanguages]);

	/** Get languages to edit
	 * @function
	 * @returns {object}
	 * @memberOf TranslationsComponent
	 */
	const getLanguages = () => (isPartnerAvailableLoading || isSystemAvailableLoading || systemDefault ? systemLanguages : partnerLanguages);

	/** Get current group and subgroup id
	 * @function
	 * @returns {object}
	 * @memberOf TranslationsComponent
	 */
	const getGroupAndSubGroupId = () => {
		let groupId = null;
		let subGroupId = null;
		if (groups.map((gr) => gr.id).includes(selectedGroupID)) {
			groupId = selectedGroupID;
		} else {
			groups.forEach((gr) => {
				if (gr.subGroups.length === 0) return true;
				if (gr.subGroups.map((s) => s.id).includes(selectedGroupID)) {
					(subGroupId = selectedGroupID), (groupId = gr.id);
					return false;
				}
			});
		}
		return { groupId, subGroupId };
	};

	/** Fires on groups dropdown change
	 * @function
	 * @memberOf TranslationsComponent
	 */
	const handleResourceDropdownChange = () => {
		const { groupId, subGroupId } = getGroupAndSubGroupId();
		getTranslations(groupId, subGroupId, selectedLanguages, systemDefault);
	};

	/** Make columns array depend on languages list
	 * @function
	 * @param {string} value - selected value
	 * @returns {array}
	 * @memberOf TranslationsComponent
	 */
	const makeColumns = () => {
		const columns = [
			{
				title: t("pages.dashboard.translations.keys"),
				dataIndex: "key",
				sorter: false,
				fixed: "left",
				width: "17%"
			},
			{
				title: t("pages.dashboard.translations.system_default"),
				dataIndex: "text",
				render: (value) => (
					<span title={value} className="table-col-shorten-text">
						{value}
					</span>
				),
				sorter: false,
				width: "17%"
			}
		];

		selectedLanguages.forEach((lang, ind) => {
			columns.push({
				title: "",
				dataIndex: lang + "_" + systemDefault + "_" + globalPartnerId,
				render: (value, record) => renderTranslationColumn(lang, record),
				sorter: false,
				className: "ant-table-input-cell"
			});
		});

		return columns;
	};

	/** Render column for translation
	 * @function
	 * @param {string} lang - language for column
	 * @param {object} record - row item
	 * @returns {JSX}
	 * @memberOf TranslationsComponent
	 */
	const renderTranslationColumn = (lang, record) => {
		const translation = record.translations.find((t) => t.languageCode.toLowerCase() === lang.toLowerCase());

		return (
			<TranslationField
				lang={lang}
				translationKey={record.key}
				groupId={getGroupAndSubGroupId().groupId}
				subGroupId={getGroupAndSubGroupId().subGroupId}
				source={translation?.source}
				isSystemDefault={systemDefault}
				formInstance={formInstance}
			/>
		);
	};

	/** Render table column header
	 * @function
	 * @param {string} lang - language for column
	 * @param {number} index - column index
	 * @returns {JSX}
	 * @memberOf TranslationsComponent
	 */
	const renderTranslationColumnTitle = (lang, index) => {
		let languages = Object.keys(getLanguages()).filter((l) => !selectedLanguages.includes(l) || l === lang);

		return (
			<div className="global-dropdown translation-field-dropdown">
				<Dropdown
					menu={{
						items: languages.map((l) => ({
							key: l,
							// TODO: this className doesn't work, need to handle other way
							className: l === selectedLanguages[index] ? "global-dropdown-active" : "",
							label: getLanguages()[l],
							onClick: (e) => {
								const update = selectedLanguages.map((l, i) => (i === index ? e.key : l));
								setSelectedLanguages(update);
							}
						}))
					}}
					trigger={["click"]}
					placement="bottomLeft"
					overlayClassName="global-dropdown-menu"
					getPopupContainer={() => document.body}
				>
					<div className="global-dropdown-content">
						<span>{getLanguages()[selectedLanguages[index]]}</span>
						<i className="icon-down" />
					</div>
				</Dropdown>
				{((hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.MODIFY }) && !systemDefault) ||
					(hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY }) && systemDefault)) && (
					<div className="translation-field-dropdown-publish" onClick={() => publishTranslations(systemDefault, selectedLanguages[index])}>
						<Tooltip placement="top" getPopupContainer={() => document.body} title={t("pages.dashboard.translations.publish")}>
							<i className="icon-speaker" />
						</Tooltip>
					</div>
				)}
				{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.MODIFY }) && !systemDefault && (
					<div className="translation-field-dropdown-unpublish" onClick={() => setShowUnpublishPopup(selectedLanguages[index])}>
						<Tooltip getPopupContainer={() => document.body} title={t("pages.dashboard.translations.unpublish")}>
							<i className="icon-unpublish" />
						</Tooltip>
					</div>
				)}
			</div>
		);
	};

	/** Fires on system default switcher change
	 * @function
	 * @param {boolean} value
	 * @memberOf TranslationsComponent
	 */
	const handleSystemDefaultChange = (value) => {
		if (value && Object.keys(systemLanguages).length === 0) {
			getSystemAvailableLanguages();
		} else if (!value && Object.keys(partnerLanguages).length === 0) {
			getPartnerAvailableLanguages();
		}
		setSystemDefault(value);
	};

	/** Close unpublishing confirmation popup after unpublish */
	useEffect(() => {
		if (!isUnpublishing) {
			setShowUnpublishPopup(false);
		}
	}, [isUnpublishing]);

	const renderHeader = () => {
		return (
			<div className="translatons-header">
				<div className="translatons-header-inner">
					<div className="translatons-header-inner-col">
						<span>{t("pages.dashboard.translations.keys")}</span>
					</div>
					<div className="translatons-header-inner-col">
						<span>{t("pages.dashboard.translations.system_default")}</span>
					</div>

					{selectedLanguages.map((lang, ind) => (
						<div
							key={lang + "_" + systemDefault + "_" + globalPartnerId}
							className="translatons-header-inner-col translatons-header-inner-col-lang"
						>
							{renderTranslationColumnTitle(lang, ind)}
						</div>
					))}
				</div>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div className="translatons-content">
				{translations.map((translation) => (
					<div className="translatons-content-row" key={translation.key}>
						<div className="translatons-content-row-col">
							<span title={translation.key} className="table-col-shorten-text">
								{translation.key}
							</span>
						</div>
						<div className="translatons-content-row-col">
							<span title={translation.text} className="table-col-shorten-text">
								{translation.text}
							</span>
						</div>
						{selectedLanguages.map((lang, ind) => (
							<div
								className="translatons-content-row-col translatons-content-row-col-lang"
								key={lang + "_" + systemDefault + "_" + globalPartnerId}
							>
								{renderTranslationColumn(lang, translation)}
							</div>
						))}
					</div>
				))}
			</div>
		);
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.menu.translations") }]} />
				<Form colon={false} requiredMark={false} form={formInstance} layout="vertical">
					<div className="dashboard-section-content vs--translations">
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<div className="inline-form-item" style={{ justifyContent: "flex-end" }}>
									<label style={{ opacity: systemDefault ? "1" : "0.5" }}>
										{t("pages.dashboard.translations.system_default_translations")}
									</label>
									<FormItem className="inline-form-item-control" style={{ flex: 0 }}>
										<Switch
											onChange={(value) => {
												handleSystemDefaultChange(value);
											}}
											checked={systemDefault}
											disabled={
												!hasPermissions([
													{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
													{ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW }
												])
											}
										/>
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} md={6}>
								<FormItem label={t("pages.dashboard.translations.resource")} name="group">
									<Select onChange={(e) => setSelectedGroupID(e)} loading={isGroupsLoading} suffixIcon={<i className="icon-down" />}>
										{groups
											.filter((g) => g.name.toLowerCase() !== "rules")
											.map((group) => {
												return group.subGroups.length > 0 ? (
													<Select.OptGroup
														value={group.id}
														key={group.id}
														label={MARKETS_TRANSLATIONS_GROUP_NAMES_TEXT[group.name] ?? group.name}
													>
														{group.subGroups.map((sub) => {
															const gameCategory = group.name === "ScheduledMarkets" ? GAME_CATEGORY.SCHEDULED : GAME_CATEGORY.INSTANT;

															return (
																<Select.Option value={sub.id} key={sub.id}>
																	{Object.keys(MARKETS_TRANSLATIONS_GROUP_NAMES_TEXT).includes(group.name)
																		? t(`common.${GAME_TYPE_MAPPER[gameCategory][sub.name]}`)
																		: sub.name}
																</Select.Option>
															);
														})}
													</Select.OptGroup>
												) : (
													<Select.Option value={group.id} key={group.id}>
														{group.name}
													</Select.Option>
												);
											})}
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} md={18}>
								<FormItem label=" " className="translation-buttons">
									{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY }) &&
										systemDefault && (
											<Button onClick={() => setShowCreatePopup(true)} type="secondary">
												{t("pages.dashboard.translations.create_translation")}
											</Button>
										)}
									{hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.MODIFY }) &&
										!systemDefault && (
											<Button
												onClick={() => setShowUnpublishPopup(true)}
												type="secondary"
												icon={<i className="icon-unpublish" />}
												className="button-with-icon"
											>
												{t("pages.dashboard.translations.unpublish")}
											</Button>
										)}
									{hasOneOfPermissions([
										{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.MODIFY },
										{ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.MODIFY }
									]) ? (
										<Button
											onClick={() => publishTranslations(systemDefault)}
											type="primary"
											icon={<i className="icon-speaker" />}
											className="button-with-icon"
										>
											{t("pages.dashboard.translations.publish")}
										</Button>
									) : null}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24} style={{ overflow: "auto", paddingRight: 0 }}>
								<Spin
									spinning={
										isLoading || isGroupsLoading || isSystemAvailableLoading || isPartnerAvailableLoading || isPublishing || isUnpublishing
									}
								>
									<div className="translations-wrapper">
										{renderHeader()}
										{renderContent()}
									</div>
								</Spin>
							</Col>
						</Row>
						{/*
								<Table
									loading={isLoading || isGroupsLoading || isSystemAvailableLoading || isPartnerAvailableLoading || isPublishing || isUnpublishing}
									columns={makeColumns()}
									data={translations}
									total={translations.length}
									actions={{}}
									isDisabled={() => false}
									noPagination={true}
									uniqueKey="key"
									showHeader={false}
								/>
							*/}
					</div>
				</Form>
			</div>

			{showCreatePopup ? (
				<TranslationAddComponent
					onClose={() => setShowCreatePopup(false)}
					groupId={getGroupAndSubGroupId().groupId}
					subGroupId={getGroupAndSubGroupId().subGroupId}
					languageCodes={selectedLanguages}
				/>
			) : null}

			<Question
				type="confirm"
				onOk={() => unpublishTranslations(showUnpublishPopup !== true ? showUnpublishPopup : null)}
				onCancel={() => setShowUnpublishPopup(false)}
				isVisible={showUnpublishPopup}
				message={t("pages.dashboard.translations.unpublish_message")}
				isLoading={isUnpublishing}
			/>
		</Fragment>
	);
};

/** TranslationsComponent propTypes
 * PropTypes
 */
TranslationsComponent.propTypes = {
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux action to get translation groups */
	getTranslationGroups: PropTypes.func,
	/** Redux state property, is true when loading translation groups */
	isGroupsLoading: PropTypes.bool,
	/** Redux state property, translation groups */
	groups: PropTypes.arrayOf(translationGroupType),
	/** Redux action to get translations */
	getTranslations: PropTypes.func,
	/** Redux action to publish translations */
	publishTranslations: PropTypes.func,
	/** Redux action to unpublish translations */
	unpublishTranslations: PropTypes.func,
	/** Redux state property, is true when loading translations */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when publishing translations */
	isPublishing: PropTypes.bool,
	/** Redux state property, is true when unpublishing translations */
	isUnpublishing: PropTypes.bool,
	/** Redux state property, translations */
	translations: PropTypes.arrayOf(translationType),
	/** Redux action to get partner available Languages */
	getPartnerAvailableLanguages: PropTypes.func,
	/** Redux action to get system available languages */
	getSystemAvailableLanguages: PropTypes.func,
	/** Redux state property, represents the object of loaded system languages */
	systemLanguages: PropTypes.object,
	/** Redux state property, represents the object of loaded partner languages */
	partnerLanguages: PropTypes.object,
	/** Redux state property, is true when loading partner languages */
	isPartnerAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when loading system languages */
	isSystemAvailableLoading: PropTypes.bool,
	/** Redux state property, is true when system default translations is enabled */
	systemDefault: PropTypes.bool,
	/** Redux action to set system default translation */
	setSystemDefault: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getTranslationGroups: () => {
		dispatch(getTranslationGroups());
	},
	getTranslations: (groupId, subGroupId, languageCodes, isSystemDefault) => {
		dispatch(getTranslations(groupId, subGroupId, languageCodes, isSystemDefault));
	},
	getSystemAvailableLanguages: () => {
		dispatch(getSystemAvailableLanguages());
	},
	getPartnerAvailableLanguages: () => {
		dispatch(getPartnerAvailableLanguages());
	},
	setSystemDefault: (systemDefault) => {
		dispatch(setSystemDefault(systemDefault));
	},
	publishTranslations: (isSystemDefault, language) => {
		dispatch(publishTranslations(isSystemDefault, language));
	},
	unpublishTranslations: (languageCode) => {
		dispatch(unpublishTranslations(languageCode));
	}
});

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		isGroupsLoading: state.translations.isGroupsLoading,
		groups: state.translations.groups,
		isLoading: state.translations.isLoading,
		isPublishing: state.translations.isPublishing,
		isUnpublishing: state.translations.isUnpublishing,
		translations: state.translations.translations,
		systemDefault: state.translations.systemDefault,
		systemLanguages: state.systemLanguages.systemAvailableLanguages,
		partnerLanguages: state.partner.language.availableLanguages,
		isPartnerAvailableLoading: state.partner.language.isAvailableLoading,
		isSystemAvailableLoading: state.systemLanguages.isAvailableLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsComponent);
