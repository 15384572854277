import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Row } from "antd";

const FooterButtons = ({ onClose, onSave, saveButtonProps }) => {
	const { t } = useTranslation();

	return (
		<Row className="ant-modal-footer" justify="end" style={{ padding: 10 }}>
			<Button onClick={onClose} className="ant-btn">
				{t("common.cancel")}
			</Button>
			<Button onClick={onSave} {...saveButtonProps} className="ant-btn ant-btn-primary">
				{t("common.save")}
			</Button>
		</Row>
	);
};

FooterButtons.propTypes = {
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Fires on popup form save */
	onSave: PropTypes.func,
	/** save button props */
	saveButtonProps: PropTypes.shape({
		loading: PropTypes.bool,
		disabled: PropTypes.bool,
		style: PropTypes.object
	})
};

export default FooterButtons;
