import { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Row, Col, Drawer, Card, Spin } from 'antd';

import { hasPermission } from 'utils/permissions';
import { mergeClassNames, isNullish } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import Paths from 'constants/path.constants';
import { DYNAMIC_PATH_ID_REGEX } from 'constants/regex.constants';
import {
	JACKPOT_BONUS_STATES_TRANSLATION_TEXTS,
	JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS,
	JACKPOT_TABLE_FILTERS,
	JACKPOT_EDIT_HASHES,
	JACKPOT_HISTORY_TABS
} from 'constants/bonus.constants';

import { getJackpotQuickInfo } from 'store/actions/dashboard/bonuses/jackpotQuickInfo.action';

import { DATE_TIME_FORMAT } from "constants/date.constants";

const getIsLoading = (state) => state.bonuses.jackpots.isSaving;
const getQuickInfoState = (state) => state.bonuses.jackpots.quickInfo || [];

const JackpotQuickInfo = ({ jackpot, onClose }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isLoading = useSelector(getIsLoading);
	const quickInfo = useSelector(getQuickInfoState);

	const dispatch = useDispatch();

	const { activeLevels, nextSeedLevels, lastWonLevels } = quickInfo.reduce((acc, levelData) => {
		const commonKeys = {
			levelType: isNullish(levelData.levelType) ? "-" : levelData.levelType,
			currency: isNullish(levelData.currency) ? "-" : levelData.currency
		}

		acc.activeLevels.push({
			activeAmount: isNullish(levelData.activeAmount) ? "-" : levelData.activeAmount,
			poolName: isNullish(levelData.poolName) ? "-" : levelData.poolName,
			...commonKeys
		});
		acc.nextSeedLevels.push({
			nextAmount: isNullish(levelData.nextAmount) ? "-" : levelData.nextAmount,
			...commonKeys
		});
		acc.lastWonLevels.push({
			winAmount: isNullish(levelData.winAmount) ? "-" : levelData.winAmount,
			projectName: isNullish(levelData.projectName) ? "-" : levelData.projectName,
			betSlipId: isNullish(levelData.betSlipId) ? "-" : levelData.betSlipId,
			winTime: isNullish(levelData.winTime) ? "-" : levelData.winTime,
			...commonKeys
		});

		return acc;
	}, { activeLevels: [], nextSeedLevels: [], lastWonLevels: [] });

	const getQuickInfo = useCallback((params) => dispatch(getJackpotQuickInfo(jackpot.id, params)), [jackpot.id]);

	useEffect(() => {
		if (jackpot.id) {
			getQuickInfo();
		}
	}, [jackpot.id]);

	return (
		<Fragment>
			<Drawer
				className="dashboard-quick-info dashboard-quick-info-jackpot"
				title={
					<>
						<div className='vs--flex vs--justify-between'>
							<span>{t('pages.dashboard.bonuses.jackpot_quick_info')}</span>
							<span className="dashboard-quick-info-jackpot-id">{t('pages.dashboard.bonuses.id')}:&nbsp;{jackpot.id}</span>
						</div>
					</>
				}
				closable={false}
				open={!isNullish(jackpot.id)}
				onClose={onClose}
				width="50%"
				maskStyle={{ backgroundColor: "transparent" }}
			>
				<Spin spinning={isLoading}>
					<div className="dashboard-quick-info-section dashboard-quick-info-jackpot-section">
						<div className="dashboard-quick-info-section-header vs--flex vs--justify-between vs--align-center">
							<span>{t('pages.dashboard.bonuses.jackpot_statistics')}</span>
							<i className="icon-refresh vs--cursor-pointer" title={t("common.refresh")} onClick={getQuickInfo} />
						</div>
						<div className="dashboard-quick-info-section-content">
							{activeLevels.length > 0 && (
								<div className="dashboard-quick-info-jackpot-section-block vs--pl-16 vs--pr-16 vs--pb-16">
									<div className="vs--flex vs--align-center vs--font-medium vs--mt-16 vs--mb-16">
										<span className="dashboard-quick-info-jackpot-section-block-title">{t(JACKPOT_BONUS_STATES_TRANSLATION_TEXTS[jackpot.state])}</span>
									</div>
									<Row gutter={[16, 0]}>
										{activeLevels.map((levelData) => (
											<Col xs={24} sm={12} xl={8}>
												<Card
													className={mergeClassNames("jackpot-level-card", `jackpot-level-card-${levelData.levelType}`)}
													title={t(`${JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelData.levelType]}`)}
												>
													<div className="jackpot-level-card-content vs--flex vs--flex-col">
														<span className="jackpot-level-card-content-item vs--font-medium">{levelData.poolName}</span>
														<span className="jackpot-level-card-content-item jackpot-level-card-content-item-amount vs--mt-8">{levelData.activeAmount}&nbsp;{levelData.currency}</span>
													</div>
												</Card>
											</Col>
										))}
									</Row>
								</div>
							)}
							<Row gutter={[16, 0]}>
								{activeLevels.map(() => (
									<Col xs={24} sm={12} xl={8} className="vs--flex vs--justify-center vs--mt-24 vs--mb-16">
										<i className="icon-down vs--font-bigest" />
									</Col>
								))}
							</Row>
							{nextSeedLevels.length > 0 && (
								<div className="dashboard-quick-info-jackpot-section-block vs--pl-16 vs--pr-16 vs--pb-16">
									<div className="vs--flex vs--align-center vs--font-medium vs--mt-16 vs--mb-16">
										<span className="dashboard-quick-info-jackpot-section-block-title">{t('pages.dashboard.bonuses.jackpot_next_seed')}</span>
									</div>
									<Row gutter={[16, 0]}>
										{nextSeedLevels.map((levelData) => (
											<Col xs={24} sm={12} xl={8}>
												<Card
													className={mergeClassNames(
														"jackpot-level-card",
														"jackpot-level-card-next-seed",
														`jackpot-level-card-${levelData.levelType}`
													)}
													title={t(`${JACKPOT_BONUS_LEVEL_TRANSLATION_TEXTS[levelData.levelType]}`)}
												>
													<div className="jackpot-level-card-content vs--flex vs--flex-col">
														<span className="jackpot-level-card-content-item vs--font-medium">{levelData.nextAmount}&nbsp;{levelData.currency}</span>
													</div>
												</Card>
											</Col>
										))}
									</Row>
								</div>
							)}
							{lastWonLevels.length > 0 && (
								<>
									<div className="dashboard-quick-info-jackpot-section-dash vs--mt-24 vs--mb-24" />
									<div className="dashboard-quick-info-jackpot-section-block vs--pl-16 vs--pr-16 vs--pb-16">
										<div className="vs--flex vs--align-center vs--font-medium vs--mt-16 vs--mb-16">
											<span className="dashboard-quick-info-jackpot-section-block-title">{t('pages.dashboard.bonuses.jackpot_last_won')}</span>
										</div>
										<Row gutter={[16, 0]}>
											{lastWonLevels.map((levelData) => (
												<Col xs={24} sm={12} xl={8}>
													<Card className={mergeClassNames(
														"jackpot-level-card",
														"jackpot-level-card-last-won",
														`jackpot-level-card-${levelData.levelType}`
													)}>
														<div className="vs--flex vs--flex-col">
															<div className="jackpot-level-card-last-won-section vs--flex vs--justify-between vs--align-center">
																<span className="jackpot-level-card-last-won-section-title vs--font-smallest">{t("pages.dashboard.bonuses.jackpot_win")}:</span>
																<span className="jackpot-level-card-last-won-section-value vs--font-bold vs--font-smallest">{levelData.winAmount}&nbsp;{levelData.currency}</span>
															</div>
															<div className="jackpot-level-card-last-won-section vs--flex vs--justify-between vs--align-center vs--mt-8">
																<span className="jackpot-level-card-last-won-section-title vs--font-smallest">{t("pages.dashboard.bonuses.betshopIds")}:</span>
																<span className="jackpot-level-card-last-won-section-value vs--font-smallest">{levelData.projectName}</span>
															</div>
															<div className="jackpot-level-card-last-won-section vs--flex vs--justify-between vs--align-center vs--mt-8">
																<span className="jackpot-level-card-last-won-section-title vs--font-smallest">{t('pages.dashboard.bonuses.jackpot_ticket_id')}:</span>
																<span className="jackpot-level-card-last-won-section-value vs--font-smallest">{levelData.betSlipId}</span>
															</div>
															<div className="jackpot-level-card-last-won-section jackpot-level-card-last-won-section-date vs--flex vs--justify-between vs--align-center vs--mt-8">
																<span className="jackpot-level-card-last-won-section-title vs--font-smallest">{t('pages.dashboard.bonuses.jackpot_win_time')}</span>
																<span className="jackpot-level-card-last-won-section-value vs--font-smallest">
																	{
																		Boolean(levelData.winTime) && levelData.winTime !== "-" ? moment.utc(levelData.winTime).local().format(DATE_TIME_FORMAT) : '-'
																	}
																</span>
															</div>
															<div className="jackpot-level-card-last-won-section jackpot-level-card-last-won-section-details vs--flex vs--justify-end vs--align-center vs--mt-8">
																<a
																	disabled={levelData.betSlipId === "-"}
																	onClick={() => {
																		if (levelData.betSlipId === "-") {
																			return
																		}
																		navigate(`${Paths.DASHBOARD_BONUSES_JACKPOT_EDIT.replace(DYNAMIC_PATH_ID_REGEX, jackpot.id)}?${JACKPOT_TABLE_FILTERS.BETSLIP}=${levelData.betSlipId}#${JACKPOT_EDIT_HASHES.TAB}=${JACKPOT_HISTORY_TABS.HISTORY}`)
																	}}
																>
																	{t('common.details')}
																</a>
															</div>
														</div>
													</Card>
												</Col>
											))}
										</Row>
									</div>
								</>
							)}
						</div>
					</div>
				</Spin>
			</Drawer>
		</Fragment>
	)
}

export default JackpotQuickInfo;