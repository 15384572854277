import { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Typography, DatePicker, Select } from "antd";
import Input from "antd/lib/input";
import NumericInput from "components/common/numericInput";
import { getTopPlayers, setTopPlayersFilters } from "store/actions/dashboard/riskManagment/topPlayers/topPlayers.actions";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import locale from "antd/es/date-picker/locale/en_US";
import { getGamesFromConfig, isNullish } from "utils/common";
import { GAME_CATEGORY } from "constants/game.constants";

const { Item: FormItem } = Form;
const allCategories = "";

/** Top Players Filters Filters Component */
const TopPlayersFilters = ({
	filters = {},
	getTopPlayers = Function.prototype,
	setTopPlayersFilters = Function.prototype
}) => {

	const [selectedPartnerId, setSelectedPartnerId] = useState(null);

	const { t } = useTranslation();

	const formInstanceRef = useRef();

	const partnersNames = useAutosuggestion(AUTOSUGGESTION_TYPE.PARTNER_NAMES, []);

	const apiKeyNames = useAutosuggestion(AUTOSUGGESTION_TYPE.API_KEY_NAMES, [selectedPartnerId], {
		actionArguments: [
			{
				partnerId: selectedPartnerId,
			}
		]
	});

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true }]
		};
	}, []);

	const filtersList = [
		{ name: "partnerName", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID") },
		{ name: "partnerId", title: t("pages.dashboard.developerBetCorrections.partnerNameOrID"), hidden: () => true },
		{ name: "projectName", title: t("pages.dashboard.apikeys.project_name_or_ID") },
		{ name: "projectId", title: t("pages.dashboard.apikeys.project_name_or_ID"), hidden: () => true },
		{ name: "date", title: t("pages.dashboard.bets.date_and_time") },
		{ name: "limit", title: t("pages.dashboard.risk_management.top_player_filter_topLimit") }
	];

	const getItemKey = (item) => item.key;
	const getItemValue = (item) => item.value;

	const handleFilters = (filters) => {
		const modifiedFilters = { ...filters };
		if (modifiedFilters.partnerId) {
			modifiedFilters.partnerName = partnersNames.find((partner) => partner.key === filters.partnerId)?.value ?? "";
		} else {
			modifiedFilters.partnerName = ""
		}
		if (modifiedFilters.projectId) {
			modifiedFilters.projectName = apiKeyNames.find((partner) => partner.key === filters.projectId)?.value ?? "";
		} else {
			modifiedFilters.projectName = ""
		}

		if (!modifiedFilters.partnerName) {
			modifiedFilters.partnerId = "";
			modifiedFilters.projectName = "";
		}
		if (!modifiedFilters.projectName) {
			modifiedFilters.projectId = "";
		}

		setTopPlayersFilters(modifiedFilters);
	}

	return (
		<FiltersWrapper
			loadFn={getTopPlayers}
			setFiltersFn={handleFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList}
			filtersName="riskManagementTopPlayersFilter"
			dependencies={[
				{ field: "partnerId", resetField: "projectId", resetValue: "" },
				{ field: "partnerId", resetField: "projectName", resetValue: "" },
				{ field: "partnerId", resetField: "partnerName", resetValue: "" },
				{ field: "partnerName", resetField: "projectId", resetValue: "" },
				{ field: "partnerName", resetField: "partnerId", resetValue: "" },
				{ field: "partnerName", resetField: "projectName", resetValue: "" },
				{ field: "projectName", resetField: "projectId", resetValue: "" },
				{ field: "projectId", resetField: "projectName", resetValue: "" }
			]}
			formInstanceRef={formInstanceRef}
		>
			<Row gutter={[16, 0]}>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem name="partnerId" label={t("pages.dashboard.developerBetCorrections.partnerNameOrID")}>
						<SearchableSelect
							addNone={true}
							noneText={t("common.all")}
							placeholder={`${t("common.start_typing")} ${t("pages.dashboard.partners.partner_name_or_id")}`}
							items={partnersNames}
							valueProp={getItemKey}
							textProp={getItemValue}
							renderText={getItemValue}
							suffixIcon={<i className="icon-down" />}
							onChange={(partnerId) => {
								setSelectedPartnerId(partnerId)
								if (partnerId === allCategories && typeof formInstanceRef?.current?.setFieldsValue === "function") {
									formInstanceRef.current.setFieldsValue({ projectId: allCategories });
								}
							}}
						/>
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem dependencies={["partnerId"]} noStyle>
						{
							({ getFieldValue }) => {
								const partnerId = getFieldValue("partnerId");
								return (
									<FormItem name="projectId" label={t("pages.dashboard.apikeys.project_name_or_ID")}>
										<SearchableSelect
											disabled={partnerId === allCategories}
											addNone={true}
											noneText={t("common.all")}
											placeholder={`${t("common.start_typing")} ${t("pages.dashboard.apikeys.project_name_or_ID")}`}
											items={apiKeyNames}
											valueProp={getItemKey}
											textProp={getItemValue}
											renderText={getItemValue}
											suffixIcon={<i className="icon-down" />}
										/>
									</FormItem>
								)
							}
						}
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem label={t("pages.dashboard.risk_management.top_player_filter_topLimit")} name="limit">
						<Select suffixIcon={<i className="icon-down" />}>
						{
							[20, 30, 40, 50].map(limit => {
								return (
									<Select.Option value={limit}>{limit}</Select.Option>
								)
							})
						}
						</Select>
					</FormItem>
				</Col>
				<Col xs={24} sm={12} lg={6} xl={4}>
					<FormItem
						name="date"
						label={t("pages.dashboard.bets.date_and_time")}
					>
						<DatePicker.RangePicker
							placeholder={[t("common.from"), t("common.to")]}
							format={`${DATE_FORMAT} ${TIME_FORMAT}`}
							showTime={{ format: TIME_FORMAT }}
							allowClear={false}
							locale={{
								...locale,
								lang: {
									...locale.lang,
									ok: t("common.apply")
								}
							}}
						/>
					</FormItem>
				</Col>
			</Row>
		</FiltersWrapper>
	);
};

/** TopPlayersFilters propTypes
 * PropTypes
 */
TopPlayersFilters.propTypes = {
	/** Redux state property, games filters */
	filters: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	setTopPlayersFilters: (filters) => {
		dispatch(setTopPlayersFilters(filters));
	},
	getTopPlayers: (fromFirstPage) => {
		dispatch(getTopPlayers(fromFirstPage));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.riskManagement.topPlayers.list.filters
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopPlayersFilters);
