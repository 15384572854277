import { useState, useEffect, Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "antd";
import Table from "components/common/table";
import GameAddComponent from "./game-add.component";

import { getBoxGames, updateBoxGame, deleteBoxGame, resetBoxGames, saveBoxGames } from "store/actions/dashboard/retail/boxes/games.action";

import { GAME_CATEGORY, GAME_CHANNEL, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DATE_TIME_FORMAT } from "constants/date.constants";

import { hasPermission } from "utils/permissions";
import { arrayMove, getGamesFromConfig } from "utils/common";

import boxGameType from "types/box/boxGame.type";
import EditGameModal from "./editGameModal/index.jsx";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action.js";

/** Box Edit Page Games Tab Component */
const GamesComponent = ({ games, getBoxGames, saveBoxGames, resetBoxGames, isLoading, updateBoxGame, deleteBoxGame, allGames, getGamesFromProps }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [showAddPopup, setShowAddPopup] = useState(false);
	const [formGames, setFormGames] = useState([]);
	const isModifyDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.BOX_GAMES, action: PERMISSION_ACTION.MODIFY });
	const [gameInfoForModify, setGameInfoForModify] = useState(null);
	const editBoxGame = (gameType, gameId) => {
		setGameInfoForModify({
			gameType, gameId
		})
	}

	const getAvailableTypes = useCallback((boxGame) => {
		const possible = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = boxGame.map((g) => g.type);
		possible.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});
		return available;
	}, []);

	useEffect(() => {
		if (!allGames.length) {
			return
		}

		const available = getAvailableTypes(games);

		const initial = allGames
			.filter(game => available.includes(game.type))
			.map((game) => ({
				id: game.id,
				name: game.name,
				gameType: game.type,
				checked: false
			}))
		setFormGames(initial)

	}, [allGames, games, setFormGames, getAvailableTypes])

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.games.name"),
			dataIndex: "name",
			sorter: false,
			className: "drag-visible"
		},
		{
			title: t("pages.dashboard.games.type"),
			dataIndex: "type",
			render: (value) =>
				t(
					`common.${Object.keys(SCHEDULED_GAME_TYPE)
						.map((g) => SCHEDULED_GAME_TYPE[g])
						.filter((g) => g.value === value)[0].type
					}`
				),
			sorter: false
		}
	];

	/** Reset Box Games on component unmount */
	useEffect(() => () => resetBoxGames(), []);

	/** Close game add popup, after game saved */
	useEffect(() => {
		setShowAddPopup(false);
	}, [games]);

	/** Get all games */
	useEffect(() => {
		getGamesFromProps(GAME_CATEGORY.SCHEDULED);
	}, []);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.BOX_GAMES, action: PERMISSION_ACTION.CREATE }) && (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button"
						onClick={() => {
							setShowAddPopup(true);
						}}
						disabled={formGames.length === 0}
					>
						<span>{t("pages.dashboard.boxes.add_new_game")}</span>
					</Button>
				</div>
			)}
			<Table
				loading={isLoading}
				columns={columns}
				data={games}
				loadFn={() => getBoxGames(routeParams.id)}
				total={games.length}
				actions={{
					activate: isModifyDisabled
						? null
						: {
							isChecked: (record) => record.enabled,
							messageKey: "game",
							handler: (isChecked, record) =>
								updateBoxGame({
									enabled: isChecked,
									gameId: record.id,
									id: routeParams.id
								}),
							disabled: (record) => Boolean(record.disableInfo),
							info: (record) =>
								!record.enabled && record.disableInfo ? (
									<Fragment>
										<p>
											{t("pages.dashboard.games.game_disabled_tooltip")}
											<br />
										</p>
										{record.disableInfo && record.disableInfo.disableFrom && (
											<span>
												<b>{t("common.from")} : </b>
												{moment.utc(record.disableInfo.disableFrom).local().format(DATE_TIME_FORMAT)}
												<br />
											</span>
										)}
										{record.disableInfo && record.disableInfo.disableTo && (
											<span>
												<b>{t("common.to")} : </b>
												{moment.utc(record.disableInfo.disableTo).local().format(DATE_TIME_FORMAT)}
												<br />
											</span>
										)}
										<span>
											<b>{t("pages.dashboard.games.reason")} : </b>
											{record.disableInfo.disableReason}
										</span>
									</Fragment>
								) : null
						},
					delete: hasPermission({ resource: PERMISSION_RESOURCE.BOX_GAMES, action: PERMISSION_ACTION.DELETE })
						? {
							messageKey: "game",
							handler: (record) => {
								deleteBoxGame(routeParams.id, record.id);
							}
						}
						: null,
					edit: hasPermission({ resource: PERMISSION_RESOURCE.BOX_RTP, action: PERMISSION_ACTION.MODIFY })
						? {
							messageKey: "game",
							handler: (record) => {
								editBoxGame(record.type, record.id);
							}
						}
						: null
				}}
				isDisabled={(record) => isModifyDisabled || !record.enabled}
				noPagination={true}
				uniqueKey="id"
				draggable={
					isModifyDisabled
						? null
						: {
							onDragEnd: (oldIndex, newIndex) => {
								const updatedGames = arrayMove(games, oldIndex, newIndex);
								saveBoxGames(routeParams.id, updatedGames);
							}
						}
				}
			/>
			{showAddPopup ? (
				<GameAddComponent
					formGames={formGames}
					onClose={() => {
						setShowAddPopup(false);
					}}
				/>
			) : null}

			<EditGameModal
				isModalVisible={!!gameInfoForModify}
				setIsModalVisible={() => {
					setGameInfoForModify(null)
				}}
				gameId={gameInfoForModify?.gameId}
				gameType={gameInfoForModify?.gameType}
				gameCategory={GAME_CATEGORY.SCHEDULED}
			/>
		</div>
	);
};

/** GamesComponent propTypes
 * PropTypes
 */
GamesComponent.propTypes = {
	/** Redux action to get box games */
	getBoxGames: PropTypes.func,
	/** Redux state property, represents the array of games of box */
	games: PropTypes.arrayOf(boxGameType),
	/** Redux state property, is true when loading box games */
	isLoading: PropTypes.bool,
	/** Redux action to update box game */
	updateBoxGame: PropTypes.func,
	/** Redux action to delete box game */
	deleteBoxGame: PropTypes.func,
	/** Redux action to reset box games */
	resetBoxGames: PropTypes.func,
	/** Redux action to save box games */
	saveBoxGames: PropTypes.func,
	allGames: PropTypes.arrayOf(boxGameType),
};

const mapDispatchToProps = (dispatch) => ({
	getBoxGames: (id) => {
		dispatch(getBoxGames(id));
	},
	updateBoxGame: (game) => {
		dispatch(updateBoxGame(game));
	},
	deleteBoxGame: (id, gameId) => {
		dispatch(deleteBoxGame(id, gameId));
	},
	resetBoxGames: () => {
		dispatch(resetBoxGames());
	},
	saveBoxGames: (id, games) => {
		dispatch(saveBoxGames(id, games));
	},
	getGamesFromProps: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory, GAME_CHANNEL.RETAIL));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.boxes.editingBox.games.isLoading,
		games: state.boxes.editingBox.games.games,
		allGames: state.games.games,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesComponent);
