import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin, Row, Col } from "antd";

import VideoPlayer from "components/common/videoPlayer";
import Scenes from "./scenes";
import WinningMarkets from "./winningMarkets";
import RacingParticipants from "./racingParticipants";

import { getEvent, recalculate } from "store/actions/dashboard/events/events.action";
import { SCHEDULED_GAME_TYPE, GAME_STATUSES, GAME_CATEGORY, INSTANT_GAME_TYPE, GAME_TYPES } from "constants/game.constants";
import eventType from "types/event/event.type";
import { isCupGame, isLeagueGame, isRacingGame, isSeasonGame } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Events Page Single event view Component */
const SingleEventComponent = ({ getEvent, event, isLoading, recalculate, gameCategory, eventId }) => {
	const { t } = useTranslation();

	const isRecalculateEnable = hasPermission({ resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.MODIFY });
	const team1 = event?.gameData?.team1 ?? {};
	const team2 = event?.gameData?.team2 ?? {};

	useEffect(() => {
		getEvent(eventId, gameCategory);
	}, [eventId, gameCategory]);

	return (
		<Spin spinning={isLoading}>
			<div className="dashboard-result-view">
				<div className="dashboard-result-view-info">
					{!(gameCategory === GAME_CATEGORY.SCHEDULED && [SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(
						event.gameType
					)) ? (
						<Fragment>
							<div className="dashboard-result-view-info-item">
								<span>{t("pages.dashboard.events.participants")}</span>
								<b>
									{(
										[GAME_TYPES[gameCategory]?.PENALTY_SHOOTOUT?.value, GAME_TYPES[gameCategory]?.FOOTBALL_SINGLE_MATCH?.value].includes(event.gameType)
									) || isCupGame(event.gameType, gameCategory)
										? `${team1.countryName ?? ""} - ${team2.countryName ?? ""}`
										: isLeagueGame(event.gameType, gameCategory)
											? `${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`
											: isRacingGame(event.gameType, gameCategory)
												? `${(event?.gameData?.participants ?? []).length} ${t(
													[SCHEDULED_GAME_TYPE.GREYHOUNDS_RACE.value, INSTANT_GAME_TYPE.GREYHOUNDS_RACE.value].includes(
															event.gameType
														)
															? "pages.dashboard.events.greyhounds"
															: "pages.dashboard.events.horses"
													)}`
												: ""}
								</b>
							</div>
							<div className="dashboard-result-view-info-item">
								<span>{t("pages.dashboard.events.result")}</span>
								<b>
									{
										
									}
									{[GAME_TYPES[gameCategory]?.PENALTY_SHOOTOUT?.value, GAME_TYPES[gameCategory].FOOTBALL_SINGLE_MATCH?.value].includes(event.gameType) || isSeasonGame(event.gameType, gameCategory)
											? `${team1.goalCount ?? 0} - ${team2.goalCount ?? 0}`
											: isRacingGame(event.gameType, gameCategory)
												? event.result
												: ""}
								</b>
							</div>
						</Fragment>
					) : null}

					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.game_type")}</span>
						<b>
							{t(
								`common.${Object.values(gameCategory === GAME_CATEGORY.SCHEDULED ? SCHEDULED_GAME_TYPE : INSTANT_GAME_TYPE)
									.filter((v) => v.value === event.gameType)
									.map((v) => v.type)}`
							)}
						</b>
					</div>
					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.game_name")}</span>
						<b>{event.gameName}</b>
					</div>
					{isSeasonGame(event.gameType, gameCategory) ? (
						<Fragment>
							<div className="dashboard-result-view-info-item">
								<span>{t(`pages.dashboard.events.${isLeagueGame(event.gameType) ? "week_id" : "round_id"}`)}</span>
								<b>{event.weekId}</b>
							</div>
							<div className="dashboard-result-view-info-item">
								<span>{t(t(`pages.dashboard.events.${isLeagueGame(event.gameType) ? "league_id" : "cup_id"}`))}</span>
								<b>{event.leagueId}</b>
							</div>
						</Fragment>
					) : null}
					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.event_id")}</span>
						<b>{event.id}</b>
					</div>
					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.start_date")}</span>
						<b>{moment.utc(event.startTime).local().format(DATE_TIME_FORMAT)}</b>
					</div>
					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.end_date")}</span>
						<b>{moment.utc(event.finishTime).local().format(DATE_TIME_FORMAT)}</b>
					</div>
					{event.bettingPeriodStartTime && (
						<div className="dashboard-result-view-info-item">
							<span>{t("pages.dashboard.events.betting_period_start")}</span>
							<b>{moment.utc(event.bettingPeriodStartTime).local().format(DATE_TIME_FORMAT)}</b>
						</div>
					)}
					{event.bettingPeriodEndTime && (
						<div className="dashboard-result-view-info-item">
							<span>{t("pages.dashboard.events.betting_period_end")}</span>
							<b>{moment.utc(event.bettingPeriodEndTime).local().format(DATE_TIME_FORMAT)}</b>
						</div>
					)}
					<div className="dashboard-result-view-info-item">
						<span>{t("pages.dashboard.events.betslips")}</span>
						<b>{event.betSlipsCount}</b>
					</div>
					{event.status === GAME_STATUSES.FINISHED || event.status === GAME_STATUSES.CANCELED ? (
						<div className="dashboard-result-view-info-item">
							<span>{t("pages.dashboard.events.calculation_status")}</span>
							{event.isResultCalculated === true ? (
								<b className="dashboard-result-view-info-item-success">{t("pages.dashboard.events.calculation_status_success")}</b>
							) : event.isResultCalculated === false ? (
								<div className="dashboard-result-view-info-item-failed">
									<i className="icon-info" />
									<b>{t("pages.dashboard.events.calculation_status_fail")}</b>
									{isRecalculateEnable ? (
										<a onClick={() => recalculate(event.id, gameCategory)}>{t("pages.dashboard.events.recalculate")}</a>
									) : null}
								</div>
							) : null}
						</div>
					) : null}
				</div>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
						{!isRacingGame(event.gameType, gameCategory) && (
							<div className="dashboard-result-view-section">
								<div className="dashboard-result-view-section-header">
									<span>
										{[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value, SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value].includes(
											event.gameType
										) || isSeasonGame(event.gameType, gameCategory)
											? t("pages.dashboard.events.scenes")
											: event.gameType === SCHEDULED_GAME_TYPE.KENO.value
												? t("common.winningBallNumbers")
												: [SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(event.gameType)
													? t("pages.dashboard.events.result")
													: ""}
									</span>
								</div>
								<div className="dashboard-result-view-section-content">
									<Scenes event={event} gameCategory={gameCategory} />
								</div>
							</div>
						)}

						{!(gameCategory === GAME_CATEGORY.SCHEDULED && [SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(
							event.gameType
						)) && <WinningMarkets event={event} gameCategory={gameCategory} />}
					</Col>

					<Col xs={24} sm={24} lg={12}>
						{((gameCategory === GAME_CATEGORY.SCHEDULED && [SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value, SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value].includes(event.gameType)) ||
							isSeasonGame(event.gameType, gameCategory)) &&
						!(gameCategory === GAME_CATEGORY.INSTANT && event.gameType === INSTANT_GAME_TYPE.GREYHOUNDS_RACE.value) ? (
							<div className="dashboard-result-view-section">
								<VideoPlayer
									streamConfiguration={{
										url: import.meta.env.SYSTEM_ARCHIVE_URL ? `${import.meta.env.SYSTEM_ARCHIVE_URL}/${event.gameId}/${event.id}.mp4` : null
									}}
									isMinimized={false}
									defaultExtended={false}
									showMinimize={false}
									showFullscreen={true}
								/>
							</div>
						) : null}

						{gameCategory === GAME_CATEGORY.SCHEDULED && [SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(event.gameType) && (
							<WinningMarkets event={event} gameCategory={gameCategory} />
						)}

						{isRacingGame(event.gameType, gameCategory) && <RacingParticipants event={event} />}
					</Col>
				</Row>
			</div>
		</Spin>
	);
};

/** SingleEventComponent propTypes
 * PropTypes
 */
SingleEventComponent.propTypes = {
	/** Redux action to get single event */
	getEvent: PropTypes.func,
	/** Redux state property, represents current viewing event */
	event: eventType,
	/** Redux state property, is true when loading event */
	isLoading: PropTypes.bool,
	/** Redux action to recalculate */
	recalculate: PropTypes.func,
	/** React property to allow invoce recalculate action */
	isRecalculateEnable: PropTypes.bool,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	/** React property, event id */
	eventId: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
	getEvent: (id, gameCategory) => {
		dispatch(getEvent(id, gameCategory));
	},
	recalculate: (id, gameCategory) => {
		dispatch(recalculate(id, gameCategory));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.events.isEventLoading,
		event: state.events.event
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleEventComponent);
